import React from 'react';
import { Grid, Text } from '@mantine/core';

import { FormAssetTreeSelector, FormAssets } from 'helpers/form';
import { getRecommendationAssetsFilters } from 'utils/recommendation';
import { IMobileAppSyncAssetsForm } from './types';

export const MobileAppSyncFormAssets: React.FC<IMobileAppSyncAssetsForm> = ({
  form,
  isEditingProducts,
  showMetadataHover,
}) => {
  return (
    <Grid className="h-100" styles={{ inner: { height: '100%' } }}>
      <Grid.Col span={6}>
        <>
          <Text>Product Assets</Text>
          <FormAssetTreeSelector
            form={form}
            allSelected
            isEditingProducts={isEditingProducts}
            showMetadataHover={showMetadataHover}
          />
        </>
      </Grid.Col>
      <Grid.Col span={6}>
        <FormAssets
          name="independentAssetIds"
          label="Other Assets"
          fitInParent
          large
          omni
          {...form.formData.independentAssetIds}
          {...form.handlers}
          filters={getRecommendationAssetsFilters()}
        />
      </Grid.Col>
    </Grid>
  );
};
