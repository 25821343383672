import React from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';

import { IPreviewImage } from 'types';
import { Thumbnail } from 'components/thumbnail';
import { AsyncSliderSelect } from 'components/slider-select/slider-select';

import useSelectedOption from 'utils/hooks/selected-option';
import { ACCESS_LEVELS_OPTIONS } from 'utils/constants';
import { LinkWithArrow } from 'utils/ui';

interface IPreviewTabProps {
  title?: React.ReactNode;
  thumbnail?: IPreviewImage;
  customThumbnail?: JSX.Element;
  buttons?: React.ReactNode;
  detailsLink?: string;
  children?: React.ReactNode;
  accessLevel?: string;
  handleAccessLevelChange?: (newAccessLevel: string) => Promise<boolean>;
  subtitle?: string | JSX.Element;
  hideThumbnail?: boolean;
}

export const PreviewTab: React.FC<IPreviewTabProps> = observer(
  ({
    children,
    title,
    thumbnail,
    detailsLink,
    accessLevel,
    handleAccessLevelChange,
    customThumbnail,
    subtitle,
    hideThumbnail,
  }) => {
    const selectedOption = useSelectedOption({ value: accessLevel });

    return (
      <div className={cx('entity-preview h-100', { 'entity-preview--deleted': !detailsLink })}>
        <div className="d-flex flex-column align-items-center justify-content-center">
          <div className="entity-preview__title">
            <h3
              className={cx({
                [`access-level-${selectedOption?.color}`]: Boolean(selectedOption),
              })}
            >
              {title}
            </h3>
            {subtitle && <span>{subtitle}</span>}
          </div>
        </div>

        {!hideThumbnail ? (
          <div className="entity-preview__thumb">{customThumbnail || <Thumbnail image={thumbnail?.url} />}</div>
        ) : null}

        <div className="entity-preview__actions py-3">
          {accessLevel && handleAccessLevelChange && (
            <AsyncSliderSelect onChange={handleAccessLevelChange} value={accessLevel} options={ACCESS_LEVELS_OPTIONS} />
          )}
          {detailsLink && <LinkWithArrow to={detailsLink} text="View Details" />}
        </div>

        {children}
      </div>
    );
  },
);
