import React, { useCallback } from 'react';
import { useMediaQuery } from '@mantine/hooks';

import { FormCheckbox, FormInput, IUseFormReturn } from 'helpers/form';
import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { IContact } from 'types';

const ContactSubscriptionSection: React.FC<IUseFormReturn<IContact>> = ({ formData, handlers }) => {
  const handleChangeAnalytics = useCallback(
    ({ analytics_subscription }: Pick<IContact, 'analytics_subscription'>): void =>
      handlers.onChange({
        ...(analytics_subscription ? {} : { analytics_playback_attachment_all: false }),
        analytics_subscription,
      }),
    [handlers],
  );

  const handleChangeSupervisorAnalytics = useCallback(
    ({ supervisor_analytics_subscription }: Pick<IContact, 'supervisor_analytics_subscription'>): void =>
      handlers.onChange({
        ...(supervisor_analytics_subscription
          ? {}
          : {
              supervisor_analytics_cc: undefined,
              supervisor_analytics_playback_attachment: false,
              supervisor_analytics_playback_attachment_all: false,
            }),
        supervisor_analytics_subscription,
      }),
    [handlers],
  );
  const matches = useMediaQuery('(min-width: 75em)');

  return (
    <FormSectionWrapperV2 title="Subscriptions" wrapperClassName="py-3" withBorder gridTemplateColumns="1fr">
      <FormCheckbox
        large
        name="analytics_subscription"
        label="Analytics Report (global)"
        {...handlers}
        {...formData.analytics_subscription}
        onChange={handleChangeAnalytics}
      />
      <FormCheckbox
        large
        name="analytics_playback_attachment_all"
        label="Include Screenings Attachment (global)"
        className="ms-3"
        {...handlers}
        {...formData.analytics_playback_attachment_all}
        disabled={!formData.analytics_subscription.value}
      />
      <FormSectionWrapperV2 gridTemplateColumns={matches ? '1fr 1fr' : '1fr'} padded={false}>
        <div>
          <FormCheckbox
            large
            name="supervisor_analytics_subscription"
            label="Analytics Report (my clients)"
            {...handlers}
            {...formData.supervisor_analytics_subscription}
            onChange={handleChangeSupervisorAnalytics}
          />
          <FormCheckbox
            large
            name="supervisor_analytics_playback_attachment"
            label="Include Screenings Attachment (my clients)"
            className="ms-3"
            {...handlers}
            {...formData.supervisor_analytics_playback_attachment}
            disabled={!formData.supervisor_analytics_subscription.value}
          />
          <FormCheckbox
            large
            name="supervisor_analytics_playback_attachment_all"
            label="Include Screenings Attachment (global)"
            className="ms-3"
            {...handlers}
            {...formData.supervisor_analytics_playback_attachment_all}
            disabled={!formData.supervisor_analytics_subscription.value}
          />
        </div>
        <FormInput
          name="supervisor_analytics_cc"
          label="Cc Recipient"
          placeholder="Type Cc Recipient Email"
          className="ms-3 align-self-start"
          {...handlers}
          {...formData.supervisor_analytics_cc}
          disabled={!formData.supervisor_analytics_subscription.value}
          inline
        />
      </FormSectionWrapperV2>
      <FormCheckbox
        large
        name="skip_marketing_emails"
        label="Opt out from Marketing Emails"
        {...handlers}
        {...formData.skip_marketing_emails}
      />
    </FormSectionWrapperV2>
  );
};

export default ContactSubscriptionSection;
