import React, { useMemo } from 'react';
import { Button } from '@mantine/core';
import { Intent } from 'utils/ui';
import { CalGenericMeeting } from '@mediafellows/mm3-types';
import { CalendarApi } from '@fullcalendar/react';
import { isEmpty } from 'lodash';

import { FormChecklist, useMm3Form } from 'helpers/form';
import { Model } from 'helpers/filters/types';
import { ICalendarInvite } from 'types/calendar';
import { formatInviteeName } from './utils';

type IAttendeesData = Pick<CalGenericMeeting, 'id' | 'invites'>;
export interface IAttendeesFormData {
  id?: number | null;
  attendees: string[];
}

const getEmail = (e: ICalendarInvite): string => e.email;
const getAttendeesEmail = (invites: ICalendarInvite[]): string[] => {
  return invites.reduce((acc, e) => (e.attended ? [...acc, getEmail(e)] : acc), []);
};

export const AttendanceForm: React.FC<
  IAttendeesData & { calendar?: CalendarApi; submit: (values: IAttendeesFormData) => void }
> = (props) => {
  const { id, invites, submit } = props;

  const initialValues = { id, attendees: getAttendeesEmail(invites || []) };
  const { valid, formData, handlers, onSubmit } = useMm3Form<IAttendeesFormData>(initialValues, Model.MEETINGS, submit);
  const options = useMemo(
    () => (invites || []).map((e) => ({ name: getEmail(e), label: formatInviteeName(e) })),
    [invites],
  );

  const isEmptyOptions = isEmpty(options);

  return (
    <>
      {!isEmptyOptions ? (
        <FormChecklist name="attendees" {...handlers} {...formData.attendees} options={options} />
      ) : (
        <h4>You have not added any named invitees to check-in.</h4>
      )}
      <Button
        variant={Intent.PRIMARY}
        disabled={!valid || isEmptyOptions}
        onClick={onSubmit}
        className="attendance-form__submit-button"
      >
        Confirm
      </Button>
    </>
  );
};
