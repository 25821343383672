import React, { useEffect } from 'react';
import { createBrowserRouter, Link, RouterProvider } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { RouteObject } from 'react-router/dist/lib/context';

import { IAnalyticsPageValues } from 'types';

import { Login } from 'pages/login';
import { Products } from 'pages/products';
import { Dashboard } from 'pages/dashboard';
import { ResetPassword } from 'pages/reset-password';
import { Registration } from 'pages/registration';
import { PrivacyPolicy } from 'pages/privacy-policy';
import { Imprint } from 'pages/imprint';
import { RegistrationConfirmation } from 'pages/registration-confirmation';
import { Contacts } from 'pages/contacts';
import { Analytics } from 'pages/analytics';
import { Recommendations } from 'pages/recommendations';
import { RecommendationDetails } from 'pages/recommendation-details';

import { SetNewPassword } from 'pages/set-new-password';
import { ProductDetails } from 'pages/product-details';
import { Assets } from 'pages/assets';
import { Events } from 'pages/events';
import { AssetsUpload } from 'pages/assets-upload';
import { AssetDetails } from 'pages/asset-details';
import { ContactDetails } from 'pages/contact-details';
import { Organizations } from 'pages/organizations';
import { OrganizationDetails } from 'pages/organization-details';
import { OrganizationDeliveryDetails } from 'pages/organization-details/organization-delivery-tab/organization-delivery-details';
import { UpsertContactForm } from 'pages/upsert-contact';
import { UpsertAccessPrivilege } from 'pages/upsert-access-privilege';
import { UpsertOrganizationForm } from 'pages/upsert-organization';
import { UpsertMobileAppSync } from 'pages/upsert-mobile-app-sync';
import { AssetSelections } from 'pages/asset-selections';
import ProductSelections from 'pages/product-selections/product-selections';
import ContactSelections from 'pages/contact-selections/contact-selections';
import AssetSelectionDetails from 'pages/asset-selection-details/asset-selection-details';
import { ProductSelectionDetails } from 'pages/product-selection-details';
import { ContactSelectionDetails } from 'pages/contact-selection-details';
import { CreateNewProduct } from 'pages/product-create';
import { AccessPrivileges } from 'pages/access-privileges';
import { AccessPrivilegeDetails } from 'pages/access-privilege-details';
import { EventDetails } from 'pages/event-details';
import { UpsertEventPage } from 'pages/upsert-event';
import Sso from 'pages/login/sso';
import { Website } from 'pages/website';
import { Baskets } from 'pages/baskets';
import { BasketDetails } from 'pages/basket-details';
import { ContactBasketDetails } from 'pages/contact-details/contact-baskets-tab/contact-basket-details';
import { CreateConference } from 'pages/conference-create';
import { Conferences } from 'pages/conferences';
import { ConferenceDetails } from 'pages/conference-details';
import { MobileAppSync } from 'pages/mobile-app-sync';
import { MobileAppSyncDetails } from 'pages/mobile-app-sync-details';
import { CastCrew } from 'pages/cast-crew';
import { CastCrewDetailsPage } from 'pages/cast-crew-details';
import SchedulerPage from 'pages/scheduler/scheduler';
import LocationsPage from 'pages/locations/locations';
import CollectionPage from 'pages/collections/collections';
import CollectionDetails from 'pages/collection-details/collection-details';
import { RecommendationCreate } from 'pages/recommendation-create';
import { Pages } from 'utils/actions/types';
import { PageSpinner } from 'components/page-spinner';
import { customRoutes } from 'routes/custom-route';
import { ScreeningRoomCreate, ScreeningRoomDetails } from 'pages/screening-room';
import { DeliveryCreate } from 'pages/delivery-create';

import { RoutePaths, Routes } from 'utils/routes';
import { PrivateRoutes } from 'helpers/private-route/private-route';
import { PublicOnlyRoutes } from 'helpers/public-route/public-route';
import { useStore, ISidebarSection } from 'store';
import { loadSession, loadUser, useSessionStore } from 'store/session-store';
import { Outlet } from 'react-router';
import { TwoFactorAuth } from 'pages/two-factor-auth';

import { ModalRenderer, UploadDialog } from 'components/dialogs';
import { ScreeningRoomDataSection } from 'components/screening-room/data-section';
import { MultipleAssetsEditModal } from 'components/asset/asset-edit-form';

import { getAssets, saveAssets } from 'utils/apis/asset';
import { SIDE_BAR_WEBSITE_SECTION } from 'utils/side-bar';
import { Deliveries } from 'pages/deliveries';
import { DeliveryDetails } from 'pages/delivery-details';
import { Affiliation } from 'pages/affiliation';
import { AccessGroups } from 'pages/access-groups';
import { UpsertAccessGroup } from 'pages/upsert-access-group';
import { AccessGroupDetails } from 'pages/access-group-details';

const AnalyticsContacts = (): React.ReactElement => (
  <Analytics section={IAnalyticsPageValues.ANALYTICS_CONTACTS} page={Pages.LIST} />
);
const AnalyticsRecommendations = (): React.ReactElement => (
  <Analytics section={IAnalyticsPageValues.ANALYTICS_RECOMMENDATION} page={Pages.LIST} />
);

const AnalyticsProducts = (): React.ReactElement => <Analytics section={IAnalyticsPageValues.ANALYTICS_PRODUCTS} />;
const AnalyticsAssets = (): React.ReactElement => <Analytics section={IAnalyticsPageValues.ANALYTICS_ASSETS} />;

const dashboardRoutes: RouteObject[] = [
  {
    index: true,
    element: <Dashboard />,
  },
  ...customRoutes,
  ...SIDE_BAR_WEBSITE_SECTION.reduce(
    (acc, { component: Component = Website, section, text, route }) =>
      route === Routes.COLLECTIONS
        ? acc
        : [
            ...acc,
            {
              path: route,
              element: <Component section={section} title={text} key={section} />,
              handle: { breadcrumb: `Website ${text}`, activeSidebar: ISidebarSection.website },
            },
          ],
    [],
  ),
  {
    path: RoutePaths.CALENDAR,
    element: <SchedulerPage />,
    handle: { breadcrumb: 'Calendar', activeSidebar: ISidebarSection.meetings },
  },
  {
    path: RoutePaths.LOCATIONS,
    element: <LocationsPage />,
    handle: { breadcrumb: 'Locations', activeSidebar: ISidebarSection.meetings },
  },
  {
    path: RoutePaths.CREATE_NEW_CONFERENCE,
    element: <CreateConference />,
    handle: { activeSidebar: ISidebarSection.marketing },
  },
  {
    path: RoutePaths.CREATE_ACCESS_PRIVILEGE,
    element: <UpsertAccessPrivilege />,
    handle: { activeSidebar: ISidebarSection.marketing },
  },
  {
    path: RoutePaths.CREATE_MOBILE_APP_SYNC,
    element: <UpsertMobileAppSync />,
    handle: { activeSidebar: ISidebarSection.marketing },
  },
  {
    path: RoutePaths.CREATE_EVENT,
    element: <UpsertEventPage />,
    handle: { activeSidebar: ISidebarSection.marketing },
  },
  {
    path: RoutePaths.CREATE_ASSET,
    element: <AssetsUpload />,
    handle: { activeSidebar: ISidebarSection.assets },
  },
  {
    path: RoutePaths.CREATE_ORGANIZATION,
    element: <UpsertOrganizationForm />,
    handle: { activeSidebar: ISidebarSection.contacts },
  },
  {
    path: RoutePaths.CREATE_NEW_PRODUCT,
    element: <CreateNewProduct />,
    handle: { activeSidebar: ISidebarSection.products },
  },
  {
    path: RoutePaths.CREATE_PRODUCT_RECOMMENDATION,
    element: <RecommendationCreate type="product" />,
    handle: { activeSidebar: ISidebarSection.marketing },
  },
  {
    path: RoutePaths.CREATE_ASSET_RECOMMENDATION,
    element: <RecommendationCreate type="asset" />,
    handle: { activeSidebar: ISidebarSection.marketing },
  },
  {
    path: RoutePaths.CREATE_ACCESS_GROUP,
    element: <UpsertAccessGroup />,
    handle: { activeSidebar: ISidebarSection.marketing },
  },
  {
    path: RoutePaths.ASSET_SELECTIONS,
    handle: { breadcrumb: 'Asset Selections', activeSidebar: ISidebarSection.assets },
    children: [
      {
        index: true,
        element: <AssetSelections />,
      },
      {
        path: RoutePaths.ASSET_SELECTIONS_PARAM,
        element: <AssetSelectionDetails />,
        handle: { breadcrumb: 'Selection Details' },
      },
    ],
  },
  {
    path: RoutePaths.BASKETS,
    handle: { breadcrumb: 'Baskets', activeSidebar: ISidebarSection.products },
    children: [
      {
        index: true,
        element: <Baskets />,
      },
      {
        path: RoutePaths.BASKET_PARAM,
        element: <BasketDetails />,
        handle: { breadcrumb: 'Basket Details' },
      },
    ],
  },
  {
    path: RoutePaths.ANALYTICS_CONTACTS,
    handle: { breadcrumb: 'Analytics Contacts', activeSidebar: ISidebarSection.analytics },
    children: [
      {
        index: true,
        element: <AnalyticsContacts />,
      },
      {
        path: RoutePaths.TAB_PARAM1,
        element: <AnalyticsContacts />,
      },
    ],
  },
  {
    path: RoutePaths.ANALYTICS_RECOMMENDATIONS,
    handle: { breadcrumb: 'Analytics Recommendations', activeSidebar: ISidebarSection.analytics },
    children: [
      {
        index: true,
        element: <AnalyticsRecommendations />,
      },
      {
        path: RoutePaths.TAB_PARAM1,
        element: <AnalyticsRecommendations />,
      },
    ],
  },
  {
    path: RoutePaths.ANALYTICS_PRODUCTS,
    handle: { breadcrumb: 'Analytics Products', activeSidebar: ISidebarSection.analytics },
    children: [
      {
        index: true,
        element: <AnalyticsProducts />,
      },
      {
        path: RoutePaths.TAB_PARAM1,
        element: <AnalyticsProducts />,
      },
    ],
  },
  {
    path: RoutePaths.ANALYTICS_ASSETS,
    handle: { breadcrumb: 'Analytics Assets', activeSidebar: ISidebarSection.analytics },
    children: [
      {
        index: true,
        element: <AnalyticsAssets />,
      },
      {
        path: RoutePaths.TAB_PARAM1,
        element: <AnalyticsAssets />,
      },
    ],
  },
  {
    path: RoutePaths.CONTACT_SELECTIONS,
    handle: { breadcrumb: 'Contact Selections', activeSidebar: ISidebarSection.contacts },
    children: [
      {
        index: true,
        element: <ContactSelections />,
      },
      {
        path: RoutePaths.CONTACT_SELECTIONS_PARAM,
        element: <ContactSelectionDetails />,
        handle: { breadcrumb: 'Selection Details' },
      },
    ],
  },
  {
    path: RoutePaths.PRODUCT_SELECTIONS,
    handle: { breadcrumb: 'Product Selections', activeSidebar: ISidebarSection.products },
    children: [
      {
        index: true,
        element: <ProductSelections />,
      },
      {
        path: RoutePaths.PRODUCT_SELECTIONS_PARAM,
        element: <ProductSelectionDetails />,
        handle: { breadcrumb: 'Selection Details' },
      },
    ],
  },
  {
    path: RoutePaths.CREATE_CONTACT,
    handle: { breadcrumb: 'Create New Contact', activeSidebar: ISidebarSection.contacts },
    element: <UpsertContactForm />,
  },
  {
    path: RoutePaths.CAST_CREW,
    handle: { breadcrumb: 'Cast & Crew', activeSidebar: ISidebarSection.products },
    children: [
      {
        index: true,
        element: <CastCrew />,
      },
      {
        path: RoutePaths.CAST_CREW_PARAM,
        element: <CastCrewDetailsPage />,
        handle: { breadcrumb: 'Cast & Crew Details' },
      },
    ],
  },
  {
    path: Routes.COLLECTIONS,
    handle: { breadcrumb: 'Website Collections', activeSidebar: ISidebarSection.website },
    children: [
      {
        index: true,
        element: <CollectionPage />,
      },
      {
        path: RoutePaths.COLLECTIONS_PARAM,
        handle: { breadcrumb: 'Website Collection Details' },
        children: [
          {
            index: true,
            element: <CollectionDetails />,
          },
          {
            path: RoutePaths.TAB_PARAM1,
            element: <CollectionDetails />,
          },
        ],
      },
    ],
  },
  {
    path: RoutePaths.MOBILE_APP_SYNC,
    handle: { breadcrumb: 'Mobile Selections', activeSidebar: ISidebarSection.marketing },
    children: [
      {
        index: true,
        element: <MobileAppSync />,
      },
      {
        path: RoutePaths.MOBILE_APP_SYNC_PARAM,
        handle: { breadcrumb: 'Mobile Selection Details' },
        children: [
          {
            index: true,
            element: <MobileAppSyncDetails />,
          },
          {
            path: RoutePaths.TAB_PARAM1,
            element: <MobileAppSyncDetails />,
          },
        ],
      },
    ],
  },
  {
    path: RoutePaths.RECOMMENDATIONS,
    handle: { breadcrumb: 'Recommendations', activeSidebar: ISidebarSection.marketing },
    children: [
      {
        index: true,
        element: <Recommendations />,
      },
      {
        path: RoutePaths.RECOMMENDATIONS_PARAM,
        handle: { breadcrumb: 'Recommendation Details' },
        children: [
          {
            index: true,
            element: <RecommendationDetails />,
          },
          {
            path: RoutePaths.TAB_PARAM1,
            element: <RecommendationDetails />,
          },
        ],
      },
    ],
  },
  {
    path: RoutePaths.ACCESS_PRIVILEGES,
    handle: { breadcrumb: 'Access Privileges', activeSidebar: ISidebarSection.marketing },
    children: [
      {
        index: true,
        element: <AccessPrivileges />,
      },
      {
        path: RoutePaths.ACCESS_PRIVILEGES_PARAM,
        handle: { breadcrumb: 'Access Privilege Details' },
        children: [
          {
            index: true,
            element: <AccessPrivilegeDetails />,
          },
          {
            path: RoutePaths.TAB_PARAM1,
            element: <AccessPrivilegeDetails />,
          },
        ],
      },
    ],
  },
  {
    path: RoutePaths.CREATE_SCREENING_ROOM,
    element: <ScreeningRoomCreate />,
    handle: { activeSidebar: ISidebarSection.marketing },
  },
  {
    path: RoutePaths.SCREENING_ROOMS,
    handle: { breadcrumb: 'Screening Rooms', activeSidebar: ISidebarSection.marketing },
    children: [
      {
        index: true,
        element: <ScreeningRoomDataSection />,
      },
      {
        path: RoutePaths.SCREENING_ROOM_PARAM,
        handle: { breadcrumb: 'Screening Room Details' },
        children: [
          {
            index: true,
            element: <ScreeningRoomDetails />,
          },
          {
            path: RoutePaths.TAB_PARAM1,
            element: <ScreeningRoomDetails />,
          },
        ],
      },
    ],
  },
  {
    path: RoutePaths.ACCESS_GROUPS,
    handle: { breadcrumb: 'Access Groups', activeSidebar: ISidebarSection.marketing },
    children: [
      {
        index: true,
        element: <AccessGroups />,
      },
      {
        path: RoutePaths.ACCESS_GROUPS_PARAM,
        handle: { breadcrumb: 'Access Group Details' },
        children: [
          {
            index: true,
            element: <AccessGroupDetails />,
          },
          {
            path: RoutePaths.TAB_PARAM1,
            element: <AccessGroupDetails />,
          },
        ],
      },
    ],
  },
  {
    path: RoutePaths.ORGANIZATIONS,
    handle: { breadcrumb: 'Organizations', activeSidebar: ISidebarSection.contacts },
    children: [
      {
        index: true,
        element: <Organizations />,
      },
      {
        path: RoutePaths.ORGANIZATIONS_PARAM,
        handle: { breadcrumb: 'Organization Details' },
        children: [
          {
            index: true,
            element: <OrganizationDetails />,
          },
          {
            path: RoutePaths.TAB_PARAM1,
            children: [
              {
                index: true,
                element: <OrganizationDetails />,
              },
              {
                path: RoutePaths.ORGANIZATIONS_DELIVERSID_PARAM,
                element: <OrganizationDeliveryDetails />,
                handle: { breadcrumb: 'Delivery Details' },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: RoutePaths.EVENTS,
    handle: { breadcrumb: 'Events', activeSidebar: ISidebarSection.marketing },
    children: [
      {
        index: true,
        element: <Events />,
      },
      {
        path: RoutePaths.EVENTS_PARAM,
        handle: { breadcrumb: 'Event Details' },
        children: [
          {
            index: true,
            element: <EventDetails />,
          },
          {
            path: RoutePaths.TAB_PARAM1,
            element: <EventDetails />,
          },
        ],
      },
    ],
  },
  {
    path: RoutePaths.CONFERENCES,
    handle: { breadcrumb: 'Conferences', activeSidebar: ISidebarSection.marketing },
    children: [
      {
        index: true,
        element: <Conferences />,
      },
      {
        path: RoutePaths.CONFERENCES_PARAM,
        handle: { breadcrumb: 'Conference Details' },
        children: [
          {
            index: true,
            element: <ConferenceDetails />,
          },
          {
            path: RoutePaths.TAB_PARAM1,
            element: <ConferenceDetails />,
          },
        ],
      },
    ],
  },
  {
    path: RoutePaths.ASSETS,
    handle: { breadcrumb: 'Assets', activeSidebar: ISidebarSection.assets },
    children: [
      {
        index: true,
        element: <Assets />,
      },
      {
        path: RoutePaths.ASSET_PARAM,
        handle: { breadcrumb: 'Asset Details' },
        children: [
          {
            index: true,
            element: <AssetDetails />,
          },
          {
            path: RoutePaths.TAB_PARAM1,
            element: <AssetDetails />,
          },
        ],
      },
    ],
  },
  {
    path: RoutePaths.CONTACTS,
    handle: { breadcrumb: 'Contacts', activeSidebar: ISidebarSection.contacts },
    children: [
      {
        index: true,
        element: <Contacts />,
      },
      {
        path: RoutePaths.CONTACTS_PARAM,
        handle: { breadcrumb: 'Contact Details' },
        children: [
          {
            index: true,
            element: <ContactDetails />,
          },
          {
            path: RoutePaths.TAB_PARAM1,
            children: [
              {
                index: true,
                element: <ContactDetails />,
              },
              {
                path: RoutePaths.CONTACTS_BASKETSID_PARAM,
                element: <ContactBasketDetails />,
                handle: { breadcrumb: 'Basket Details' },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: RoutePaths.PRODUCTS,
    handle: { breadcrumb: 'Products', activeSidebar: ISidebarSection.products },
    children: [
      {
        index: true,
        element: <Products />,
      },
      {
        path: RoutePaths.PRODUCTS_PARAM,
        handle: { breadcrumb: 'Product Details' },
        children: [
          {
            index: true,
            element: <ProductDetails />,
          },
          {
            path: RoutePaths.TAB_PARAM1,
            element: <ProductDetails />,
          },
        ],
      },
    ],
  },
  {
    path: RoutePaths.CREATE_DELIVERY_PACKAGE,
    element: <DeliveryCreate />,
    handle: { activeSidebar: ISidebarSection.marketing },
  },
  {
    path: RoutePaths.DELIVERIES,
    handle: { breadcrumb: 'Deliveries', activeSidebar: ISidebarSection.fulfillment },
    children: [
      {
        index: true,
        element: <Deliveries />,
      },
      {
        path: RoutePaths.DELIVERIES_PARAM,
        handle: { breadcrumb: 'Delivery Details' },
        children: [
          {
            index: true,
            element: <DeliveryDetails />,
          },
          {
            path: RoutePaths.TAB_PARAM1,
            element: <DeliveryDetails />,
          },
        ],
      },
    ],
  },
  {
    path: RoutePaths.AFFILIATION,
    handle: { breadcrumb: 'Affiliation', activeSidebar: ISidebarSection.settings },
    children: [
      {
        index: true,
        element: <Affiliation />,
      },
      {
        path: RoutePaths.TAB_PARAM1,
        element: <Affiliation />,
      },
    ],
  },
];

const NotFound = (): React.ReactElement => (
  <div className="d-flex justify-content-center align-items-center p-5 flex-column">
    <h1>404: Page not found</h1>
    <div className="mt-3 h3">
      Go back to <Link to={Routes.DASHBOARD}>Dashboard</Link>
    </div>
  </div>
);

const Root = (): React.ReactElement => (
  <>
    <Outlet />
    <ModalRenderer />
    <UploadDialog />
    <MultipleAssetsEditModal getAssets={getAssets} saveAssets={saveAssets} />
  </>
);

export const router = createBrowserRouter([
  {
    path: Routes.DASHBOARD,
    element: <Root />,
    handle: {
      breadcrumb: 'Home',
    },
    children: [
      {
        element: <PrivateRoutes />,
        children: dashboardRoutes,
      },
      {
        element: <PublicOnlyRoutes />,
        children: [
          {
            path: RoutePaths.LOGIN,
            element: <Login />,
          },
          {
            path: RoutePaths.REGISTRATION,
            element: <Registration />,
          },
          {
            path: RoutePaths.SSO,
            element: <Sso />,
          },
          {
            path: RoutePaths.TWO_FACTOR_AUTH,
            element: <TwoFactorAuth />,
          },
          {
            path: 'um/confirmations/:token',
            element: <RegistrationConfirmation />,
          },
          {
            path: 'confirmations/:token',
            element: <RegistrationConfirmation />,
          },
          {
            path: 'um/passwords/:token/edit',
            element: <SetNewPassword />,
          },
          {
            path: RoutePaths.RESET_PASSWORD,
            element: <ResetPassword />,
          },
        ],
      },
      {
        element: <PrivacyPolicy />,
        path: RoutePaths.PRIVACY_POLICY,
      },
      {
        element: <Imprint />,
        path: RoutePaths.IMPRINT,
      },
      {
        element: <NotFound />,
        path: '*',
      },
    ],
  },
]);

export const AppRoutes: React.FC<{}> = observer(() => {
  const store = useStore();
  const initialLoadDone = useSessionStore((state) => state.initialLoadDone);
  const session = useSessionStore((state) => state.session);

  useEffect(() => {
    (async () => {
      await loadSession();
    })();
  }, [store]);

  useEffect(() => {
    (async () => {
      const { basicStore, notificationsStore } = store;
      if (!session) return;
      loadUser();
      basicStore.loadBasics();
      notificationsStore.connect();
    })();
  }, [store, session]);

  if (!initialLoadDone) return <PageSpinner />;

  return <RouterProvider router={router} />;
});
