import React from 'react';

import { FormSectionWrapper } from 'components/form-section-wrapper';
import { FormMultiSelect, FormInput } from 'helpers/form';
import { IFormHandlers, IFormData } from 'helpers/form/types';

import { IAssetFormFields } from './types';

interface IAssetIdentifiersSectionProps {
  formData: IFormData<IAssetFormFields>;
  handlers: IFormHandlers<IAssetFormFields>;
}

export const AssetIdentifiersSection: React.FC<IAssetIdentifiersSectionProps> = ({ formData, handlers }) => {
  return (
    <FormSectionWrapper gridTemplateColumns="1fr 1fr 1fr" title="Identifiers">
      <FormMultiSelect
        name="tags"
        label="Tags"
        large
        allowNewItems
        {...formData.tags}
        {...handlers}
        options={formData.tags?.value}
        noResults={null}
      />
      <FormInput name="external_reference" label="External Reference" {...formData.external_reference} {...handlers} />
      <FormInput name="archive_number" label="Archive Number" {...formData.archive_number} {...handlers} />
    </FormSectionWrapper>
  );
};

export default AssetIdentifiersSection;
