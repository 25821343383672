import React, { useCallback, useState, useEffect, useMemo } from 'react';
import isArray from 'lodash/isArray';
import { observer } from 'mobx-react-lite';
import { Loader, Menu } from '@mantine/core';
import { ItemRenderer, MultiSelect } from '@blueprintjs/select';

import { IAggregation, InFilter, NotInFilter } from 'helpers/filters/types';
import { IDataProvider, IDataProviderItem } from 'helpers/data-provider/option-data-provider';
import { FormGroup } from 'helpers/form/fields/form-group';
import { useDebounce } from 'utils/hooks';
import { highlightText } from 'helpers/form/fields/helpers';
import { MantineIcon } from 'utils/ui';

import cx from 'classnames';

type IMultiSelectFilterDefinition = InFilter | NotInFilter;

interface IFilterMultiSelectProps {
  name: string;
  label: string;
  placeholder?: string;
  optionsProvider: IDataProvider;
  large?: boolean;
  disabled?: boolean;

  aggregations?: IAggregation[];
  filter: IMultiSelectFilterDefinition;
  onChange?: (newValue: { [key: string]: IMultiSelectFilterDefinition }) => void;
}

const MfxMultiFilterSelect = MultiSelect.ofType<IDataProviderItem>();

const tagRenderer = (item: IDataProviderItem): string => item.label;

const FilterMultiSelect: React.FC<IFilterMultiSelectProps> = observer((props) => {
  const { name, placeholder, label, filter, aggregations, onChange, optionsProvider, ...restProps } = props;
  const { value } = filter;
  const [query, setQuery] = useState('');

  const valueNormalized = useMemo(() => (isArray(value) ? value : [value]), [value]);
  const debouncedQuery = useDebounce(query, 150);

  const options = optionsProvider.data;

  const itemRenderer: ItemRenderer<IDataProviderItem> = useCallback(
    (item, { handleClick, modifiers, query }) => {
      if (!modifiers.matchesPredicate) {
        return null;
      }

      const aggr = aggregations?.find((a) => a.value === item.value);
      const isSelected = valueNormalized.includes(item.value);
      return (
        <Menu>
          <Menu.Item
            className={cx({ active: modifiers.active })}
            disabled={Boolean(modifiers.disabled || (aggregations?.length && !aggr?.count))}
            key={item.value}
            onClick={handleClick}
            leftSection={isSelected ? <MantineIcon icon="tick" /> : null}
            rightSection={aggr && `${aggr.count}`}
          >
            {highlightText(item.label, query)}
          </Menu.Item>
        </Menu>
      );
    },
    [aggregations, valueNormalized],
  );

  const handleItemRemove = useCallback(
    (tag: string, idx: number): void => {
      const item = valueNormalized[idx];
      const newValue = valueNormalized.filter((v) => v !== item);
      onChange && onChange({ [name]: { ...filter, value: newValue } });
    },
    [filter, name, onChange, valueNormalized],
  );

  const handleItemSelect = useCallback(
    (item: IDataProviderItem): void => {
      // we add selected item to value
      if (!valueNormalized.includes(item.value)) {
        const newValue = [...valueNormalized, item.value];
        onChange && onChange({ [name]: { ...filter, value: newValue } });
        // it was already there so we remove it from value
      } else {
        handleItemRemove(`${item.value}`, valueNormalized.indexOf(item.value));
      }
    },
    [filter, handleItemRemove, name, onChange, valueNormalized],
  );

  const handleQueryChange = useCallback((query: string) => {
    setQuery(query);
  }, []);

  useEffect(() => {
    optionsProvider.filter(debouncedQuery);
  }, [debouncedQuery, optionsProvider]);

  const handlePopoverOpen = useCallback(() => {
    optionsProvider.init();
  }, [optionsProvider]);

  const selectedItems = options.filter((o) => valueNormalized.includes(o.value));

  return (
    <FormGroup label={label} labelFor={name}>
      <MfxMultiFilterSelect
        className="filter-multiselect"
        onItemSelect={handleItemSelect}
        items={optionsProvider.loading ? [] : options}
        itemRenderer={itemRenderer}
        onQueryChange={handleQueryChange}
        noResults={
          <Menu>
            <Menu.Item disabled={true}>{optionsProvider.loading ? <Loader size={20} /> : 'Nothing found.'}</Menu.Item>
          </Menu>
        }
        popoverProps={{ minimal: true, fill: true, onOpening: handlePopoverOpen }}
        tagRenderer={tagRenderer}
        tagInputProps={{ onRemove: handleItemRemove, placeholder }}
        selectedItems={selectedItems}
        {...restProps}
      />
    </FormGroup>
  );
});

export default FilterMultiSelect;
