import { ISelectionStandardized, ISelection } from 'types';
import { formatFullName } from 'utils/general';

const mapSelection = (selectionData: ISelection, entityCount: number): ISelectionStandardized => ({
  ...selectionData,
  entityCount,
  owner: formatFullName(selectionData.owner, false),
  ownerDivisionId: selectionData.owner?.division_id,
});

export default mapSelection;
