import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';

import { Thumbnail } from 'components/thumbnail';
import { parseKeyPathToObject } from 'utils/payload';
import { FormCheckbox, useForm } from 'helpers/form';
import { Model } from 'helpers/filters/types';
import { IAsset, ItemId } from 'types';

import { AssetSettingsSection, AssetIdentifiersSection, AssetAboutSection, IAssetFormFields } from './sections';
import { assetFormCustomContext, editAssetPresetInitialData } from 'components/asset/asset-edit-form/constants';
import { getAssetFormFields } from './utils';
import isEqual from 'lodash/isEqual';

interface IAssetEditFormProps {
  asset?: IAsset;
  onDataChange: (object: IAsset) => void;
  formId?: ItemId;
  selectable?: boolean;
  setIsValid?: React.Dispatch<React.SetStateAction<boolean>>;
}

const AssetEditForm: React.FC<IAssetEditFormProps> = observer(
  ({ asset, onDataChange, formId = 'asset-edit-form', setIsValid, selectable = false }) => {
    const initialAssetFields = getAssetFormFields({ ...editAssetPresetInitialData, ...asset });
    const latestAssetFields = useRef(initialAssetFields);

    const { formData, handlers, valid, values } = useForm<IAssetFormFields>(
      initialAssetFields,
      Model.ASSETS,
      undefined,
      assetFormCustomContext,
    );

    const latestValues = useRef(values);
    useEffect(() => setIsValid?.(valid), [setIsValid, valid]);

    useEffect(() => {
      if (isEqual(initialAssetFields, latestAssetFields.current)) {
        return;
      }

      latestAssetFields.current = initialAssetFields;
      handlers.onChange(initialAssetFields);
    }, [handlers, initialAssetFields]);

    useEffect(() => {
      if (isEqual(values, latestValues.current)) {
        return;
      }

      latestValues.current = values;
      onDataChange?.(parseKeyPathToObject(values));
    }, [values, valid, onDataChange]);

    return (
      <form id={`${formId}`} className="asset-edit-modal__form-container">
        <div className="d-flex align-items-center pt-3">
          {selectable && <FormCheckbox name="selected" {...formData.selected} {...handlers} />}
          <Thumbnail style={{ height: '200px', width: '61.5%' }} image={asset?.preview_image?.url} />
          <div className="p-2"> {asset?.name} </div>
        </div>

        <AssetSettingsSection formData={formData} formId={`${formId}`} handlers={handlers} />

        <AssetAboutSection
          formData={formData}
          formId={`${formId}`}
          handlers={handlers}
          assetType={asset?.default_layer?.['@type']}
        />

        <AssetIdentifiersSection formData={formData} handlers={handlers} />
      </form>
    );
  },
);

export default AssetEditForm;
