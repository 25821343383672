import React from 'react';
import { CalGenericMeeting } from '@mediafellows/mm3-types';

import { Row } from 'components/label-value-pair';
import { InfoBoxV2 } from 'components/info-box-v2';
import { getContactName } from 'utils/ui';

import { InviteesDisplay } from './ui';

import './style.scss';
import DateListElement from 'components/date-list-element';
import { changeTimeZone } from 'helpers/form/fields/helpers';
import { getTotalAttendeesCount } from './utils';

export const MeetingPreview: React.FC<CalGenericMeeting & { padded?: boolean }> = ({ padded = false, ...meeting }) => {
  const {
    description,
    ends_at,
    host,
    mobile_sync_group,
    starts_at,
    time_zone,
    invites,
    location,
    title,
    additional_seats,
    basket,
  } = meeting;
  return (
    <InfoBoxV2 gridTemplateColumns="190px 1fr" padded={padded}>
      <Row key="title" label="Title" value={title} />
      <Row key="start" label="Start" value={<DateListElement date={changeTimeZone(starts_at, time_zone)} />} />
      <Row key="end" label="End" value={<DateListElement date={changeTimeZone(ends_at, time_zone)} />} />
      <Row key="timezone" label="Timezone" value={time_zone} />
      <Row key="host" label="Host" value={getContactName(host)} />
      <Row rawValue key="Invitees" label="Invitees" value={<InviteesDisplay invites={invites} />} />
      <Row key="additional_seats" label="No. of additional attendees" value={additional_seats || 0} />
      <Row label="Total number of attendees" value={getTotalAttendeesCount(meeting)} />
      <Row key="location" label="Market Location" value={location?.name} fallback="N/A" />
      <Row key="mobile_sync_group" label="Mobile Selection" value={mobile_sync_group?.name} fallback="N/A" />
      <Row key="basket" label="Basket" value={basket?.name} fallback="N/A" />
      <Row key="description" label="Description" value={description} valueClassName="meeting-preview__description" />
    </InfoBoxV2>
  );
};
