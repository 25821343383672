import { IContact } from 'types';
import { cleanUp, parseKeyPathToObject } from 'utils/payload';

import { IContactFields } from './upsert-contact';

export enum StepIds {
  Step1,
  Step2,
  Step3,
}

export function prepareUserData(contact: IContactFields): IContact {
  const defaults = {
    phones: [],
    im_contacts: [],
    social_media_profiles: [],
  };

  return cleanUp(parseKeyPathToObject(contact), defaults) as IContact;
}
