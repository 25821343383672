import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import {
  FormCheckbox,
  FormChecklist,
  FormSelect as Select,
  FormMultiSelect,
  FormHtmlInput,
  FormProducts,
  FormRemoteSelect,
} from 'helpers/form';
import { IFormData, IFormHandlers } from 'helpers/form/types';
import { filterAssetStatusOptions } from 'helpers/form/fields/form-select';

import { IAsset, IAssetPresetFields, ISearchFilter, ItemId } from 'types';
import { useBasicStore } from 'store/hooks';
import { getWatermarkOptions } from 'components/asset/asset-upload-components/utils';
import { CustomAssetRow } from 'components/asset/asset-edit-form/sections/custom-row';
import { AssetFilters } from 'utils/asset';
import { getEntityType } from 'utils/general';
import { queryMainAssets } from 'utils/apis/asset';
import { flags } from 'utils/flags';

import './index.scss';

export interface IAssetPresetFieldsProps {
  formId: string;
  formData: IFormData<IAssetPresetFields>;
  handlers: IFormHandlers<IAssetPresetFields>;
  values: IAssetPresetFields;
  shouldShowErrors: boolean;
  withParentField?: boolean;
}

const fetchParentAssets = (q: string, id: ItemId): Promise<IAsset[]> =>
  queryMainAssets({ q, ids: id ? [id] : undefined }, [['type', 'eq', 'Asset3::Digital::Video']] as ISearchFilter[]);

export const AssetPresetFields: React.FC<IAssetPresetFieldsProps> = ({
  formId,
  formData,
  handlers,
  values,
  shouldShowErrors,
  withParentField,
}) => {
  const { languagesDataOptions } = useBasicStore();

  const FormSelect = useCallback(
    ({ touched, ...props }): JSX.Element => <Select {...props} touched={shouldShowErrors || touched} />,
    [shouldShowErrors],
  );

  const classification = values?.classification || '';
  const watermarkOptions = getWatermarkOptions(formData.watermark);
  const statusOptions = filterAssetStatusOptions(formData.status?.options);
  const assetItemType = getEntityType(classification);
  const isVideo: boolean = assetItemType === AssetFilters.VIDEO;

  return (
    <div className="asset-upload-components__preset-form-container mt-3">
      <div className="asset-upload-components__preset-form-left">
        <FormChecklist
          formId={formId}
          name="protection_levels"
          label="Protection Levels"
          {...formData.protection_levels}
          {...handlers}
        />
        <div className="mt-3">
          <FormCheckbox
            formId={formId}
            name="product.marketing_use"
            label="Marketing Use"
            {...formData['product.marketing_use']}
            {...handlers}
            className="mb-1"
          />
          <FormCheckbox
            formId={formId}
            name="sharable"
            label="Sharable"
            className="mb-1"
            {...formData.sharable}
            {...handlers}
          />
          <FormChecklist formId={formId} name="permissions" className="mb-1" {...formData.permissions} {...handlers} />
        </div>
      </div>
      <div className="asset-upload-components__preset-form-right">
        {!values.parent_id && (
          <div className="asset-upload-components__preset-form-products">
            <FormProducts name="products" label="Products" large {...formData.products} {...handlers} />
          </div>
        )}
        <FormSelect name="classification" label="Type" large {...formData.classification} {...handlers} />
        {flags.isMm3Assets && withParentField && !values.products?.length && (
          <FormRemoteSelect
            fetchOptions={fetchParentAssets}
            name="parent_id"
            label="Main Asset"
            large
            {...formData.parent_id}
            {...handlers}
          />
        )}
        <CustomAssetRow formData={formData} handlers={handlers} />
        {Boolean(watermarkOptions.length) && (
          <FormSelect
            name="watermark"
            label="Watermark"
            large
            {...formData.watermark}
            {...handlers}
            options={watermarkOptions}
          />
        )}
        <FormSelect name="access_level" label="Access" large {...formData.access_level} {...handlers} />
        <FormMultiSelect
          name="languages"
          label="Languages"
          large
          {...formData.languages}
          {...handlers}
          options={languagesDataOptions}
        />
        <FormSelect
          name="status"
          label="Status"
          large
          {...{ ...formData.status, options: statusOptions }}
          {...handlers}
        />
        {isVideo && (
          <>
            <FormSelect
              name="burned_in_subtitles_language_id"
              label="Burned-in Subtitles"
              large
              {...formData.burned_in_subtitles_language_id}
              {...handlers}
              options={languagesDataOptions}
            />

            <FormSelect
              name="dubbed_language_id"
              label="Dubbed language"
              large
              {...formData.dubbed_language_id}
              {...handlers}
              options={languagesDataOptions}
            />
          </>
        )}

        <div className="asset-upload-components__preset-form-products">
          <FormHtmlInput
            formId={formId}
            name="layer.notes"
            type="textarea"
            label="Notes"
            large
            {...formData['layer.notes']}
            {...handlers}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(observer(AssetPresetFields));
