import merge from 'lodash/merge';
import { IJsonLDSpec } from '@mediafellows/chipmunk';

import { IDeepPartial } from 'types';
import { IFormValidationResults, IFormFieldsSettings, IFieldOptions, IFileExtensions } from 'helpers/form/types';
import {
  validate,
  calculateRequired,
  calculateOptions,
  fillFields,
  calculateExtensions,
  isSameValues,
  calculateDisabled,
} from 'helpers/form/helpers';
import { updateTouchedFieldsFromValues } from 'helpers/form';

import { UpdateFormActions, FormStateActionType } from './actions';

export interface IFormState<T> {
  context: IJsonLDSpec | null;
  customContext: IDeepPartial<IJsonLDSpec> | null;
  values: T;
  validations: IFormValidationResults<T>;
  fieldsTouched: IFormFieldsSettings<T, boolean>;
  required: IFormFieldsSettings<T, boolean>;
  options: IFormFieldsSettings<T, IFieldOptions | null>;
  extensions: IFormFieldsSettings<T, IFileExtensions>;
  disabled: IFormFieldsSettings<T, boolean>;
}

const combineContexts = (
  appContext: IJsonLDSpec | null,
  customContext: IDeepPartial<IJsonLDSpec> | null,
): IDeepPartial<IJsonLDSpec> => {
  return merge({}, appContext || {}, customContext || {});
};

export const formReducer = <T extends object>(state: IFormState<T>, action: UpdateFormActions<T>): IFormState<T> => {
  switch (action.type) {
    case FormStateActionType.UpdateContext:
      const contexts = combineContexts(action.context, state.customContext);
      const validationsAfterContext = validate(state.values, contexts);
      const requiredAfterContext = calculateRequired(state.values, contexts);
      const disabledAfterContext = calculateDisabled(state.values, contexts);
      const optionsAfterContext = calculateOptions(state.values, contexts);

      return {
        ...state,
        context: action.context,
        validations: validationsAfterContext,
        required: requiredAfterContext,
        disabled: disabledAfterContext,
        options: optionsAfterContext,
        extensions: calculateExtensions(state.values, contexts),
      };
    case FormStateActionType.UpdateCustomContext:
      const customContext = combineContexts(action.context as IJsonLDSpec, state.customContext);
      const contextsAfterCustomContext = combineContexts(state.context, customContext);
      const validationsAfterCustomContext = validate(state.values, contextsAfterCustomContext);
      const requiredAfterCustomContext = calculateRequired(state.values, contextsAfterCustomContext);
      const disabledCustomContext = calculateDisabled(state.values, contextsAfterCustomContext);
      const optionsAfterCustomContext = calculateOptions(state.values, contextsAfterCustomContext);

      return {
        ...state,
        customContext,
        validations: validationsAfterCustomContext,
        required: requiredAfterCustomContext,
        options: optionsAfterCustomContext,
        disabled: disabledCustomContext,
        extensions: calculateExtensions(state.values, contextsAfterCustomContext),
      };
    case FormStateActionType.UpdateValues:
      if (isSameValues(state, action)) {
        return state;
      }

      const contextsAfterValues = combineContexts(state.context, state.customContext);
      const newValues = { ...state.values, ...action.values };
      const validationsAfterValues = { ...state.validations, ...validate(newValues, contextsAfterValues) };

      // recalculate required fields when new keys are added to the form
      let required = state.required;
      let disabled = state.disabled;
      if (Object.keys(action.values).some((key) => !(key in state.values))) {
        required = calculateRequired(newValues, contextsAfterValues);
        disabled = calculateDisabled(newValues, contextsAfterValues);
      }

      return { ...state, values: newValues, validations: validationsAfterValues, required, disabled };

    case FormStateActionType.SetFields:
      const contextsAfterRemoveValues = combineContexts(state.context, state.customContext);
      const { values } = action;
      const validations = validate(values, contextsAfterRemoveValues);
      const fieldsTouched = updateTouchedFieldsFromValues<T>(state.fieldsTouched, values);
      return { ...state, fieldsTouched, values, validations };

    case FormStateActionType.ResetFields:
      const initialForm = formStateInit(action.values);
      return {
        ...state,
        values: initialForm.values,
        validations: validate(initialForm.values, combineContexts(state.context, state.customContext)),
        fieldsTouched: initialForm.fieldsTouched,
      };

    case FormStateActionType.UpdateTouched:
      return { ...state, fieldsTouched: { ...state.fieldsTouched, ...action.fieldsTouched } };

    default:
      return state;
  }
};

export const formStateInit = <T extends {}>(initialData: T): IFormState<T> => {
  return {
    context: null,
    customContext: null,
    values: initialData,
    validations: validate(initialData, null),
    fieldsTouched: fillFields(initialData, false),
    required: fillFields(initialData, false),
    disabled: fillFields(initialData, false),
    options: fillFields(initialData, null),
    extensions: fillFields(initialData, null),
  };
};
