import React from 'react';
import { IMarketingEvent, IStyled } from 'types';
import { observer } from 'mobx-react-lite';

import { useEventUrl } from 'utils/event-utils';
import { ClipboardView } from 'components/clipboard-view';

interface IEventLink extends IStyled {
  event: IMarketingEvent;
  mode?: 'full' | 'short';
  linkClassName?: string;
}

export const EventLink: React.FC<IEventLink> = observer(({ event, mode = 'full', style, className, linkClassName }) => {
  const eventUrl = useEventUrl(event);
  return (
    <ClipboardView value={eventUrl} className={className} textClassName={linkClassName}>
      <a className={linkClassName} style={style} href={eventUrl || undefined} target="_blank" rel="noreferrer noopener">
        {mode === 'full' ? eventUrl : event?.purpose}
      </a>
    </ClipboardView>
  );
});
