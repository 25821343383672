import React from 'react';
import { IGroup, IStyled } from 'types';
import { observer } from 'mobx-react-lite';

import { useScreeningRoomUrl } from 'utils/event-utils';
import { ClipboardView } from 'components/clipboard-view';

interface IScreeningRoomLink extends IStyled {
  screeningRoom: IGroup | undefined;
  mode?: 'full' | 'short';
  linkClassName?: string;
}

export const ScreeningRoomLink: React.FC<IScreeningRoomLink> = observer(
  ({ screeningRoom, mode = 'full', style, className, linkClassName }) => {
    const screeningRoomUrl = useScreeningRoomUrl(screeningRoom);

    return (
      <ClipboardView value={screeningRoomUrl} className={className} textClassName={linkClassName}>
        <a
          className={linkClassName}
          style={style}
          href={screeningRoomUrl || undefined}
          target="_blank"
          rel="noreferrer noopener"
        >
          {mode === 'full' ? screeningRoomUrl : screeningRoom?.id}
        </a>
      </ClipboardView>
    );
  },
);
