import React from 'react';
import FullCalendar, { EventApi, EventClickArg, EventInput } from '@fullcalendar/react';
import { ResourceLabelContentArg } from '@fullcalendar/resource-common';
import { CalGenericMeeting } from '@mediafellows/mm3-types';
import { ActionIcon, Menu } from '@mantine/core';
import cx from 'classnames';

import { useMeetingActions } from 'utils/actions/meeting';
import { IAction, IUseActionsOptionParam } from 'utils/actions';
import { ICalendarInvite } from 'types/calendar';
import { changeTimeZone } from 'helpers/form/fields/helpers';

import { formatDate } from 'utils/date';
import { formatInviteeName, getValidNewItem } from './utils';
import { validEmailRegex } from 'utils/validations/email';
import { MantineIcon } from 'utils/ui/icon';
import { Classes, renderMenuActionItems } from 'utils/ui';

import { IActionName } from 'utils/actions/types';

import './style.scss';

const InviteeRow: React.FC<ICalendarInvite> = ({ name, email, attended }) => {
  return (
    <div className="d-flex align-items-center gap-1 w-max-context">
      <MantineIcon icon="symbol-circle" color={attended ? 'green' : 'grey'} />
      <div className="text-truncate"> {formatInviteeName({ name, email })}</div>
    </div>
  );
};

export const InviteesDisplay: React.FC<Pick<CalGenericMeeting, 'invites'>> = ({ invites }) => {
  if (!invites?.length) {
    return <i>No Invitees</i>;
  }

  return (
    <>
      {invites.map((invitee, i) => (
        <InviteeRow key={invitee.email + i} {...invitee} />
      ))}
    </>
  );
};

const ActionsMenu: React.FC<{ actions: IAction<IActionName>[] }> = (props) => {
  const { actions } = props;

  if (!actions?.length) return <></>;

  return (
    <Menu position="left" middlewares={{ flip: { fallbackAxisSideDirection: 'end' } }}>
      <Menu.Target>
        <ActionIcon color="gray.5" radius="sm" variant="subtle" className="action-menu-button js_action-btn">
          <MantineIcon icon="more" />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>{renderMenuActionItems(actions)}</Menu.Dropdown>
    </Menu>
  );
};

export function MeetingActions({
  info,
  calendar,
}: {
  info: EventClickArg | EventInput;
  calendar?: React.RefObject<FullCalendar>;
}): JSX.Element {
  let eventClickArg: EventApi | null = null;
  let eventInput: EventInput | null = null;

  if (info.event) {
    eventClickArg = info.event;
  } else {
    eventInput = info;
  }

  const meeting = {
    ...(eventInput || {}),
    ...((eventClickArg || eventInput)?.extendedProps || {}),
    title: (eventClickArg || eventInput)?.title || '',
    id: parseInt((eventClickArg || eventInput)?.id || '', 10) || null,
  } as CalGenericMeeting;

  const actions = useMeetingActions([meeting], { calendar, closePrevDialog: true } as IUseActionsOptionParam);

  return (
    <div className="more-button-header">
      <ActionsMenu actions={actions} />
    </div>
  );
}

const formatLocationTime = (date: string, timeZone: string): string => {
  return formatDate(changeTimeZone(date, timeZone), 'dd MMM HH:mm');
};

export const resourceLabelContent = ({ resource }: ResourceLabelContentArg): JSX.Element => {
  const { capacity, starts_at, ends_at, time_zone } = resource.extendedProps;

  return (
    <div className="resource-view__wrapper">
      <div className="text-truncate">
        {resource.title} {capacity ? `(${capacity} pax)` : ''}
      </div>
      <div className="text-truncate d-flex gap-1 resource-view__location-time-container">
        <span>{formatLocationTime(starts_at, time_zone)}</span>
        <span>-</span>
        <span>{formatLocationTime(ends_at, time_zone)}</span>
        <span>{time_zone}</span>
      </div>
    </div>
  );
};

const ExternalInviteeElement: React.FC<{ onClick: VoidFunction; validInvitee?: string }> = ({
  onClick,
  validInvitee,
}) => {
  return (
    <Menu>
      <Menu.Item onClick={onClick} disabled={!validInvitee}>
        <>
          <span className={cx(Classes.TEXT_MUTED, 'pe-2')}>
            {`For external Invitees use the format: Joe Doe joe@email.com ${validInvitee ? ':' : ''}`}
          </span>
          <div>{validInvitee}</div>
        </>
      </Menu.Item>
    </Menu>
  );
};
export const CreateInviteeElement: React.FC<{ onClick: VoidFunction; text: string }> = ({ onClick, text }) => {
  const validInvitee = getValidNewItem(text);
  return <ExternalInviteeElement validInvitee={validInvitee} onClick={onClick} />;
};

export const SearchExternalInvitee: React.FC<{ onClick: VoidFunction; text: string }> = ({ onClick, text }) => {
  const validEmail = text.match(validEmailRegex)?.[0];
  return <ExternalInviteeElement validInvitee={validEmail} onClick={onClick} />;
};
