import React, { useCallback, useMemo } from 'react';
import { useStore } from 'store';
import { IAsset, IMm3Asset } from 'types';
import { FormDate, FormCheckbox, useForm, IFormData, IFormHandlers } from 'helpers/form';
import { FormSubmitSection } from 'components/form-submit-section';
import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';

import { IAccessChangeScheduledAccessDelegation } from 'utils/actions/asset';

import { useScheduledJobs } from './hooks';
import { ScheduledJobList } from 'components/scheduled-job-list';
import { contextExtension } from './utils';
import { minAccessDate, maxAccessDate } from 'utils/constants';
import { Model } from 'helpers/filters/types';

import 'components/scheduled-job-list/style.scss';
import { getValidIds } from 'utils/general';

const renderDetails = (job): JSX.Element => <>Prevent delegation: {job.skip_access_delegation ? 'Yes' : 'No'}</>;

export const AccessChangeScheduledAccessDelegationForm: React.FC<{
  items: (IAsset | IMm3Asset)[];
  readOnly?: boolean;
}> = ({ items, readOnly = false }) => {
  const formId = `asset-access-change-scheduled-access-delegation`;
  const { toastStore } = useStore();
  const itemIds = useMemo(() => getValidIds(items), [items]);

  const [scheduledJobs, removeJob, scheduleJob] = useScheduledJobs<IAccessChangeScheduledAccessDelegation>(
    'change_access_delegation_on_asset',
    itemIds,
  );

  const submitForm = useCallback(
    async (data: IAccessChangeScheduledAccessDelegation) => {
      try {
        await scheduleJob(data);
        toastStore.success('Access delegation changed');
      } catch (error) {
        toastStore.error(error);
      }
    },
    [toastStore, scheduleJob],
  );

  const { formData, handlers, valid, onSubmit } = useForm<IAccessChangeScheduledAccessDelegation>(
    {
      scheduled_for: '',
      skip_access_delegation: false,
      item_ids: itemIds,
    },
    Model.ASSETS,
    submitForm,
    contextExtension,
  );

  return (
    <form onSubmit={onSubmit}>
      {Boolean(scheduledJobs.length) && (
        <FormSectionWrapperV2 title="Scheduled Access Delegation Prevention" gridTemplateColumns="1fr" padded>
          <ScheduledJobList
            jobs={scheduledJobs}
            onRemove={removeJob}
            renderDetails={renderDetails}
            readOnly={readOnly}
          />
        </FormSectionWrapperV2>
      )}

      {!readOnly && <FormElements valid={valid} formId={formId} formData={formData} handlers={handlers} />}
    </form>
  );
};

const FormElements: React.FC<{
  formId: string;
  formData: IFormData<IAccessChangeScheduledAccessDelegation>;
  handlers: IFormHandlers<IAccessChangeScheduledAccessDelegation>;
  valid: boolean;
}> = ({ formId, formData, handlers, valid }) => {
  return (
    <>
      <FormSectionWrapperV2 title="Schedule new Access Delegation" gridTemplateColumns="1fr">
        <FormDate
          name="scheduled_for"
          label="Date"
          large
          withTime={true}
          minDate={minAccessDate}
          maxDate={maxAccessDate}
          {...formData.scheduled_for}
          {...handlers}
        />
        <FormCheckbox
          name="skip_access_delegation"
          label="Prevent (Product) Access Delegation"
          formId={formId}
          large
          {...formData.skip_access_delegation}
          {...handlers}
        />
      </FormSectionWrapperV2>

      <FormSubmitSection submitDisabled={!valid} labels={{ confirm: 'Schedule', cancel: 'Close' }} />
    </>
  );
};
