import React, { useCallback, useMemo } from 'react';

import { useStore } from 'store';

import { IProduct, IScheduledJob } from 'types';
import { FormDate, FormChecklist, FormSelect, useForm, IFormData, IFormHandlers } from 'helpers/form';
import { FormSubmitSection } from 'components/form-submit-section';
import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { IAccessChangeScheduledFormAccess } from 'utils/actions/product/';

import { useScheduledJobs } from './hooks';
import { ScheduledJobList } from 'components/scheduled-job-list';
import { contextExtension } from './utils';
import 'components/scheduled-job-list/style.scss';
import { minAccessDate, maxAccessDate } from 'utils/constants';
import { Model } from 'helpers/filters/types';

const renderDetails = (job: IScheduledJob): JSX.Element => (
  <>
    {job?.access_level}
    <br />
    {job?.permissions?.join(', ')}
  </>
);

export const AccessChangeScheduledAccessForm: React.FC<{
  items: IProduct[];
  showPermissions?: boolean;
  readOnly?: boolean;
}> = ({ items, showPermissions = true, readOnly = false }) => {
  const formId = `product-access-change-scheduled-access`;
  const { toastStore } = useStore();

  const itemIds = useMemo(() => items.map((item) => item.id), [items]);

  const [scheduledJobs, removeJob, scheduleJob] = useScheduledJobs<IAccessChangeScheduledFormAccess>(
    'change_access_level_on_product',
    itemIds,
  );

  const submitForm = useCallback(
    async (data: IAccessChangeScheduledFormAccess): Promise<void> => {
      try {
        await scheduleJob(data);
        toastStore.success('Access changed');
      } catch (error) {
        toastStore.error(error);
      }
    },
    [toastStore, scheduleJob],
  );

  const initialValues =
    items.length > 1
      ? {
          scheduled_for: '',
          access_level: '',
          permissions: [],
          item_ids: itemIds,
        }
      : {
          scheduled_for: '',
          item_ids: itemIds,
          access_level: items[0].access_level,
          permissions: items[0]?.permissions,
        };
  const { formData, handlers, valid, onSubmit } = useForm<IAccessChangeScheduledFormAccess>(
    initialValues,
    Model.ASSETS,
    submitForm,
    contextExtension,
  );

  return (
    <form onSubmit={onSubmit}>
      {Boolean(scheduledJobs.length) && (
        <FormSectionWrapperV2 title="Scheduled access" gridTemplateColumns="1fr" padded>
          <ScheduledJobList
            jobs={scheduledJobs}
            onRemove={removeJob}
            renderDetails={renderDetails}
            readOnly={readOnly}
          />
        </FormSectionWrapperV2>
      )}
      {!readOnly && (
        <FormElements
          valid={valid}
          formId={formId}
          formData={formData}
          handlers={handlers}
          showPermissions={showPermissions}
        />
      )}
    </form>
  );
};

const FormElements: React.FC<{
  formId: string;
  formData: IFormData<IAccessChangeScheduledFormAccess>;
  handlers: IFormHandlers<IAccessChangeScheduledFormAccess>;
  showPermissions: boolean;
  valid: boolean;
}> = ({ formId, formData, handlers, valid, showPermissions }) => {
  return (
    <>
      <FormSectionWrapperV2 title="Schedule new access" gridTemplateColumns="1fr" padded>
        <FormDate
          name="scheduled_for"
          label="Date"
          large
          withTime={true}
          minDate={minAccessDate}
          maxDate={maxAccessDate}
          {...formData.scheduled_for}
          {...handlers}
        />
        <div>
          <FormSelect
            name="access_level"
            label="Access"
            large
            {...formData.access_level}
            {...handlers}
            placeholder="Select Access"
            withinPortal
          />

          {showPermissions && (
            <FormChecklist
              name="permissions"
              label="Permissions"
              formId={formId}
              large
              {...formData.permissions}
              {...handlers}
            />
          )}
        </div>
      </FormSectionWrapperV2>
      <FormSubmitSection submitDisabled={!valid} labels={{ confirm: 'Schedule', cancel: 'Close' }} />
    </>
  );
};
