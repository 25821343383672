import { IContactFields } from './upsert-contact';

export const contactInitialValues: IContactFields = {
  first_name: '',
  last_name: '',
  organization_id: '',
  function: '',
  email: '',
  gender: '',
  date_of_birth: '',
  role_id: '',
  country_id: '',
  responsible_user_id: undefined,
  im_contacts: [],
};
