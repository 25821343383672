import { omitBy } from 'lodash';
import { XHRFileDownload } from '@mediafellows/tuco/dist/lib/helpers';
import { CalGenericMeeting } from '@mediafellows/mm3-types';

import { chipmunk } from 'utils/chipmunk';
import { Model, IPresetFilter } from 'helpers/filters/types';
import { ISearchFilter } from 'types';
import { IInfo } from 'types/calendar';
import { meetingListSchema } from 'utils/schemas/meeting';
import { timezones } from 'utils/timezones';
import { notifications } from '@mantine/notifications';

const propsToFilter = (props: ISearchFilter): IPresetFilter[] => {
  const filters: IPresetFilter[] = [];
  if (!props) return filters;
  props = omitBy(props, (val) => !val) as ISearchFilter;

  const keys: Array<string> = Object.keys(props);
  keys.forEach((key) => {
    if (key === 'starts_at') filters.push([key, 'range', props[key][0], props[key][1]]);
    else filters.push([key, 'eq', props[key]]);
  });

  return filters;
};

export async function getMeetings(
  info: IInfo,
  schema: string = meetingListSchema,
): Promise<Array<CalGenericMeeting & { id: string | number | null }>> {
  return chipmunk.run(async ({ action }) => {
    const { objects } = await action(Model.MEETINGS, 'get', {
      params: info,
      proxy: false,
      schema,
    });

    return objects;
  });
}

export async function searchMeetings(
  props: ISearchFilter,
  options?: { paginated: boolean },
): Promise<Array<CalGenericMeeting & { id: string | number | null }>> {
  const filters = propsToFilter(props);
  return chipmunk.run(async ({ action, unfurl }) => {
    const act = options?.paginated ? action : unfurl;
    const { objects = [] } = await act(Model.MEETINGS, 'search', {
      body: { search: { filters } },
      schema: meetingListSchema,
    });

    return objects;
  });
}

export async function createMeeting({ additional_seats, ...body }: CalGenericMeeting): Promise<CalGenericMeeting> {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.MEETINGS, 'create', {
      body: { ...body, additional_seats: additional_seats || 0 },
    });

    return object;
  });
}

export async function updateMeeting(body: Partial<CalGenericMeeting>): Promise<CalGenericMeeting> {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.MEETINGS, 'update', { params: { meetings_ids: body.id }, body });

    return object;
  });
}

export async function deleteMeeting(ids: number | number[]): Promise<CalGenericMeeting> {
  return chipmunk.run(async ({ action }) => {
    const { objects } = await action(Model.MEETINGS, 'destroy', { params: { meetings_ids: ids } });

    return objects;
  });
}

export async function getMeeting(
  ids: number | number[],
  schema: string = meetingListSchema,
): Promise<CalGenericMeeting> {
  return chipmunk.run(async ({ action }) => {
    const { objects } = await action(Model.MEETINGS, 'get', { params: { meetings_ids: ids }, schema });

    return objects;
  });
}

export const downloadExport = async (props): Promise<void> => {
  props = omitBy(props, (val) => !val);
  const filters = propsToFilter(props);
  const spec = await chipmunk.spec('mm3:cal.meeting');
  const url = spec.action('export_xlsx').template;

  try {
    await XHRFileDownload({
      url: url,
      method: 'POST',
      headers: chipmunk.currentConfig().headers,
      filename: 'export.xlsx',
      body: { search: { filters } },
    });
  } catch (error) {
    const message = error.description || 'Something went wrong!';
    notifications.show({
      color: 'var(--mfx-error)',
      message: message,
    });
  }
};

export const getTimezones = async (): Promise<Array<string>> => {
  return timezones;
};
