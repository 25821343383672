import React, { useCallback, useMemo } from 'react';
import { Select, SelectProps } from '@mantine/core';
import { toNumber, isNumber, isNaN } from 'lodash';

import { getFieldPlaceholder } from 'helpers/form/fields/helpers';
import { IFieldData, IFieldHandlers } from 'helpers/form/types';

import './style.scss';

type IOptionValue = string | number | null | undefined;

export interface IFormSelectOption {
  value: IOptionValue;
  label: string;
}

interface ISelectOption {
  value: string;
  label: string;
}

type IFormatLabelHelper = (label: string | number | undefined) => string | number;
export interface IFormSelectProps
  extends Omit<SelectProps, 'value' | 'onChange' | 'onBlur'>,
    IFieldData<IOptionValue, (number | string | IFormSelectOption)[]>,
    IFieldHandlers<IOptionValue> {
  name: string;
  label?: string;
  large?: boolean;
  inline?: boolean;
  disabled?: boolean;
  placeholder?: string;
  showPositiveValidation?: boolean;
  formatLabel?: IFormatLabelHelper;
  emptyValue?: IOptionValue;
  emptyValueIsUndefined?: boolean;
  noEmptyOption?: boolean;
  withinPortal?: boolean;
}

const preparePropOptions = (
  options?: (IFormSelectOption | string | number)[],
  formatLabel?: IFormatLabelHelper,
): ISelectOption[] => {
  const props = (options || []).map((op) =>
    typeof op === 'object'
      ? { label: op.label, value: op.value?.toString() || '' }
      : { label: '' + (formatLabel?.(op) || op), value: op.toString() || '' },
  );
  return props as ISelectOption[];
};

export const FormSelect: React.FC<IFormSelectProps> = (props) => {
  const {
    showPositiveValidation,
    name,
    label = '',
    options,
    disabled,
    placeholder,
    onChange,
    onBlur,
    validation,
    touched,
    value,
    required,
    formatLabel,
    emptyValue = '',
    emptyValueIsUndefined = false,
    size = 'md',
    withinPortal = false,
  } = props;

  const formattedPlaceholder = getFieldPlaceholder({ placeholder, disabled, defaultPlaceholder: `Select ${label}` });
  const showError = Boolean(touched && !validation?.valid && validation?.errorMessage);
  const showValid = Boolean(showPositiveValidation && touched && validation?.valid);

  const handleBlur = useCallback(() => {
    onBlur?.(name);
  }, [name, onBlur]);

  const handleChange = useCallback(
    (value: IOptionValue) => {
      // select does not support numbers as values
      const parsedValue = toNumber(value);
      const isValueNumber = isNumber(parsedValue) && !isNaN(parsedValue);

      value = isValueNumber ? parsedValue : value;

      const isEmpty = value == '' || (!value && value !== 0);

      if (!isEmpty) {
        onChange?.({ [name]: value });
      } else {
        onChange?.({ [name]: emptyValueIsUndefined ? undefined : emptyValue });
      }

      handleBlur();
    },
    [handleBlur, name, onChange, emptyValue, emptyValueIsUndefined],
  );

  const preparedOptions = useMemo(() => preparePropOptions(options, formatLabel), [formatLabel, options]);
  const formattedValue = value !== undefined && value !== null ? value.toString() : value;
  return (
    <Select
      label={label}
      withAsterisk={required}
      placeholder={formattedPlaceholder}
      name={name}
      data={preparedOptions}
      value={formattedValue}
      onChange={handleChange}
      onBlur={handleBlur}
      searchable
      nothingFoundMessage="Nothing found."
      error={showValid || showError ? validation?.errorMessage : undefined}
      disabled={disabled}
      clearable={!required}
      allowDeselect={!required}
      withCheckIcon={false}
      comboboxProps={{ withinPortal, position: 'bottom', middlewares: { flip: false, shift: false }, offset: 3 }}
      size={size}
      scrollAreaProps={{ type: 'always' }}
    />
  );
};

export default FormSelect;
