import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Title } from '@mantine/core';

import { chipmunk } from 'utils/chipmunk';
import { Globals } from 'types';
import { Wizard, WizardStep } from 'components/wizard';
import { StepPassword, IPasswordFields } from './step-password';
import { useStore } from 'store';
import { Footer } from 'components/footer';
import { IObject } from '@mediafellows/chipmunk/dist/src/action';
import { login } from 'store/session-store';
import { Routes } from 'utils/routes';

import './style.scss';

const enum StepIds {
  Password,
  Picture,
}

const RegistrationConfirmation: React.FC<{}> = observer(() => {
  const { toastStore } = useStore();
  const [currentStep, setCurrentStep] = useState<number>(StepIds.Password);
  const [inProgress, setInProgress] = useState(false);
  const [user, setUser] = useState<IObject | null>(null);
  const navigate = useNavigate();

  const { token } = useParams<{ token: string }>();

  useEffect(() => {
    chipmunk.run(async (chipmunk) => {
      try {
        const result = await chipmunk.action('um.confirmation', 'get', {
          params: { invitation_token: token },
        });
        const user = result.object;
        user.user_terms_accepted = true;
        setUser(user);
      } catch (e) {
        toastStore.error(e.text);
        navigate(Routes.LOGIN);
      }
    });
  }, [navigate, toastStore, token]);

  const handlePasswordContinue = useCallback(
    (data: IPasswordFields): void => {
      setInProgress(true);
      const payload = { ...user, ...data } as IObject;

      chipmunk.run(async (chipmunk) => {
        try {
          await chipmunk.action('um.confirmation', 'update', {
            body: { ...payload },
            params: { invitation_token: token },
          });
          try {
            await login(payload.user_email, payload.user_password);
          } catch (e) {
            toastStore.error(`Log in failed: ${e.txt || 'Something went wrong'}`);
          }
          setCurrentStep(StepIds.Picture);
        } catch (err) {
          const msg = err.object?.description || err.message;
          toastStore.error(`Your account could not be activated: ${msg}`, true);
        }
        setInProgress(false);
      });
    },
    [toastStore, token, user],
  );

  return (
    <div className={`page--registration-confirmation ${Globals.MODE_DARK_CLASS}`}>
      <div className="registration-confirmation">
        <div className="registration-confirmation__head">
          <Title order={3}>Confirm Registration</Title>
        </div>

        <div className="registration-confirmation__line" />

        <div className="registration-confirmation__content px-6">
          <Wizard selectedStepId={currentStep} onChange={setCurrentStep}>
            <WizardStep
              id={StepIds.Password}
              title="Password"
              icon="lock"
              panel={<StepPassword inProgress={inProgress} onContinue={handlePasswordContinue} />}
            />
            <WizardStep
              id={StepIds.Picture}
              title="Profile Picture"
              icon="mugshot"
              disabled={true}
              panel={
                <div className="p-4">
                  Not implemented yet. Go to <Link to="/">Dashboard</Link> instead
                </div>
              }
            />
          </Wizard>
        </div>
      </div>
      <Footer />
    </div>
  );
});

export default RegistrationConfirmation;
