import { IAsset, IMm3Asset } from 'types';
import { useAssetsEditStore } from 'store/hooks';
import { IUseActionsOptionParam } from 'utils/actions/types';

import { shouldDisplayUpdate as shouldDisplay } from './actions-acl';
import { IAssetActionConfig, IAssetActionName } from 'utils/actions/asset/types';
import { getValidIds } from 'utils/general';

export const useUpdateAction = (items: (IAsset | IMm3Asset)[], options: IUseActionsOptionParam): IAssetActionConfig => {
  const { setIsModalOpen, setAssetIds } = useAssetsEditStore();

  return {
    name: IAssetActionName.UPDATE,
    shouldDisplay,
    icon: 'edit',
    title: 'Edit',
    handler: (): void => {
      setIsModalOpen(true, options.onSuccess);
      setAssetIds(getValidIds(items));
    },
  };
};
