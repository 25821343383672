import React from 'react';

import { FormSectionWrapper } from 'components/form-section-wrapper';
import { FormSelect, FormChecklist, FormCheckbox } from 'helpers/form';
import { IFormHandlers, IFormData } from 'helpers/form/types';
import { filterAssetStatusOptions } from 'helpers/form/fields/form-select';

import { hasPower, amWriteProtectedAttributes } from 'utils/powers';

import { IAssetFormFields } from './types';

interface IAssetSettingsSectionProps {
  formData: IFormData<IAssetFormFields>;
  handlers: IFormHandlers<IAssetFormFields>;
  formId: string;
}

export const AssetSettingsSection: React.FC<IAssetSettingsSectionProps> = ({ formData, handlers, formId }) => {
  const statusOptions = filterAssetStatusOptions(formData.status?.options);

  return (
    <FormSectionWrapper gridTemplateColumns="1fr 1fr 1fr" title="Settings">
      <FormSelect name="access_level" label="Access Level" large {...formData.access_level} {...handlers} />
      <FormSelect
        name="status"
        label="Status"
        large
        {...{ ...formData.status, options: statusOptions }}
        {...handlers}
      />

      <FormCheckbox
        name="sharable"
        mainLabel="Sharable"
        label="Allow non-Administrator contacts to share this asset with other contacts"
        formId={formId}
        large
        {...formData.sharable}
        {...handlers}
        disabled={!hasPower(amWriteProtectedAttributes)}
      />

      <FormChecklist
        name="permissions"
        label="Permissions"
        formId={formId}
        large
        {...formData.permissions}
        {...handlers}
      />

      <FormChecklist
        name="protection_levels"
        label="Protection Levels"
        formId={formId}
        large
        {...formData.protection_levels}
        {...handlers}
        className="asset-edit-modal__checklist--inline"
      />
    </FormSectionWrapper>
  );
};

export default AssetSettingsSection;
