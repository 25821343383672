import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';
import { endOfDay, startOfDay, subDays } from 'date-fns';

import { Routes } from 'utils/routes';

import { ProductAncestryBreadcrumbs as AncestryBreadcrumbs } from 'components/breadcrumb-product-ancestry';
import { AsyncSliderSelect } from 'components/slider-select/slider-select';
import { Thumbnail } from 'components/thumbnail';
import { PreviewBarChart } from 'components/preview-bar-chart';
import { SectionHeader } from 'components/section-header';
import ProductPreviewAttributes from 'components/product-preview-tab/preview-attributes';

import { changeProductAccessImmediate } from 'utils/actions/product/api';
import { getHistogramData } from 'utils/apis/analytics';
import useSelectedOption from 'utils/hooks/selected-option';
import { changeAccessLevelSlider } from 'utils/change-access';
import { getProductSubtitle } from 'components/product/product-translated-title/get-product-translated-title';

import { useDataSectionStore } from 'store';
import { IProduct, IHistogramData, DetailsPageTabs } from 'types';

import './style.scss';
import { ACCESS_LEVELS_OPTIONS } from 'utils/constants';
import { hasAncestry } from 'utils/general';
import { useRemote, useProductAncestry } from 'utils/hooks';
import { LinkWithArrow } from 'utils/ui';
import { productListSchema } from 'utils/schemas/product';

const ProductPreviewTab: React.FC<{ overrideProduct?: IProduct }> = observer(({ overrideProduct }) => {
  const { activeItem, active } = useDataSectionStore<IProduct>();
  const product = overrideProduct || activeItem;
  // these are initialized inside the component to make sure that the latest now()
  // is being used if the user goes to a different page and comes back
  // initializing them outside will require the user to refresh their browser
  const end = React.useMemo(() => endOfDay(new Date()), []);
  const start = React.useMemo(() => subDays(startOfDay(end), 13), [end]);

  const fetchProductAnalytics = useCallback(async (): Promise<IHistogramData[]> => {
    const { data } = await getHistogramData({
      entity: 'product',
      queryName: 'product::histogram',
      entityId: active,
      dateRange: [start, end],
    });
    return data;
  }, [active, end, start]);

  const [histogram] = useRemote(fetchProductAnalytics);

  const { id, title, access_level, deleted_at, owner_division_id, type: productType } = product || {};
  const [ancestry] = useProductAncestry(id);

  const handleAccessLevelChange = useCallback(
    (newAccessLevel: string) => {
      return changeAccessLevelSlider({
        apiCall: (data) => changeProductAccessImmediate(data, productListSchema),
        divisionId: owner_division_id,
        newAccessLevel,
      });
    },
    [owner_division_id],
  );

  const CustomRightRenderer = useCallback(
    (): JSX.Element => <LinkWithArrow to={`${Routes.PRODUCTS}/${id}/${DetailsPageTabs.ANALYTICS}`} text="View All" />,
    [id],
  );
  const showBreadcrumbs = hasAncestry(ancestry);
  const selectedOption = useSelectedOption({ value: access_level });
  const subTitle = getProductSubtitle(product);

  return (
    <div
      className={cx(
        'entity-preview',
        { 'd-flex align-items-center justify-content-center': !product },
        { 'entity-preview--deleted': deleted_at },
      )}
    >
      {product && id && (
        <>
          <div className="d-flex flex-column">
            <div className="d-flex flex-column">
              {showBreadcrumbs && <AncestryBreadcrumbs ancestryInfo={ancestry} layout="preview" productId={id} />}
              <div
                className={cx('entity-preview__title product-preview__title', {
                  'product-preview__title--breadcrumbless': !showBreadcrumbs,
                })}
              >
                <h3
                  className={cx({
                    [`access-level-${selectedOption?.color}`]: Boolean(selectedOption),
                  })}
                >
                  {title}
                </h3>
              </div>
              {Boolean(subTitle) ? (
                <div className={`entity-preview__subtitle muted-text`}>
                  <h4>{subTitle}</h4>
                </div>
              ) : (
                <></>
              )}
            </div>

            <div className="entity-preview__thumb">
              <Thumbnail showType assetType={productType} image={product?.inherited_preview_image?.url} />
            </div>

            <div className="entity-preview__actions py-3">
              <div className="d-flex justify-content-around w-100">
                <AsyncSliderSelect
                  onChange={handleAccessLevelChange}
                  value={access_level}
                  options={ACCESS_LEVELS_OPTIONS}
                />
                {!deleted_at && (
                  <LinkWithArrow to={`${Routes.PRODUCTS}/${id}/${DetailsPageTabs.OVERVIEW}`} text="View Details" />
                )}
              </div>
            </div>
          </div>
          <div className="my-3">
            <ProductPreviewAttributes />
            <div className="product-preview__analytics">
              <SectionHeader useBackground title="View Activity" {...(!deleted_at && { CustomRightRenderer })} />
              <div className="product-preview__analytics__graph">
                <PreviewBarChart data={histogram} title="View Activity" startDate={start} endDate={end} unit="view" />
              </div>
            </div>
          </div>
        </>
      )}
      {!product && <span className="entity-preview-tab__no-preview">No preview</span>}
    </div>
  );
});
export default ProductPreviewTab;
