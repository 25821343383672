import React from 'react';

import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { FormDate, FormInput } from 'helpers/form';
import { IProductEditFormProps, maxDate, minDate } from './utils';

export const ProductEditMarketScreeningsSection: React.FC<IProductEditFormProps> = ({ layerForm, productForm }) => {
  const { formData, handlers } = layerForm;
  const isEditing = Boolean(productForm.values?.id);

  return (
    <FormSectionWrapperV2 className="product-create_html-editors-container" title="Fabric">
      <FormDate
        name="meta.release_year"
        key="release_year"
        label="Release Year"
        maxDate={maxDate}
        minDate={minDate}
        {...formData.meta?.release_year}
        {...handlers}
        large
        emptyValue={null}
        disabled={isEditing}
      />
      <FormInput
        className="sales_synopsis"
        name="meta.sales_synopsis"
        key="sales_synopsis"
        label="Sales Synopsis - extras"
        type="textarea"
        {...formData.meta?.sales_synopsis}
        {...handlers}
        disabled={isEditing}
      />

      <FormInput
        className="press_reviews"
        name="meta.press_reviews"
        key="press_reviews"
        label="Press Reviews"
        {...formData.meta?.press_reviews}
        {...handlers}
        disabled={isEditing}
      />

      <FormInput
        className="press_quotes"
        name="meta.press_quotes"
        key="press_quotes"
        label="Press Quotes"
        {...formData.meta?.press_quotes}
        {...handlers}
        disabled={isEditing}
      />

      <FormInput
        name="meta.awards"
        key="awards"
        label="Awards"
        {...formData.meta?.awards}
        {...handlers}
        disabled={isEditing}
      />
      <FormInput
        name="meta.ratings"
        key="ratings"
        label="Ratings"
        {...formData.meta?.ratings}
        {...handlers}
        disabled={isEditing}
      />
      <FormInput
        name="meta.cast_and_crew"
        key="cast_and_crew"
        label="Cast & Crew"
        textarea
        {...formData.meta?.cast_and_crew}
        {...handlers}
        disabled={isEditing}
      />
      <FormInput
        name="meta.aspect_ratio"
        key="aspect_ratio"
        label="Aspect Ratio"
        {...formData.meta?.aspect_ratio}
        {...handlers}
        disabled={isEditing}
      />
      <FormInput
        name="meta.fabric_id"
        key="fabric_id"
        label="Fabric ID"
        {...productForm.formData.meta?.fabric_id}
        {...productForm.handlers}
        disabled={isEditing}
      />
    </FormSectionWrapperV2>
  );
};

export default ProductEditMarketScreeningsSection;
