import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import { Routes } from 'utils/routes';
import { Row, yn } from 'components/label-value-pair';
import { AssetFilters } from 'utils/asset';
import { PreviewInfo } from 'components/preview-tab';
import { ScheduledChangeIndicator } from 'components/common/scheduled-change-info';
import { AssetStatusIndicator } from 'components/asset';
import { Mm3AssetTypesLink } from 'components/asset/assets-aggregation/product-assets';

import { useBasicStore, useDataSectionStore } from 'store';
import { IMm3Asset, DetailsPageTabs } from 'types';

import { queryAllAssets } from 'utils/apis/asset';
import { formatDuration, formatDate } from 'utils/date';
import { getEntityType, formatAssetType, formatResolution, parseDataSize, formatField } from 'utils/general';
import { useRemote } from 'utils/hooks';

import { parseMm3Subtitles } from './utils';

import './style.scss';

export const AssetMm3PreviewAttributes: React.FC = observer(() => {
  const { activeItem: asset } = useDataSectionStore<IMm3Asset>();

  const {
    access_level,
    classification,
    created_at,
    deleted_at,
    file_size,
    id,
    meta,
    permissions,
    products,
    protection_levels,
    published_at,
    status,
    updated_at,
    sharable,
  } = asset || {};

  const { duration, language } = meta || {};
  const detailsPageLink = `${Routes.ASSETS}/${id}`;

  const productsCount = products?.length ? (
    <Link to={`${detailsPageLink}/${DetailsPageTabs.PRODUCTS}`}>{products.length}</Link>
  ) : (
    '0'
  );
  const assetItemType = getEntityType(classification);
  const isVideo = assetItemType === AssetFilters.VIDEO;
  const isImage = assetItemType === AssetFilters.IMAGE;
  const isAudio = assetItemType === AssetFilters.AUDIO;
  const hasResolution = isImage || isVideo;
  const hasDuration = isVideo || isAudio;
  const fetchSubtitleAssets = useCallback(async (): Promise<IMm3Asset[]> => {
    if (!id || !isVideo) {
      return [];
    }
    return queryAllAssets({}, [
      ['type', 'eq', 'Asset3::Digital::Subtitle'],
      ['parent_id', 'eq', id],
    ]);
  }, [id, isVideo]);

  const [subtitles = []] = useRemote(fetchSubtitleAssets, []);

  const { languages } = useBasicStore();
  const subs = parseMm3Subtitles('subtitle', languages, subtitles);
  const captions = parseMm3Subtitles('caption', languages, subtitles);

  if (!id) {
    return <></>;
  }
  return (
    <PreviewInfo>
      <Row
        label="Sub-Assets"
        value={
          <Mm3AssetTypesLink
            assets={asset?.children}
            fallback="-"
            muted
            baseUrl={`${Routes.ASSETS}/${id}/${DetailsPageTabs.SUB_ASSETS}`}
          />
        }
        size="m"
        rawLabel
      />
      <Row label="File type" value={assetItemType} size="m" rawLabel />
      <Row label="Asset type" value={formatAssetType(classification)} size="m" rawLabel />
      <Row label="Resolution" value={formatResolution(meta)} size="m" hidden={!hasResolution} />
      <Row label="File size" hideIfNoValue value={parseDataSize(file_size)} size="m" rawLabel />
      <Row label="Runtime" value={formatDuration(duration, 'seconds')} size="m" hidden={!hasDuration} />
      <Row
        label="Status"
        value={
          <>
            {status} <ScheduledChangeIndicator entity={asset} type="status" />
            {asset && <AssetStatusIndicator asset={asset} />}
          </>
        }
        size="m"
      />
      <Row
        label="Access level"
        value={
          <>
            {access_level} <ScheduledChangeIndicator entity={asset} type="access" />
          </>
        }
        size="m"
        rawLabel
      />
      <Row label="Sharable" value={yn(sharable)} size="m" />

      <Row
        label="Permissions"
        value={
          <>
            {permissions?.includes('download') ? 'Download' : '[N/A]'}
            <ScheduledChangeIndicator entity={asset} type="permissions" />
          </>
        }
        size="m"
      />
      <Row
        label="Protection levels"
        value={
          <>
            {formatField(protection_levels, '[N/A]')} <ScheduledChangeIndicator entity={asset} type="protection" />
          </>
        }
        rawLabel
      />
      <Row label="Language" value={language?.name || 'No languages'} size="m" />
      <Row label="Products" value={productsCount} size="m" />
      <Row label="Created at" value={formatDate(created_at)} rawLabel />
      <Row label="Updated at" value={formatDate(updated_at)} rawLabel />
      <Row label="Published at" value={formatDate(published_at) || '[N/A]'} rawLabel />
      <Row label="Deleted at" value={formatDate(deleted_at)} hidden={!deleted_at} rawLabel />
      <Row label="Subtitles" value={subs} size="m" hidden={isVideo} />
      <Row label="captions" value={captions} size="m" hidden={isVideo} />
      {/* <Row label="Audio Tracks" value={audioCount} size="m" hidden={isVideo} /> */}
    </PreviewInfo>
  );
});
