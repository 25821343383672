import React, { useCallback } from 'react';
import { startCase } from 'lodash';

import { Text } from 'components/text';
import { FormCheckbox, FormMultiSelect, FormProducts, FormRemoteSelect, IUseFormReturn } from 'helpers/form';

import { useFutureDescendantsFlag } from 'components/access-privilege';
import { GroupTypes, IAsset, IGroup, IGroupVariety, IItem, IQueryParams, ISearchFilter } from 'types';
import { getEntityLabel } from 'utils/general';
import { formatGroupName } from 'utils/group';
import { buildFilters } from './utils';
import { IAddToGroupForm, IAddToGroupEntityType } from './types';

import { queryContacts } from 'utils/apis/contacts';
import { queryEvents } from 'utils/apis/events';
import { fetchGroups } from 'utils/apis/groups';
import { queryOrganizations } from 'utils/apis/organization';
import { queryProducts, fetchProductsWithAncestry } from 'utils/apis/product';
import { queryQueue, queryRecipientContacts, queryContactSelections } from 'utils/apis/recipients';
import { queryMainAssets } from 'utils/apis/asset';
import { queryAssetSelections } from 'utils/apis/selection';
import { getRecommendationAssetsFilters } from 'utils/recommendation';

import { IMarketingEvent } from 'types';

interface IFormAddToGroupItemsForm {
  form: IUseFormReturn<IAddToGroupForm>;
  entity: IAddToGroupEntityType;
  disabledItemsSelector?: boolean;
  disabledGroupSelector?: boolean;
  hideGroupSelector?: boolean;
  hideProductsParams?: boolean;
  addSelectedProductAncestors?: boolean;

  items?: Array<{ label: string; value: number | string }>;
  group?: IGroupVariety;
  groupType?: string;
  groupLabel?: string;

  omni?: boolean;
}

const fetchValues: {
  [k in IAddToGroupEntityType];
} = {
  assets: queryMainAssets,
  assets_and_selections: queryQueue<IAsset | IGroup>([queryMainAssets, queryAssetSelections]),
  products: queryProducts,
  users: queryContacts,
  events: queryEvents,
  organizations: queryOrganizations,
  contacts_and_selections: queryQueue([queryRecipientContacts, queryContactSelections]),
  contact_selections: queryContactSelections,
};

const addParentProductsMessage = (
  <Text>
    All products will be added with their full ancestry (necessary for the correct display of privileged access on the
    client-facing site).
  </Text>
);

export const FormAddToGroupItemsForm: React.FC<IFormAddToGroupItemsForm> = ({
  items,
  entity,
  group,
  groupType,
  disabledItemsSelector = false,
  disabledGroupSelector = false,
  hideGroupSelector = false,
  form,
  omni = false,
  hideProductsParams = false,
  groupLabel: groupLabelOverride,
  addSelectedProductAncestors = false,
}) => {
  const { formData, handlers } = form;

  const groupLabel = groupLabelOverride ?? formatGroupName(groupType);
  const itemLabel = startCase(getEntityLabel(entity));

  const loadGroups = useCallback(
    async (query: string): Promise<IGroup[]> => {
      if (!groupType) {
        return [];
      }
      const filters = buildFilters({ groupId: group?.id, query, groupType, entity });
      return fetchGroups(filters);
    },
    [entity, groupType, group?.id],
  );

  const fetchProductsOverride = useCallback(
    (params, filters) => {
      const defaultFilter = filters || [['parent_id', 'not_exist']];

      if (group?.id) {
        defaultFilter.push(['group_ids', 'ne', group.id]);
      }

      return fetchProductsWithAncestry(params, defaultFilter);
    },
    [group],
  );

  const fetchItems = useCallback(
    async (params: IQueryParams): Promise<IItem[]> => {
      const filters: ISearchFilter[] = entity === 'assets' ? [['parent_id', 'not_exist']] : [];

      if (group?.id && !(groupType === GroupTypes.EVENT && entity === 'events')) {
        filters.push(['group_ids', 'ne', group?.id]);
      }

      if (groupType === GroupTypes.EVENT) {
        const event = group as IMarketingEvent;
        event?.subevent_group_ids &&
          entity === 'events' &&
          filters.push(['id', 'not_in', [...event?.subevent_group_ids]]);
        entity === 'assets' && filters.push(['status', 'eq', 'available']);
      }
      if (groupType === GroupTypes.MOBILE_APP_SYNC && entity === 'assets') {
        filters.push(...getRecommendationAssetsFilters());
      }
      return fetchValues[entity](params, filters);
    },
    [entity, group, groupType],
  );

  const handleContactsChange = useCallback(
    (values) => {
      handlers.onChange({ contacts: values, item_ids: (values || []).reduce((acc, item) => [item.id, ...acc], []) });
    },
    [handlers],
  );

  useFutureDescendantsFlag(form);

  const isProducts = entity === 'products';

  return (
    <>
      {isProducts ? (
        <FormProducts
          name="item_ids"
          label={itemLabel}
          {...formData.item_ids}
          disabled={disabledItemsSelector}
          large
          omni={omni}
          fetchValues={fetchProductsOverride}
          {...handlers}
        />
      ) : (
        <FormMultiSelect
          fetchValues={fetchItems}
          name="item_ids"
          label={itemLabel}
          {...formData.item_ids}
          onBlur={handlers.onBlur}
          options={items}
          disabled={disabledItemsSelector}
          large
          omni={omni}
          onSelectedItemsChange={handleContactsChange}
        />
      )}

      {addSelectedProductAncestors && addParentProductsMessage}

      {!hideGroupSelector && (
        <FormRemoteSelect
          name="group_id"
          label={groupLabel}
          {...formData.group_id}
          {...handlers}
          fetchOptions={loadGroups}
          disabled={disabledGroupSelector}
          large
        />
      )}

      {isProducts && !hideProductsParams && (
        <>
          <FormCheckbox
            name="include_descendants"
            label="Add product(s) including descendants"
            {...formData.include_descendants}
            {...handlers}
            large
          />
          {groupType === GroupTypes.ACCESS_PRIVILEGE && (
            <>
              <FormCheckbox
                label="Automatically include all future descendants"
                name="include_future_descendants"
                large
                className="ms-3 mb-4"
                {...form.formData.include_future_descendants}
                {...form.handlers}
                disabled={!form.values.include_descendants}
              />
              <FormCheckbox
                name="delegates_access"
                label="Include the Products’ unpublished Assets"
                {...formData.delegates_access}
                {...handlers}
                large
              />
              <FormCheckbox
                label="Permit asset download"
                name="permit_download"
                large
                {...form.formData.permit_download}
                {...form.handlers}
              />
            </>
          )}
        </>
      )}
    </>
  );
};
