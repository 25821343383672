import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { ActionIcon } from '@mantine/core';

import { ModalWrapper } from 'components/dialogs';
import { Model } from 'helpers/filters/types';
import { FormInput, useForm } from 'helpers/form';
import { useAssetsEditStore } from 'store/hooks';
import { IAsset } from 'types';
import { editAssetPresetInitialData } from 'components/asset/asset-edit-form/constants';
import { STORAGE_KEYS, getStorageItem, setStorageItem } from 'utils/storage';
import { MantineIcon } from 'utils/ui';

interface ISavePresetFormFields {
  name: string;
}

const initialValues: ISavePresetFormFields = { name: '' };

export interface ISaveAssetPresetFormProps {
  closeModal: () => void;
  preset: IAsset;
  setPresets: React.Dispatch<React.SetStateAction<object>>;
}

export interface ISaveAssetPresetProps {
  setPresets: React.Dispatch<React.SetStateAction<object>>;
}

const SaveAssetPresetForm: React.FC<ISaveAssetPresetFormProps> = ({ closeModal, preset, setPresets }) => {
  const handleSubmit = useCallback(
    ({ name }: ISavePresetFormFields): void => {
      const saved = getStorageItem(STORAGE_KEYS.ASSET_EDIT_PRESETS);
      setStorageItem<Record<string, IAsset>>(STORAGE_KEYS.ASSET_EDIT_PRESETS, {
        ...saved,
        [name]: { ...editAssetPresetInitialData, ...preset } as unknown as IAsset,
      });
      setPresets(getStorageItem(STORAGE_KEYS.ASSET_EDIT_PRESETS));
      closeModal();
    },
    [closeModal, preset, setPresets],
  );

  const { formData, handlers, onSubmit } = useForm(initialValues, Model.ASSETS, handleSubmit);

  return (
    <form onSubmit={onSubmit} style={{ display: 'grid', gridTemplateColumns: '1fr 20px' }}>
      <FormInput
        label="Add Preset Name"
        placeholder="Type Preset Name"
        name="name"
        {...formData.name}
        {...handlers}
        size="xs"
      />
      <ActionIcon variant="subtle" color="gray.5" type="submit" mt="30px">
        <MantineIcon icon="floppy-disk" />
      </ActionIcon>
    </form>
  );
};

export const SavePresetModal: React.FC<ISaveAssetPresetProps> = observer(({ setPresets }) => {
  const { preset, isSavePresetModalOpen: opened, setIsSavePresetModalOpen } = useAssetsEditStore();

  const onClose = useCallback((): void => {
    setIsSavePresetModalOpen(false);
  }, [setIsSavePresetModalOpen]);

  const body = useCallback(() => {
    return <SaveAssetPresetForm closeModal={onClose} preset={preset} setPresets={setPresets} />;
  }, [onClose, preset, setPresets]);

  return <ModalWrapper title="Save As Preset" {...{ opened, onClose, body }} />;
});
