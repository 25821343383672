import { useRecommendMobileSelectionDialog } from 'components/recommendation';
import { IMobileAppSync } from 'types';

import { IUseAction } from 'utils/actions';

import { IMobileAppSyncActionName } from './types';
import { shouldDisplayRecommendMobileSelection as shouldDisplay } from './actions-acl';

export const useRecommendAction: IUseAction<IMobileAppSync, IMobileAppSyncActionName> = (items, options) => {
  const [handler] = useRecommendMobileSelectionDialog({ group: items?.[0], options });

  return {
    name: IMobileAppSyncActionName.RECOMMEND,
    icon: 'send-message',
    title: 'Recommend',
    shouldDisplay,
    handler,
  };
};
