import { IUseActionsOptionParam } from 'utils/actions/types';
import { IAsset, IMm3Asset } from 'types';

import { useUploadImageDialog } from 'components/upload-preview-image';

import { uploadAsset } from './api';
import { shouldDisplayUploadAsset as shouldDisplay } from './actions-acl';
import { IAssetActionConfig, IAssetActionName } from 'utils/actions/asset/types';

// TODO: handle other and subtitle asset type
export const extensionsByClassification = {
  image: ['image/*'],
  video: ['video/*'],
  audio: '.aac .ac3 .aif .aiff .amr .ape .dts .flac .m4a .mka .mks .mp3 .oga .ogg .wav .wma'.split(' '),
  document: '.doc .docx .pdf .txt .rtf .odt .ott .tex .xls .xlsx .ppt .pptx .csv .html .htm .xml .md'.split(' '),
};

export const useUploadAssetAction = (
  items: (IAsset | IMm3Asset)[],
  options: IUseActionsOptionParam,
): IAssetActionConfig => {
  const [openUploadImageDialog] = useUploadImageDialog({
    onSubmit: async (file) => {
      if (file && items?.[0]?.id) {
        await uploadAsset(items[0].id, file);
        options.onSuccess?.(IAssetActionName.UPLOAD_ASSET, items[0]);
      }
    },
    extensions: extensionsByClassification[items?.[0]?.classification || 'image'],
    customMessage: 'Select a file to upload',
  });

  return {
    name: IAssetActionName.UPLOAD_ASSET,
    shouldDisplay,
    icon: 'upload',
    title: 'Upload File',
    handler: openUploadImageDialog,
  };
};
