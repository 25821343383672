import React, { useState, useEffect, useCallback, useRef } from 'react';
import { InputGroupProps } from '@blueprintjs/core';
import SunEditor from 'suneditor-react';
import SunEditorCore from 'suneditor/src/lib/core';
import { useIsFirstRender } from '@mantine/hooks';
import cx from 'classnames';

import { IFieldData, IFieldHandlers } from 'helpers/form/types';
import { formatFormLabel } from 'helpers/form/fields/helpers';
import { FormGroup } from 'helpers/form/fields/form-group';
import { Intent } from 'utils/ui';
import { IStyled } from 'types';

import 'suneditor/src/assets/css/suneditor-contents.css';
import 'suneditor/src/assets/css/suneditor.css';
import './style.scss';

export interface IFormHtmlInputProps
  extends Omit<Omit<InputGroupProps, 'value'>, 'onChange'>,
    IFieldData<string | null>,
    IFieldHandlers<string>,
    IStyled {
  name: string;
  type?: string;
  label?: string;
  showPositiveValidation?: boolean;
  formId?: string;
  inline?: boolean;
  height?: string;
  large?: boolean;
}

const buttonList = [
  ['formatBlock'],
  ['bold', 'underline', 'italic', 'removeFormat'],
  // ['fontColor', 'hiliteColor'],
  ['list', 'outdent', 'indent', 'align'],
  ['link', 'horizontalRule'],
  ['fullScreen', 'codeView'],
];
const options = { buttonList, linkNoPrefix: true };

const getElementClassname = (name: string): string => {
  const normalized = name.replace(/[^a-zA-Z0-9\-]/g, '_');
  return `html-edit-${normalized}`;
};

const FormHtmlInput: React.FC<IFormHtmlInputProps> = (props) => {
  const {
    label = '',
    name,
    placeholder,
    onChange,
    onBlur,
    validation,
    touched,
    value,
    required,
    inline = false,
    style,
    className,
    disabled,
    large = false,
    ...rest
  } = props;
  const [isCodeView, setCodeView] = useState(false);
  const firstRender = useIsFirstRender();

  const { height = large ? '139' : '108' } = props;

  const showError = Boolean(touched && !validation?.valid && validation?.errorMessage);
  const intent = showError ? Intent.DANGER : Intent.NONE;

  const editorWrapper = React.useRef<HTMLInputElement>(null);
  const editor = useRef<SunEditorCore>();

  const handleChange = useCallback(
    (value) => {
      // get text only from editor without html tags for validation
      const text = editor?.current?.getText().trim() as string;
      const inputValue = !text.length ? text : value;
      onChange?.({ [name]: inputValue });
    },
    [name, onChange],
  );

  const handleBlur = useCallback(() => {
    onBlur?.(name);
  }, [name, onBlur]);

  const groupClassName = inline ? 'form-input-inline__container' : className;
  const elementClassname = getElementClassname(name);

  const getSunEditorInstance = useCallback((sunEditor) => {
    editor.current = sunEditor;
  }, []);

  const handleMouseOut = useCallback((): void => {
    if (isCodeView) {
      editor.current?.core.toggleCodeView();
      editor.current?.core.toggleCodeView();
    }
  }, [isCodeView]);

  useEffect(() => {
    const element = editorWrapper.current;
    if (element && isCodeView) {
      element.addEventListener('mouseleave', handleMouseOut);
    }

    return () => {
      if (element) {
        element.removeEventListener('mouseleave', handleMouseOut);
      }
    };
  }, [handleMouseOut, isCodeView]);

  const toggleCodeView = (codeView: boolean): void => {
    setCodeView(codeView);
  };

  useEffect(() => {
    if (!firstRender) return;
    const previewElements = document.querySelectorAll('.se-dialog-form');
    previewElements.forEach((previewElement) => {
      const label = previewElement.querySelector('label');
      if (
        label &&
        label?.textContent?.trim() === 'URL to link' &&
        !label.nextElementSibling?.classList.contains('url-hint')
      ) {
        const newDiv = document.createElement('div');
        newDiv.textContent = 'Please make sure the URL starts with https:// or http://';
        newDiv.classList.add('url-hint');
        label.insertAdjacentElement('afterend', newDiv);
      }
    });
  }, [firstRender]);

  return (
    <div ref={editorWrapper}>
      <FormGroup
        label={formatFormLabel(label, required)}
        labelFor={name}
        intent={intent}
        inline={inline}
        helperText={showError ? validation?.errorMessage : ''}
        className={cx(['form-html-input-wrapper', groupClassName, elementClassname])}
        style={{ ...style, minWidth: 'fit-content' }}
      >
        <SunEditor
          name={name}
          toggleCodeView={toggleCodeView}
          setContents={value || ''}
          placeholder={placeholder}
          onChange={handleChange}
          onBlur={handleBlur}
          disable={disabled}
          setOptions={options}
          height={height}
          getSunEditorInstance={getSunEditorInstance}
          {...rest}
        />
      </FormGroup>
    </div>
  );
};

export default FormHtmlInput;
