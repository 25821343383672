import React, { useCallback, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { RangeSlider, Flex, RangeSliderProps } from '@mantine/core';
import { ActionIcon } from '@mantine/core';
import { RangeFilter } from 'helpers/filters/types';
import { MantineIcon } from 'utils/ui';

import { FormGroup } from 'helpers/form/fields/form-group';

import './style.scss';

type ISliderFilterDefinition = RangeFilter;

interface IFilterSliderProps extends Omit<RangeSliderProps, 'onChange'> {
  name: string;
  label: string;
  min: number;
  max: number;
  large?: boolean;
  disabled?: boolean;
  filter: ISliderFilterDefinition;
  marks?: { value: number; label: React.ReactNode }[];
  onChange?: (newValue: { [key: string]: ISliderFilterDefinition }) => void;
}

const FilterSlider: React.FC<IFilterSliderProps> = observer((props) => {
  const { name, label, filter, onChange, min, max, marks, ...restProps } = props;
  const [value, setValue] = useState<[number, number]>([min, max]);
  const [isDragging, setIsDragging] = useState(false);
  const disableReset = value[0] === min && value[1] === max;

  useEffect(() => {
    if (isDragging) {
      return;
    }

    if (filter.start !== value[0] || filter.end !== value[1]) {
      setValue([filter.start as number, filter.end as number]);
    }
  }, [filter, value, isDragging]);

  const handleChange = useCallback(
    (value: [number, number]): void => {
      onChange && onChange({ [name]: { ...filter, start: value[0], end: value[1] } });
      setIsDragging(false);
    },
    [filter, name, onChange],
  );

  const updateCurrentValue = useCallback((value: [number, number]): void => {
    setIsDragging(true);
    setValue(value);
  }, []);

  const handleReset = useCallback((): void => {
    onChange && onChange({ [name]: { ...filter, start: min, end: max } });
    setIsDragging(false);
  }, [filter, name, onChange, min, max]);

  return (
    <FormGroup style={{ color: 'var(--mfx-gray-1--dark)' }} label={label}>
      <Flex className="w-100 mb-3" align="center">
        <RangeSlider
          value={value}
          labelAlwaysOn
          min={min}
          max={max}
          marks={marks}
          onChange={updateCurrentValue}
          onChangeEnd={handleChange}
          className="w-100"
          {...restProps}
        />
        <ActionIcon variant="subtle" color="gray.5" disabled={disableReset} onClick={handleReset}>
          <MantineIcon icon={'cross'} />
        </ActionIcon>
      </Flex>
    </FormGroup>
  );
});

export default FilterSlider;
