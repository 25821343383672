import { AmGenericAsset3 } from '@mediafellows/mm3-types';
import { UppyFile, UploadedUppyFile } from '@uppy/core';

import { ItemId } from 'types';

export interface IAssetFields extends IAssetPresetFields {
  id: string;
  selected: boolean;
  file_name: string;
  name: string;
  preview?: string;
  uploadURL?: string;
  type: string;
}

export interface IAssetPresetFields {
  protection_levels: string[];
  watermark: string;
  access_level: string;
  permissions: string[];
  languages: string[];
  burned_in_subtitles_language_id?: string;
  status: string;
  classification: string;
  products: string[];
  parent_id?: ItemId;
  sharable: boolean;
  'product.marketing_use': boolean;
  'layer.notes': string;
  dubbed_language_id?: string;
}

export interface IAssetFile extends IAssetFields {
  fileData: Partial<UppyFile>;
  uploadURL?: string;
}

export interface IAssetPayload {
  id: string;
  selected: boolean;
  file_name: string;
  name: string;
  preview?: string;
  type: string;
  protection_levels: string[];
  watermark: string;
  access_level: string;
  permissions: string[];
  languages: string[];
  sharable: boolean;
  status: string;
  classification: string;
  products: string[];
  product: { marketing_use: boolean };
  layer: { notes: string };
  burned_in_subtitles_language_id?: string;
  dubbed_language_id?: string;
}

export interface IMm3AssetPayload {
  id: string;
  selected: boolean;
  file_name: string;
  name: string;
  preview?: string;
  type: string;
  protection_levels: Array<'2fa' | 'drm' | 'watermark'>;
  watermark: string;
  access_level: AmGenericAsset3['access_level'];
  permissions: AmGenericAsset3['permissions'];
  languages: string[];
  sharable: boolean;
  status: AmGenericAsset3['status'];
  classification: AmGenericAsset3['classification'];
  products: string[];
  product: { marketing_use: boolean };
  parent_id: number;
  layer: { notes: string };
  burned_in_subtitles_language_id?: string;
  dubbed_language_id?: string;
}

export type IUploadedFile = UploadedUppyFile<{}, {}> & { orientation?: string };

export interface IAssetToProduct {
  id: number;
  asset_ids: ItemId[];
  product_ids: ItemId[];
  marketing_use: boolean;
  include_descendants: boolean;
}

export interface IResolution {
  width?: number | null;
  height?: number | null;
}

export type IAssetMainClassification = 'video' | 'audio' | 'image' | 'document';
export enum IAssetMainClassificationValues {
  VIDEO = 'video',
  AUDIO = 'audio',
  IMAGE = 'image',
  DOCUMENT = 'document',
}

export type IAssetAggregation = {
  [P: `count_by_${string}`]: {
    count: number;
    value: IAssetMainClassification;
  }[];
};

export enum AssetStatus {
  AVAILABLE = 'available',
  CREATED = 'created',
  REVIEW = 'review',
}

export enum IngestStatus {
  INVALID = 'invalid',
  AVAILABLE = 'available',
  READY = 'ready',
}
