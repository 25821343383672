import React, { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';

import SectionMessage, { SectionMessageType } from 'components/section-message/section-message';
import { ISectionHeaderAction, SectionHeader } from 'components/section-header';
import { DashboardBreadcrumbs } from 'components/dashboard-breadcrumbs';
import { Loading } from 'components/loading';
import { useEventCreateForm, useEventSave } from 'components/event-create';
import { EventActionsList } from 'components/action';
import { EventAncestryBreadcrumbs } from 'components/breadcrumb-event-ancestry';

import { IMarketingEventActionName } from 'utils/actions/event/types';
import { EventDetailsTabsView } from './event-details-tabs-view';

import { changeGroupAccessImmediate, loadAssetGroupItems, loadGroup } from 'utils/apis/groups';
import { deleteUnreferencedAttachments } from 'utils/apis/attachment';

import { useEditMode, useRefreshDataSection, useRemote, useTab } from 'utils/hooks';
import { getAttendeesCount } from 'utils/event-utils';
import { EventDetailsSchema } from 'utils/schemas/event';

import { Pages } from 'utils/actions';
import { DetailsPageTabs, IMarketingEvent } from 'types';
import { changeAccessLevelSlider } from 'utils/change-access';
import { IActionName } from 'utils/actions/types';
import { IGroupActionName } from 'utils/actions/common/types';
import { Classes } from 'utils/ui';
import { Routes } from 'utils/routes';
import { useNavigate } from 'react-router';
import './style.scss';
import { ActionsMenu } from 'components/action/utils';

const eventAttachementKeys = [
  'event_not_started_background_image_url',
  'event_not_started_featured_image_url',
  'event_started_not_loggedin_background_image_url',
  'event_started_not_loggedin_featured_image_url',
  'event_started_loggedin_background_image_url',
  'event_started_loggedin_featured_image_url',
  'event_finished_background_image_url',
  'event_finished_featured_image_url',
];
const editableTabs = [DetailsPageTabs.OVERVIEW, DetailsPageTabs.LAYOUT, DetailsPageTabs.ATTENDEES];
const tabOptions = [
  DetailsPageTabs.OVERVIEW,
  DetailsPageTabs.LAYOUT,
  DetailsPageTabs.ATTENDEES,
  DetailsPageTabs.PRODUCTS,
  DetailsPageTabs.ASSETS,
  DetailsPageTabs.SUB_EVENTS,
  DetailsPageTabs.ANALYTICS,
  DetailsPageTabs.TIMELINE,
];

const EventDetails: React.FC<{}> = observer(() => {
  const navigate = useNavigate();

  const { eventId } = useParams<{ eventId: string; tabLevel1?: DetailsPageTabs }>();
  const [editModeEnabled, setEditModeEnabled] = useEditMode();
  const [isSortModeEnabled, setIsSortModeEnabled] = useState<boolean>(false);

  const [activeTab, setActiveTab] = useTab(DetailsPageTabs.OVERVIEW, tabOptions);

  const fetchEvent = useCallback(async () => {
    if (eventId) {
      return loadGroup<IMarketingEvent>(eventId, EventDetailsSchema);
    }
  }, [eventId]);
  const [event, loading, setEvent, refreshEvent] = useRemote(fetchEvent);

  const fetchParentEvent = useCallback(async () => {
    if (event && event.subevent && event.group_ids?.length) {
      return loadGroup<IMarketingEvent>(event.group_ids[0], 'id, name');
    }
  }, [event]);
  const [parentEvent, , ,] = useRemote<IMarketingEvent>(fetchParentEvent);

  const fetchAssetTypes = useCallback(async (): Promise<{ classification?: string; type?: string }[]> => {
    if (!eventId) {
      return [];
    }
    return loadAssetGroupItems({
      schema: 'id, type, classification',
      group_ids: eventId,
    }) as Promise<{ classification?: string; type?: string }[]>;
  }, [eventId]);
  const [assets = []] = useRemote(fetchAssetTypes);

  const onTabChangeHandle = useCallback(
    (newTabId: DetailsPageTabs) => {
      setActiveTab(newTabId);
      navigate(`/events/${eventId}/${newTabId}${editModeEnabled ? '?edit=1' : ''}`);
    },
    [navigate, editModeEnabled, eventId, setActiveTab],
  );

  const { name, pm_statistics_data, access_level, owner } = event || {};

  const attendeesCount = useMemo(() => {
    if (event) {
      return getAttendeesCount(event);
    }
  }, [event]);

  const refreshDataSection = useRefreshDataSection();

  const onSuccess = useCallback(
    async (action?: IActionName, newEvent?: IMarketingEvent): Promise<void> => {
      switch (action) {
        case IMarketingEventActionName.REMOVE_FROM_GROUP:
        case IGroupActionName.ADD_ENTITIES:
          setTimeout(refreshEvent, 1000);
          break;
        case IMarketingEventActionName.DELETE:
          navigate(`${Routes.EVENTS}`);
          break;
        case IGroupActionName.UPLOAD_PREVIEW:
          newEvent &&
            setEvent((prev) => ({
              ...prev,
              preview_image: newEvent?.preview_image,
              preview_image_id: newEvent?.preview_image_id,
            }));
        default:
          refreshDataSection();
      }
    },
    [navigate, refreshDataSection, refreshEvent, setEvent],
  );

  const onSaveSuccess = useCallback(
    async (result?: IMarketingEvent) => {
      await deleteUnreferencedAttachments(event, result, eventAttachementKeys);
      setEditModeEnabled(false);
      refreshEvent();
    },
    [event, setEditModeEnabled, refreshEvent],
  );

  const onEventSave = useEventSave({ onSuccess: onSaveSuccess });
  const form = useEventCreateForm({
    saveEvent: onEventSave,
    values:
      {
        ...event,
        eventTiming: event?.is_timed_event ? 'timed' : 'persistent',
        eventType: event?.subevent ? 'subevent' : 'mainevent',
      } || {},
  });

  const headerActions: ISectionHeaderAction[] = [];

  if (editModeEnabled) {
    headerActions.push({
      type: 'save',
      handler: form.onSubmit,
      disabled: !form.valid,
    });

    headerActions.push({
      type: 'cancel',
      handler: () => {
        setEditModeEnabled(false);
        form.resetFields();
      },
    });
  } else {
    headerActions.push({
      type: 'edit',
      handler: () => {
        setIsSortModeEnabled(false);
        if (!editableTabs.includes(activeTab)) {
          setActiveTab(editableTabs[0], true);
          setEditModeEnabled(true, false);
          return;
        }
        setEditModeEnabled(true);
      },
    });
  }

  const headerMenu = useCallback((): JSX.Element => {
    return (
      <ActionsMenu
        component={EventActionsList}
        items={[event]}
        options={{ context: 'single', page: Pages.DETAILS, onSuccess }}
      />
    );
  }, [event, onSuccess]);

  const customLeftRenderer = (): JSX.Element => {
    if (!name) {
      return <></>;
    }
    return (
      <div className="section-header__left my-0 d-flex flex-column">
        {Boolean(parentEvent) && <EventAncestryBreadcrumbs parentEvent={parentEvent} />}
        <div className="mt-1 d-flex flex-column">
          <h3 className="section-header__title">{name}</h3>
          <div
            className={Classes.TEXT_MUTED}
          >{`${pm_statistics_data?.count} Products | ${attendeesCount} Attendees`}</div>
        </div>
      </div>
    );
  };

  const handleAccessLevelChange = useCallback(
    async (newAccessLevel: string) => {
      return await changeAccessLevelSlider<IMarketingEvent>({
        apiCall: (data) => changeGroupAccessImmediate({ ...data, item_ids: [eventId || 0] }, EventDetailsSchema),
        divisionId: owner?.division_id,
        newAccessLevel,
        entitySetter: setEvent,
      });
    },
    [eventId, owner?.division_id, setEvent],
  );
  return (
    <div className="d-flex flex-column h-100 w-100">
      <div className="d-flex align-items-center justify-content-between">
        <DashboardBreadcrumbs className="mb-3" />
      </div>
      {loading && <Loading text="Loading event" />}
      {!loading && event && (
        <>
          <SectionHeader
            customLeftRenderer={customLeftRenderer}
            useBackground={true}
            actions={headerActions}
            dropdownMenu={headerMenu()}
            accessLevel={access_level}
            onAccessLevelChange={handleAccessLevelChange}
          />

          <div className="event-details__content-wrapper flex-full">
            <div className="event-details__tabs-wrapper h-100">
              <EventDetailsTabsView
                event={event}
                editModeEnabled={editModeEnabled}
                form={form}
                assets={assets}
                currentTabId={activeTab}
                onTabChangeHandle={onTabChangeHandle}
                onSuccess={onSuccess}
                setEvent={setEvent}
                isSortModeEnabled={isSortModeEnabled}
                setIsSortModeEnabled={setIsSortModeEnabled}
              />
            </div>
          </div>
        </>
      )}
      {!loading && !event && (
        <SectionMessage intent={SectionMessageType.EMPTY} title="Uh-Oh!">
          It appears that this page is empty.
        </SectionMessage>
      )}
    </div>
  );
});

export default EventDetails;
