import { ItemId, ICategory } from 'types';
import { categoryListSchema } from 'utils/schemas';
import { chipmunk } from 'utils/chipmunk';
import { Model } from 'helpers/filters/types';

export const queryCategories = (
  params: { ids?: ItemId[] | null; q?: string },
  schema = categoryListSchema,
): Promise<ICategory[]> => {
  return chipmunk.run(async (chipmunk) => {
    const { objects } = await chipmunk.action(Model.CATEGORIES, 'query', {
      params,
      schema,
    });

    return objects;
  });
};
