import { IChipmunk, IResult } from '@mediafellows/chipmunk';
import { chunk, map, isEmpty, groupBy } from 'lodash';

import {
  IDeepPartial,
  IExtendedSearchFilter,
  IFetchProductsWithAncestry,
  IProduct,
  IProductAncestryInfo,
  IProductAsset,
  IProductLayer,
  IQueryParams,
  ISearchFilter,
  ItemId,
  IWithRequired,
  Product3Types,
} from 'types';
import { chipmunk } from 'utils/chipmunk';
import { parseToSearchParams } from 'utils/general';
import { handleLongIdsParam } from 'utils/payload';
import { productListSchema } from 'utils/schemas';
import { autosuggestValues } from 'utils/autosuggest-values';
import { Model, ParametronOrder, ParametronSort } from 'helpers/filters/types';
import { queryClassificationAssetsOfProducts, queryAssetsOfProducts } from 'utils/apis/product-asset';
import { getAllowedParentSearchTypes, mapProductToAncestryInfo } from 'utils/product';
import { IAssetTreeOption } from 'helpers/form/fields/form-select';
import { IItemId } from 'components/form-selection-items';
import { IPagination } from '@mediafellows/chipmunk/dist/src/action';
import { IFilterOption } from 'utils/hooks';
interface IProductStatistics {
  products_accessible: number;
  products_company: number;
  products_count: number;
  products_private: number;
  products_viewable: number;
}

/**
 * @todo delete parent_id only and keep parent_ids to allow assets assigned to more than one product
 */
export const queryProductsWithClassificationAssets = async (
  products: IProduct[],
  opts: { marketingUseOnly: boolean; classification?: string; assetFilters?: ISearchFilter[] },
): Promise<IAssetTreeOption[]> => {
  const videoAssets = await queryClassificationAssetsOfProducts(
    map(products, 'id'),
    opts?.marketingUseOnly,
    true,
    opts?.classification,
    opts?.assetFilters,
  );

  const productsThatHaveAssets = {};
  const formattedAssets = videoAssets.flat().map((asset) => {
    asset?.product_ids?.map((id) => {
      productsThatHaveAssets[id] = true;
    });

    return {
      id: String(asset.id),
      name: asset?.name || '',
      parent_id: asset?.product_id,
      parent_ids: asset?.product_ids,
      classification: asset?.classification,
      asset,
    };
  });

  return products
    .reduce(
      (acc, { id, full_title, title }) =>
        productsThatHaveAssets[id] ? [...acc, { id: String(id), name: full_title || title }] : acc,
      [],
    )
    .concat(formattedAssets);
};

/**
 * @todo delete parent_id only and keep parent_ids to allow assets assigned to more than one product
 */
export const queryProductsWithAssets = async (
  products: IProduct[],
  marketingUseOnly: boolean,
): Promise<IAssetTreeOption[]> => {
  const assets = await queryAssetsOfProducts(map(products, 'id'), marketingUseOnly);

  const productsThatHaveAssets = {};
  const formattedAssets = assets.flat().map((asset) => {
    asset?.product_ids?.map((id) => {
      productsThatHaveAssets[id] = true;
    });

    return {
      id: String(asset.id),
      name: asset?.name || '',
      parent_id: asset?.product_id,
      parent_ids: asset?.product_ids,
      classification: asset?.classification,
      asset,
    };
  });

  return products
    .reduce(
      (acc, { id, full_title, title }) =>
        productsThatHaveAssets[id] ? [...acc, { id: String(id), name: full_title || title }] : acc,
      [],
    )
    .concat(formattedAssets);
};

export function getProductsByAssetId(
  asset_id?: ItemId | null,
  schema: string | null = productListSchema,
): Promise<IProduct[]> {
  return chipmunk.run(async ({ action }: IChipmunk) => {
    if (!asset_id) {
      return [];
    }
    const { objects } = await action(Model.PRODUCTS, 'search', {
      body: {
        search: {
          filters: [['asset_ids', 'eq', asset_id]],
        },
      },
      schema: schema || undefined,
    });

    return objects;
  });
}

export const queryProductsByBasketId = (
  basketId: string,
  params: IQueryParams = { sort: ParametronSort.SEQUENCE_NUMBER, order: ParametronOrder.ASCENDING },
  schema = productListSchema,
): Promise<IProduct[]> => {
  return chipmunk.run(async (chipmunk) => {
    const { objects } = await chipmunk.unfurl<IProduct>(Model.PRODUCTS, 'search_basket', {
      params: {
        ...params,
        basket_id: basketId,
      },
      schema,
    });
    const ancestryInfos = await getProductsAncestryInfo(objects.map((e) => e.id));

    return mapProductToAncestryInfo(objects, ancestryInfos);
  });
};

export const updateProductSequenceNumberInBasket = (
  basketId: string,
  products: {
    id: IItemId;
    sequence_number: number;
  },
): Promise<IProduct[]> => {
  return chipmunk.run(async (ch) => {
    const obj = (
      await ch.action('pm.basket', 'member.basket_products_bulk_update', {
        params: { basket_id: basketId },
        body: { products },
      })
    ).objects;
    return obj;
  });
};

export const queryProducts = (
  params: IQueryParams & { search?: { filters: IExtendedSearchFilter[] } },
  additionalFilters: ISearchFilter[] = [],
  parseToSearch = parseToSearchParams,
  actionName: 'action' | 'unfurl' = 'action',
): Promise<IProduct[]> => searchProducts(parseToSearch(params, additionalFilters), productListSchema, actionName);

export const queryAllProducts = (
  params: IQueryParams | { search: { filters: IExtendedSearchFilter[] } },
  additionalFilters: ISearchFilter[] = [],
): Promise<IProduct[]> =>
  searchProducts(
    parseToSearchParams({ ...params, per: 200 } as IQueryParams, additionalFilters),
    productListSchema,
    'unfurl',
  );

export const searchProducts = (
  body: IQueryParams & { search?: { filters: IExtendedSearchFilter[] } },
  schema = productListSchema,
  actionName: 'action' | 'unfurl' = 'action',
  callback?: (products) => Promise<IProduct[]>,
): Promise<IProduct[]> => {
  return chipmunk.run(async (chipmunk) => {
    const sortParams = body?.sorting || body?.sort ? {} : { sorting: { _score: 'desc', descendants_count: 'desc' } };
    const { objects } = await chipmunk[actionName](Model.PRODUCTS, 'search', {
      body: { per: 25, ...sortParams, ...body },
      schema,
    });
    return callback && objects?.length ? callback(objects) : objects;
  });
};

export const fetchGroupProduct = async <T extends true | false | undefined | void = void>(
  groupId?: ItemId | ItemId[],
  params?: IQueryParams,
  opts: { stats?: string; rawResult?: T } = {},
): Promise<T extends true ? IResult<IProduct> : IProduct[]> => {
  const groupIds = Array.isArray(groupId) ? groupId : [groupId];
  return chipmunk.run(async ({ action }) => {
    const result = await action<IProduct>(Model.PRODUCTS, 'search', {
      params,
      body: {
        stats: opts.stats,
        search: {
          filters: [['group_ids', 'in', groupIds]],
        },
      },
    });

    return opts?.rawResult ? result : result.objects;
  });
};

export const getProduct = async (product_id: ItemId, schema = productListSchema): Promise<IProduct> => {
  const [product] = await getProducts({ product_ids: product_id }, schema);
  return product;
};

export const getProducts = (
  params: { product_ids: ItemId | ItemId[] },
  schema = productListSchema,
): Promise<IProduct[]> =>
  chipmunk.run(async ({ action }) => {
    let ids = params.product_ids;
    if (!ids || (Array.isArray(ids) && !ids.length)) {
      return [];
    }
    if (!Array.isArray(ids)) {
      ids = [ids];
    }

    async function getBatchOfProducts(product_ids: ItemId[]): Promise<IProduct[]> {
      const { objects } = await action<IProduct>(Model.PRODUCTS, 'get', {
        params: { product_ids },
        schema,
      });

      return objects;
    }

    return handleLongIdsParam(getBatchOfProducts, ids);
  });

export const getProductsWithAncestryInfo = async (
  params: { product_ids: ItemId | ItemId[] },
  schema = productListSchema,
): Promise<IProduct[]> => {
  const productsPromise = getProducts(params, schema);
  const ancestryInfosPromise = getProductsAncestryInfo(params.product_ids);
  const [products, ancestryInfos] = await Promise.all([productsPromise, ancestryInfosPromise]);

  return mapProductToAncestryInfo(products, ancestryInfos);
};
export const queryAllLevelProducts = (params: IQueryParams): Promise<IProduct[]> =>
  chipmunk.run(async ({ action }) => {
    const { objects } = await action(Model.PRODUCTS, 'search', {
      body: {
        only_roots: 'false',
        ...parseToSearchParams(params),
      },
      schema: productListSchema,
    });

    return objects;
  });

export const createProduct = ({ product, layer }: { product: IProduct; layer: IProductLayer }): Promise<IProduct> => {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.PRODUCTS, 'create', {
      body: product,
    });

    let default_layer;
    if (object?.id)
      default_layer = (
        await action(Model.PRODUCT_STANDARD_LAYER, 'create', {
          params: { product_ids: object.id },
          body: { ...layer, product_id: object.id },
        })
      ).object;

    return { ...object, default_layer };
  });
};

export const fetchProductsAncestry = async (product_ids: ItemId | ItemId[]): Promise<IProductAncestryInfo[]> => {
  return chipmunk.run(async ({ action }) => {
    if (!product_ids || (Array.isArray(product_ids) && !product_ids?.length)) {
      return [];
    }
    const chunks = chunk(Array.isArray(product_ids) ? product_ids : [product_ids], 80);
    const promises = chunks.map(async (ids) => {
      const { objects } = await action<IProductAncestryInfo>(Model.PRODUCT_ANCESTRY_INFO, 'get', {
        params: { product_ids: ids },
      });
      return objects;
    });
    const result = await Promise.all(promises);

    return result.flat();
  });
};

export const fetchProductsDescendants = async (
  product_ids: ItemId[],
  action: 'action' | 'unfurl' = 'action',
): Promise<IProduct[]> => {
  return chipmunk.run(async (cp) => {
    if (isEmpty(product_ids)) {
      return [];
    }
    const { objects } = await cp[action](Model.PRODUCTS, 'search', {
      body: {
        search: {
          filters: [['ancestor_ids', 'in', product_ids]],
        },
      },
    });

    return objects;
  });
};

export const fetchAllProductsDescendants = async (product_ids: ItemId[]): Promise<IProduct[]> => {
  return fetchProductsDescendants(product_ids, 'unfurl');
};

export const updateProduct = async (product: IDeepPartial<IProduct>, schema?: string): Promise<IProduct> => {
  const [object] = await updateProducts([product], schema);
  return object;
};

export const updateProducts = async (products: IDeepPartial<IProduct>[], schema?: string): Promise<IProduct[]> => {
  return chipmunk.run(async ({ action }) => {
    const { objects } = await action(Model.PRODUCTS, 'update', {
      params: { product_ids: products.map((e) => e.id) },
      body: products,
      schema,
    });

    return objects;
  });
};

export const updateProductLayer = async (
  layer: IWithRequired<IDeepPartial<IProductLayer>, 'id' | 'product_id'>,
  schema?: string,
): Promise<IProductLayer> => {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action<IProductLayer>(Model.PRODUCT_STANDARD_LAYER, 'update', {
      params: { product_ids: layer.product_id, layer_ids: layer.id },
      body: layer,
      schema,
      proxy: false,
    });

    return object;
  });
};

export const getProductStatistics = (): Promise<IProductStatistics> => {
  return chipmunk.run(async (chipmunk) => {
    const { objects } = await chipmunk.action(Model.PRODUCT_STATISTICS_DATA, 'product.get');

    return objects;
  });
};

export const getProductGroupIds = async (product_ids: ItemId | ItemId[]): Promise<number[]> => {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.PRODUCTS, 'group_ids', {
      params: {
        product_ids,
      },
    });

    return object.group_ids;
  });
};

export const getProductsAncestryInfo = (productIds: ItemId | ItemId[]): Promise<IProductAncestryInfo[]> => {
  return fetchProductsAncestry(productIds);
};

const getSuggestions = async ({ ids, q }: IQueryParams, property: string): Promise<string[]> => {
  if (ids) {
    return ids as string[];
  }
  return autosuggestValues(Model.PRODUCTS, property, q as string);
};

export const createFieldSuggestion = (name: string) => {
  return (params: IQueryParams) => getSuggestions(params, name);
};

export const fetchProductsWithAncestry: IFetchProductsWithAncestry = (
  { ids, q },
  additionalFilters = [['parent_id', 'not_exist']],
) => {
  if (ids?.length) {
    return getProducts({ product_ids: ids });
  }
  const fetchProductAssets = async (product_ids: ItemId[]): Promise<IProductAsset[]> => {
    const { objects: assets } = await chipmunk.unfurl<IProductAsset>(Model.PRODUCT_ASSET, 'query', {
      params: { product_ids },
    });
    return assets;
  };

  const callback = async (objects: IProduct[]): Promise<IProduct[]> => {
    const product_ids = objects?.map((item) => item.id) || [];
    const assets = await handleLongIdsParam(fetchProductAssets, product_ids);
    const assetsByProductId = groupBy(assets, 'product_id');

    objects?.map((product) => {
      product.assets = assetsByProductId[product.id] || [];
    });

    const { objects: ancestryInfos } = await chipmunk.action(Model.PRODUCT_ANCESTRY_INFO, 'get', {
      params: { product_ids },
    });

    return mapProductToAncestryInfo(objects, ancestryInfos as IProductAncestryInfo[]);
  };

  const body = {
    ...parseToSearchParams({ q } as IQueryParams, additionalFilters),
    sorting: { _score: ParametronOrder.DESCENDING },
  };

  return searchProducts(body, productListSchema, 'action', callback);
};

export const fetchPotentialParentsByType = async (
  q: string,
  type: Product3Types,
  additionalFilters?: IFilterOption[],
): Promise<IProduct[]> => {
  if (!type) {
    return [];
  }

  const parentFilters: [string, string, string[]] = ['type', 'in', getAllowedParentSearchTypes(type)];

  const filters = [parentFilters, ...(additionalFilters || [])];

  return fetchProductsWithAncestry({ q }, filters);
};

export const getProductSequenceNumber = (filters: ISearchFilter[]): Promise<number> => {
  return chipmunk.run(async ({ action }) => {
    try {
      const result = await action(Model.PRODUCTS, 'search', {
        body: {
          sort: 'sequence_number',
          order: 'desc',
          search: {
            filters,
          },
        },
      });

      const sequenceNumber = result.object.sequence_number || 0;
      const totalItems = result.pagination?.total_count || 0;
      return Math.max(sequenceNumber, totalItems) + 1;
    } catch (error) {
      return 1;
    }
  });
};

export const getProductsCount = (
  params: IQueryParams,
  additionalFilters: ISearchFilter[] = [],
): Promise<IPagination> => {
  return chipmunk.run(async ({ action }) => {
    const result = await action(Model.PRODUCTS, 'search', {
      body: parseToSearchParams({ ...params, per: 1 }, additionalFilters),
      schema: 'id',
    });

    return result.pagination;
  });
};

export const exportProducts = (
  { per, page, ...params }: IQueryParams,
  filters: ISearchFilter[] = [],
): Promise<void> => {
  const omitParams = params;
  const body = parseToSearchParams(omitParams, filters);

  return chipmunk.run(async ({ action }) => {
    await action(Model.PRODUCTS, 'export', { body });
    return;
  });
};
