import React, { useEffect } from 'react';
import cx from 'classnames';
import { Button } from '@mantine/core';

import { EqFilter } from 'helpers/filters/types';
import { IDataProvider } from 'helpers/data-provider/option-data-provider';
import { FormGroup } from 'helpers/form/fields/form-group';

import './style.scss';

type IButtonGroupFilterDefinition = EqFilter;

interface IFilterButtonGroupProps {
  name: string;
  label: string;
  className?: string;
  disabled?: boolean;
  filter: IButtonGroupFilterDefinition;
  onChange?: (newValue: { [key: string]: IButtonGroupFilterDefinition }) => void;
  optionsProvider: IDataProvider;
}

const FilterButtonGroup: React.FC<IFilterButtonGroupProps> = ({
  name,
  label,
  filter,
  onChange,
  className,
  disabled,
  optionsProvider,
}) => {
  const { value } = filter;
  useEffect(() => {
    optionsProvider.init();
  }, [optionsProvider]);

  const options = optionsProvider.data || [];

  const handleClick = (e): void => {
    const option = e.currentTarget.getAttribute('data-option');
    const isSelected = value === option;
    onChange?.({ [name]: { ...filter, value: isSelected ? '' : option } });
  };

  return (
    <FormGroup label={label} labelFor={name} className={cx(`filter-button-group ${className}`)}>
      <Button.Group>
        {options.map(({ value: v, label }, index) => {
          return (
            <Button
              variant="default"
              size="compact-md"
              className="text-capitalize filter-button-group__button"
              data-active={value === v}
              disabled={disabled}
              onClick={handleClick}
              data-option={v}
              key={index}
            >
              {label}
            </Button>
          );
        })}
      </Button.Group>
    </FormGroup>
  );
};

export default FilterButtonGroup;
