import React, { useCallback, useMemo } from 'react';
import { Checkbox } from '@mantine/core';
import { isEmpty } from 'lodash';
import cx from 'classnames';
import { IFieldData, IFieldHandlers, IChecklistField } from 'helpers/form/types';

import { IStyled } from 'types';

export interface IMFXFormInputProps
  extends IFieldData<string[] | null, (string | IChecklistField)[]>,
    IFieldHandlers<string[]>,
    IStyled {
  name: string;
  label?: string;
  labelElement?: JSX.Element;
  large?: boolean;
  disabled?: boolean;
  formId?: string;
}

const preparePropOptions = (options?: (string | IChecklistField)[]): IChecklistField[] => {
  return options?.map((e) => (typeof e === 'object' ? e : { label: e, name: e })) || [];
};

export const FormChecklist: React.FC<IMFXFormInputProps> = ({
  formId = 'id',
  name,
  touched,
  validation,
  onChange,
  onBlur,
  options,
  value,
  label,
  className,
  required,
}) => {
  const preparedOptions = useMemo(() => preparePropOptions(options), [options]);

  const handleChange = useCallback(
    ({ currentTarget }: React.FormEvent<HTMLInputElement>): void => {
      const newValue = currentTarget.checked
        ? [...(value || []), currentTarget.name]
        : value?.filter((id) => id !== currentTarget.name);
      newValue && onChange?.({ [name]: newValue });
    },
    [value, onChange, name],
  );

  const handleBlur = useCallback((): void => {
    onBlur?.(name);
  }, [name, onBlur]);

  const getCheckboxId = useCallback((label: string): string => [formId, name, label].join('-'), [formId, name]);

  const showError = touched && !validation?.valid && (!isEmpty(value) || required);
  const helperText = showError ? validation?.errorMessage : '';

  return (
    <Checkbox.Group
      error={helperText}
      label={label ?? null}
      withAsterisk={required}
      value={value as string[]}
      className={cx('form-control-wrap', className)}
    >
      {preparedOptions?.map(({ name, label }) => (
        <Checkbox
          id={getCheckboxId(name)}
          key={name}
          name={name}
          value={name}
          label={label}
          className="check-list_menu-item"
          onChange={handleChange}
          onBlur={handleBlur}
          size="sm"
          mb="5"
        />
      ))}
    </Checkbox.Group>
  );
};

export default FormChecklist;
