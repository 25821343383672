import React, { useCallback } from 'react';

import {
  assetDividerAction,
  contactDividerAction,
  productDividerAction,
  useAssetActions,
  useContactActions,
  useProductActions,
} from 'utils/actions';
import { useSelectionActions } from 'utils/actions/selection';
import { removeDividers, getValidSelectionActions } from 'utils/actions/utils';
import { IAsset, IContact, IProduct, ISelectionStandardized } from 'types';
import { loadAssetSelectionItems, loadAllContactSelectionItems, loadProductSelectionItems } from 'utils/apis/selection';

import { IItemActionsMenuProps } from './utils';
import { useRemote } from 'utils/hooks';
import { areAllNotDeleted } from 'utils/is-entity-deleted';
import { renderMenuActionItems } from 'utils/ui';

export const SelectionListItemActionsList: React.FC<IItemActionsMenuProps<ISelectionStandardized>> = (props) => {
  const { items, options } = props;

  const [, ...actions] = useSelectionActions(items, options);

  return <>{renderMenuActionItems(getValidSelectionActions(actions))}</>;
};

export const AssetSelectionActionsList: React.FC<IItemActionsMenuProps<ISelectionStandardized>> = (props) => {
  const { items, options } = props;

  const selectionActions = useSelectionActions(items, options);

  const fetchSelectionItems = useCallback(async (): Promise<IAsset[]> => {
    return areAllNotDeleted(items) ? loadAssetSelectionItems(items) : [];
  }, [items]);

  const [newSelectionItems] = useRemote<IAsset[]>(fetchSelectionItems, []);
  const assetActions = useAssetActions(newSelectionItems, { ...options, context: 'multi' });

  if (!areAllNotDeleted(items)) {
    return <>{renderMenuActionItems(selectionActions)}</>;
  }
  const actions = removeDividers([...selectionActions, assetDividerAction, ...assetActions]);

  return <>{renderMenuActionItems(actions)}</>;
};

export const ContactSelectionActionsList: React.FC<IItemActionsMenuProps<ISelectionStandardized>> = (props) => {
  const { items, options } = props;

  const fetchSelectionItems = useCallback(async (): Promise<IContact[]> => {
    return areAllNotDeleted(items) ? loadAllContactSelectionItems(items) : [];
  }, [items]);

  const [contacts] = useRemote<IContact[]>(fetchSelectionItems, []);

  const contactActions = useContactActions(contacts || [], { ...options, context: 'multi' });

  const selectionActions = useSelectionActions(items, options);

  if (!areAllNotDeleted(items)) {
    return <>{renderMenuActionItems(selectionActions)}</>;
  }

  const actions = removeDividers([...selectionActions, contactDividerAction, ...contactActions]);

  return <>{renderMenuActionItems(actions)}</>;
};

export const ProductSelectionActionsList: React.FC<IItemActionsMenuProps<ISelectionStandardized>> = (props) => {
  const { items, options } = props;

  const fetchSelectionItems = useCallback(async (): Promise<IProduct[]> => {
    return areAllNotDeleted(items) ? loadProductSelectionItems(items) : [];
  }, [items]);

  const [newSelectionItems] = useRemote<IProduct[]>(fetchSelectionItems, []);

  const selectionActions = useSelectionActions(items, options);
  const productActions = useProductActions(newSelectionItems || [], { ...options, context: 'multi' });

  if (!areAllNotDeleted(items)) {
    return <>{renderMenuActionItems(selectionActions)}</>;
  }

  const actions = removeDividers([...selectionActions, productDividerAction, ...productActions]);

  return <>{renderMenuActionItems(actions)}</>;
};
