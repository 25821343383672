import React from 'react';

import { IAsset, IMm3Asset } from 'types';
import { formatDuration } from 'utils/date';
import { InfoBoxV2 } from 'components/info-box-v2';
import { LabelValuePair as Row } from 'components/label-value-pair';
import { AssetFilters, withAssetVersion } from 'utils/asset';
import {
  FormInput,
  FormHtmlInput,
  FormSelect,
  useFetchFieldOptions,
  FormContacts,
  FormOrganization,
} from 'helpers/form';
import { CustomAssetMetadataEditRow } from 'pages/asset-details/asset-metadata-tab/custom-row';

import { IAssetMetadataEditTabProps } from './types';
import { useBasicStore } from 'store/hooks';
import { getDivisions } from 'utils/apis/divisions';
import { getEntityType } from 'utils/general';

export const AssetOldMetadataAboutEditSection: React.FC<IAssetMetadataEditTabProps<IAsset>> = ({ asset, form }) => {
  const { formData, handlers } = form;
  const { languagesDataOptions } = useBasicStore();
  const divisionOptions = useFetchFieldOptions(getDivisions);

  const { duration, classification } = asset || {};
  const assetItemType = getEntityType(classification);
  const isVideo: boolean = assetItemType === AssetFilters.VIDEO;
  const isAudio: boolean = assetItemType === AssetFilters.AUDIO;
  return (
    <InfoBoxV2 gridTemplateColumns="120px 1fr" title="About">
      <Row
        label="Name"
        showAsterisk
        value={
          <FormInput
            name="default_layer.name"
            placeholder="Type Name"
            large
            {...formData['default_layer.name']}
            {...handlers}
          />
        }
        size="m"
      />
      <Row
        label="Asset Type"
        showAsterisk
        value={
          <FormSelect
            name="classification"
            placeholder="Select Asset Type"
            {...formData.classification}
            {...handlers}
            large
          />
        }
        size="m"
      />

      <CustomAssetMetadataEditRow formData={formData} handlers={handlers} />
      <Row
        label="owner"
        value={
          <FormContacts
            label=""
            name="owner_id"
            {...formData.owner_id}
            {...handlers}
            placeholder="Select Owner"
            large
          />
        }
        size="m"
      />
      <Row
        label="organization"
        value={<FormOrganization label="" name="owner_organization_id" formData={formData} handlers={handlers} />}
        size="m"
      />
      <Row
        label="division"
        value={
          <FormSelect
            name="owner_division_id"
            large
            placeholder="Search Division"
            {...formData.owner_division_id}
            {...handlers}
            options={divisionOptions}
          />
        }
        hidden={!divisionOptions?.length}
        size="m"
      />

      <Row label="Duration" value={formatDuration(duration, 'seconds')} size="m" hidden={!(isVideo || isAudio)} />
      <Row
        label="Notes"
        value={
          <FormHtmlInput label="" name="default_layer.notes" large {...handlers} {...formData['default_layer.notes']} />
        }
      />
      <Row
        rawLabel
        label="Burned-in Subtitles"
        hidden={!isVideo}
        value={
          <FormSelect
            placeholder="Select Burned-in Subtitles"
            name="burned_in_subtitles_language_id"
            {...formData.burned_in_subtitles_language_id}
            {...handlers}
            options={languagesDataOptions}
          />
        }
      />
    </InfoBoxV2>
  );
};

export const AssetMm3MetadataAboutEditSection: React.FC<IAssetMetadataEditTabProps<IMm3Asset>> = ({ asset, form }) => {
  const { formData, handlers } = form;
  const divisionOptions = useFetchFieldOptions(getDivisions);

  const { classification, meta } = asset || {};
  const { duration } = meta || {};

  const assetItemType = getEntityType(classification);
  const isVideo: boolean = assetItemType === AssetFilters.VIDEO;

  const isAudio: boolean = assetItemType === AssetFilters.AUDIO;

  return (
    <InfoBoxV2 gridTemplateColumns="120px 1fr" title="About">
      <Row
        label="Name"
        showAsterisk
        value={<FormInput name="name" placeholder="Type Name" {...formData.name} {...handlers} />}
        size="m"
      />
      <Row
        label="Asset Type"
        showAsterisk
        value={
          <FormSelect
            name="classification"
            placeholder="Select Asset Type"
            {...formData.classification}
            {...handlers}
            large
          />
        }
        size="m"
      />

      <CustomAssetMetadataEditRow formData={formData} handlers={handlers} />
      <Row
        label="owner"
        value={
          <FormContacts
            label=""
            name="owner_id"
            {...formData.owner_id}
            {...handlers}
            placeholder="Select Owner"
            large
          />
        }
        size="m"
      />
      <Row
        label="organization"
        value={<FormOrganization label="" name="owner_organization_id" formData={formData} handlers={handlers} />}
        size="m"
      />
      <Row
        label="division"
        hidden={!divisionOptions?.length}
        value={
          <FormSelect
            name="owner_division_id"
            large
            placeholder="Search Division"
            {...formData.owner_division_id}
            {...handlers}
            options={divisionOptions}
            emptyValueIsUndefined
          />
        }
        size="m"
      />
      <Row label="Duration" value={formatDuration(duration, 'seconds')} size="m" hidden={!(isVideo || isAudio)} />
      <Row
        label="Notes"
        value={<FormHtmlInput label="" name="meta.notes" large {...handlers} {...formData.meta?.notes} />}
      />
      {/* <Row
        rawLabel
        label="Burned-in Subtitles"
        hidden={!isVideo}
        value={
          <FormSelect
            placeholder="Select Burned-in Subtitles"
            name="burned_in_subtitles_language_id"
            {...formData.burned_in_subtitles_language_id}
            {...handlers}
            options={languagesDataOptions}
          />
        }
      /> */}
    </InfoBoxV2>
  );
};

export const AssetMetadataAboutEditSection = withAssetVersion(
  AssetOldMetadataAboutEditSection,
  AssetMm3MetadataAboutEditSection,
);
