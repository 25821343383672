import { logger } from 'utils/logger';
import { chipmunk } from 'utils/chipmunk';
import { Model } from 'helpers/filters/types';

export const invalidateSession = async (): Promise<void> => {
  try {
    await chipmunk.action(Model.SESSION, 'member.delete', {});
  } catch (error) {
    logger.error(error.text);
  }
};
