import { getRootStore } from 'store';
import { ICategory } from 'types';

import { formatField, byId } from './general';

export function formatCategories(ids: number[], categories: ICategory[]): string {
  if (!ids?.length) {
    return '';
  }

  const categoriesById = byId(categories);

  const categoriesFullNames = ids.map((id) => formatCategory(id, categoriesById));

  return formatField(categoriesFullNames) as string;
}

export const getFullCategoryNames = (
  id: undefined | number,
  categoriesById: { [key: number]: ICategory },
): string[] => {
  if (!id || !categoriesById[id]) {
    return [];
  }

  return [...getFullCategoryNames(categoriesById[id]?.parent_id, categoriesById), categoriesById[id].name];
};

export const formatCategory = (id: number, categoriesById: { [key: number]: ICategory }): string => {
  const field = getFullCategoryNames(id, categoriesById);

  return formatField(field, '', ' > ') as string;
};

export const formatCategoryLabel = (id: number): string => {
  const {
    basicStore: { categories },
  } = getRootStore();

  const categoriesById = byId<ICategory>(categories);
  return formatCategory(id, categoriesById);
};
