import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { PmItvstudiosLayer3MotionPictureStandard, PmItvstudiosProduct3 } from '@mediafellows/mm3-types';

import { LabelValuePair as Row, yn } from 'components/label-value-pair';
import { InfoBoxV2 } from 'components/info-box-v2';
import ScrollWrapper from 'components/scroll-wrapper';

import { formatDate } from 'utils/date';
import { fetchProducerCompanies } from 'utils/apis/organization';
import { formatField } from 'utils/general';
import { CategoriesInfo } from 'utils/ui';
import { useLanguages, useRemote } from 'utils/hooks';

import 'pages/product-details/product-metadata-tab/style.scss';
import './style-itv.scss';
import { useTerritories, useTerritoriesCount } from 'utils/hooks/territory';
import { Product3Types } from 'types';

interface IProductMetadataTabPreviewProps {
  product?: PmItvstudiosProduct3;
}

const dateFormat = 'dd MMMM yyyy';

const ProductMetadataTabPreview: React.FC<IProductMetadataTabPreviewProps> = observer(({ product }) => {
  const { default_layer, external_reference, meta, type } = product || {};
  const { slug = '' } = meta || {};

  const defaultLayerMeta = (default_layer as PmItvstudiosLayer3MotionPictureStandard)?.meta || {};

  const languageInfo = useLanguages(defaultLayerMeta.language_ids);
  const territoryInfo = useTerritories(defaultLayerMeta.territories_sold_in);
  const territoriesCount = useTerritoriesCount(defaultLayerMeta.territories_sold_in);
  const {
    duration,
    duration_text,
    tx_date,
    original_release_date,
    tx_date_text,
    co_producers,
    commissioning_broadcasters,
    produced_by_external,
    keywords,
    bw_content,
  } = defaultLayerMeta;
  const getProducerCompanies = useCallback(async () => {
    const ids = defaultLayerMeta.producer_organization_ids;
    if (!ids?.length) {
      return [];
    }
    return fetchProducerCompanies({ ids });
  }, [defaultLayerMeta.producer_organization_ids]);

  const [producerCompanies] = useRemote(getProducerCompanies);

  return (
    <div className="product-metadata-tab h-100">
      <ScrollWrapper>
        <InfoBoxV2 wrapperClassName="py-3" className="entity-detail__infobox-wrapper" padded={false}>
          <div>
            <InfoBoxV2
              gridTemplateColumns="1fr 5fr"
              wrapperClassName="pb-3"
              title="About"
              className="text-no-transform "
            >
              <Row label="Title" value={default_layer?.title} />
              <Row label="Logline" value={defaultLayerMeta.logline} html className="text-no-transform" />
              <Row label="Synopsis" value={defaultLayerMeta.synopsis} html className="text-no-transform" />
              <Row label="Genres" value={<CategoriesInfo category_ids={defaultLayerMeta.category_ids} expanded />} />
              <Row label="Slug" rawValue value={formatField(slug)} />
              <Row
                hideIfNoValue
                label="Number of Seasons (Manual)"
                value={formatField(defaultLayerMeta.number_of_seasons_manual)}
              />
              <Row label="Duration (Minutes)" rawLabel value={duration} />
              <Row label="Duration (Text)" rawLabel value={duration_text} />
              <Row label="Original Release Date" rawLabel value={formatDate(original_release_date, 'dd MMM yyyy')} />
              <Row label="TX Date" value={formatDate(tx_date, 'dd MMM yyyy')} />
              <Row label="TX Date (Text)" rawLabel value={tx_date_text} />
            </InfoBoxV2>
            <InfoBoxV2
              gridTemplateColumns="1fr 5fr"
              wrapperClassName="pb-3"
              title="Fabric"
              className="text-no-transform "
            >
              <Row label="Release Year" value={formatDate(defaultLayerMeta.release_year, 'dd MMM yyyy')} />
              <Row label="Sales Synopsis" value={defaultLayerMeta.sales_synopsis} html className="text-no-transform" />
              <Row label="Press Reviews" value={defaultLayerMeta.press_reviews} />
              <Row label="Press Quotes" value={defaultLayerMeta.press_quotes} />
              <Row label="Awards" rawValue value={defaultLayerMeta.awards} />
              <Row hideIfNoValue label="Ratings" value={defaultLayerMeta.ratings} />
              <Row label="Cast & Crew " rawLabel value={defaultLayerMeta.cast_and_crew} />
              <Row label="Aspect Ratio" rawLabel value={defaultLayerMeta.aspect_ratio} />
              <Row label="Fabric ID" value={meta?.fabric_id} />
            </InfoBoxV2>
          </div>

          <div>
            <InfoBoxV2 gridTemplateColumns="2fr 6fr" wrapperClassName="pb-3" title="Production">
              <Row
                label="Produced by (ITV label)"
                rawLabel
                value={formatField(producerCompanies?.map((e) => e.name))}
              />
              <Row label="Produced by (External)" rawLabel value={formatField(produced_by_external)} />
              <Row label="Commissioning Broadcaster" value={formatField(commissioning_broadcasters)} />
              <Row label="Co-production Partner(s)" rawLabel value={formatField(co_producers)} />
              {type === Product3Types.PROGRAM && (
                <Row hideIfNoValue label="Black & White" rawLabel value={yn(bw_content)} />
              )}
              <Row hideIfNoValue rawLabel label="BBFC" value={formatField(defaultLayerMeta.bbfc)} />
              <Row label="Resolutions" value={formatField(defaultLayerMeta.resolutions)} />
              <Row label="Production Status" value={formatField(defaultLayerMeta.product_state)} />
              <Row label="Year of Production" rawLabel value={formatField(defaultLayerMeta.year_of_production)} />
              <Row label="Production Start" value={formatDate(defaultLayerMeta.production_start, dateFormat)} />
              <Row label="Production End" value={formatDate(defaultLayerMeta.production_end, dateFormat)} />
              <Row label="Due Delivery Date" value={formatDate(defaultLayerMeta.due_delivery_date, dateFormat)} />
              <Row label="Languages" value={languageInfo} />
              <Row label="CCID" value={formatField(meta?.ccid)} rawValue />
              <Row label="ParisID" value={formatField(meta?.paris_id)} rawValue />
            </InfoBoxV2>

            <InfoBoxV2 wrapperClassName="py-3" gridTemplateColumns="2fr 6fr" title="Distribution">
              <Row label="Territory Count" value={territoriesCount} />
              <Row label="Territories Sold In" value={territoryInfo} />
            </InfoBoxV2>
            <InfoBoxV2 wrapperClassName="pt-3" gridTemplateColumns="2fr 6fr" title="OTHERS">
              <Row label="External Reference" value={formatField(external_reference)} />
              <Row label="Share Link" value={formatField(defaultLayerMeta.share_link)} rawValue />
            </InfoBoxV2>
            <InfoBoxV2 wrapperClassName="pt-3" gridTemplateColumns="2fr 6fr" title="SEARCH">
              <Row label="Keywords" value={formatField(keywords)} />
            </InfoBoxV2>
          </div>
        </InfoBoxV2>
      </ScrollWrapper>
    </div>
  );
});

export default ProductMetadataTabPreview;
