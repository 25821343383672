import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';

import { FormSelect, FormRemoteSelect, FormCheckbox } from 'helpers/form';
import { EventInput } from '@fullcalendar/react';

import { FormInvitees } from './form-invitees';
import { FormHosts } from './form-hosts';
import { FormParticipants } from './form-participants';
import { IMeetingPropsFilters } from './calendar';
import FilterPresetSelector from './filter-preset-selector';
import { useSessionStore } from 'store/session-store';
import { getTimezones } from 'utils/apis/meeting';
import { queryLocation } from 'utils/apis/location';

import './style.scss';

interface IMeetingFiltersProps {
  timezone: string;
  filters: IMeetingPropsFilters;
  setFilters: React.Dispatch<React.SetStateAction<IMeetingPropsFilters>>;
  setTimezone: (tz: string) => void;
  events?: EventInput[];
}

const MeetingFilters: React.FC<React.PropsWithChildren<IMeetingFiltersProps>> = ({
  timezone,
  setFilters,
  setTimezone,
  filters,
  events,
}: IMeetingFiltersProps) => {
  const [timezones, setTimezones] = useState<string[]>([]);
  const user = useSessionStore((state) => state.user);

  useEffect(() => {
    async function fetchData(): Promise<void> {
      if (isEmpty(timezones)) {
        const timezones = await getTimezones();
        setTimezones(['local', ...timezones]);
      }
    }
    fetchData();
  }, [timezones]);

  const onLocationChange = async ({ location_id }: Partial<IMeetingPropsFilters>): Promise<void> => {
    setFilters((filters) => ({
      ...filters,
      location_id,
    }));
  };

  const onOnlyMeSwitch = ({ only_me }: Partial<IMeetingPropsFilters>): void => {
    if (user?.id) {
      setFilters((oldFilters) => ({
        ...oldFilters,
        only_me,
        participant_ids: only_me ? user.id : null,
        host_id: null,
        invites_id: null,
      }));
    }
  };

  const onParticipantChange = ({ participant_ids }: Partial<IMeetingPropsFilters>): void => {
    setFilters((filters) => ({
      ...filters,
      participant_ids,
      host_id: null,
      invites_id: null,
      only_me: participant_ids === user?.id,
    }));
  };

  const onChange = (val: Partial<IMeetingPropsFilters>): void => {
    setFilters((oldFilters) => ({
      ...oldFilters,
      ...val,
      participant_ids: null,
      only_me: false,
    }));
  };
  const inviteIds = events?.map((e) => e.invites.map((i) => i.user_id)).flat();

  return (
    <div className="meeting-filters">
      <div className="meeting-filters__left gap-3">
        <FormSelect
          label="Timezone"
          placeholder="Select Timezone"
          name="timezone"
          value={timezone}
          options={timezones}
          onChange={(value) => setTimezone(String(value['timezone']))}
          size="xs"
        />
        <FormRemoteSelect
          label="Location"
          placeholder="Select Location"
          name="location_id"
          fetchOptions={queryLocation}
          onChange={onLocationChange}
          value={filters.location_id}
          enableClearing
        />
        <FormHosts
          label="Hosts"
          placeholder="Select Hosts"
          name="host_id"
          onChange={onChange}
          value={filters.host_id}
          enableClearing
        />

        <FormInvitees
          label="Invitees"
          placeholder="Select Invitees"
          name="invites_id"
          ids={inviteIds}
          onChange={onChange}
          value={filters.invites_id}
          enableClearing
        />

        <FormParticipants
          label="Either"
          placeholder="Select Participants"
          name="participant_ids"
          ids={inviteIds}
          onChange={onParticipantChange}
          value={filters.participant_ids}
          enableClearing
        />
        <FormCheckbox
          className="meeting-filters__checkbox"
          onChange={onOnlyMeSwitch}
          name="only_me"
          label="My meetings"
          value={Boolean(filters.only_me)}
        />
      </div>

      <FilterPresetSelector filters={filters} onChange={setFilters} />
    </div>
  );
};

export default MeetingFilters;
