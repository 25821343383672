import React from 'react';

import { FormInput } from 'helpers/form';
import { IAssetAboutSectionProps } from 'components/asset/asset-edit-form/sections/about';

export const ImageSpecificFields: React.FC<IAssetAboutSectionProps> = ({ assetType, formData, handlers }) => {
  if (!assetType?.includes('image')) return null;

  return (
    <>
      <FormInput
        name="default_layer.caption"
        label="Caption"
        type="textarea"
        {...formData['default_layer.caption']}
        {...handlers}
      />
      <FormInput
        name="default_layer.talent_in_image"
        label="Talent in Image"
        type="textarea"
        {...formData['default_layer.talent_in_image']}
        {...handlers}
        className="talent-in__image-form"
      />
      <FormInput
        name="default_layer.photographer"
        label="Photographer"
        type="textarea"
        {...formData['default_layer.photographer']}
        {...handlers}
      />
    </>
  );
};
