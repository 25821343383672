import React from 'react';
import { IProduct } from 'types/index';

export const extractKeywords = (inputString): string | null => {
  const regex = /^(?:[^.]+\.meta\.)?(\w+)\./;
  const match = inputString.match(regex);
  if (match) {
    return match[1]; // Return the captured keyword
  }
  return null;
};

export const humanizeFilter = (filter: string): string | undefined => {
  if (!filter) return undefined;

  const keyword = extractKeywords(filter);
  switch (keyword) {
    case 'full_category_ids':
      return 'Genres';
    case 'produced_by':
      return 'Produced by';
    case 'produced_by_external':
      return 'Produced by (External)';
    default:
      return keyword?.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase());
  }
};

export const normalizeSearchFilters = (filters: string): Array<string> => {
  if (!filters) return [];

  const keys: string[] = [];
  const parsedFilters = JSON.parse(filters);

  const relevantFilters = parsedFilters[0][0];

  for (const filter of relevantFilters) {
    const name = humanizeFilter(filter);
    if (name) {
      keys.push(name);
    }
  }

  return keys;
};

type IFilterOrProduct = string | IProduct[];

export const listRenderer = (items: IFilterOrProduct, type = 'filters'): React.ReactNode => {
  if (!items.length) return null;
  const isFilters = type === 'filters';

  const limitedItems = !isFilters ? (items as IProduct[]).slice(0, 10) : normalizeSearchFilters(items as string);

  return (
    <div className="products-list__popover-content">
      {limitedItems?.map((item: IProduct | string, index: number) => (
        <div className="text-nowrap text-truncate" key={index}>
          {isFilters ? item : (item as IProduct).title}
        </div>
      ))}
      {!isFilters && items.length > 10 && <div className="mt-2">{`+ ${items.length - limitedItems.length} more`}</div>}
    </div>
  );
};
