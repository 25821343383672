import React from 'react';
import { Link } from 'react-router-dom';
import { Classes } from 'utils/ui';

import { ItemId } from 'types/utility';

interface IParentBreadcrumbProps {
  title: string;
  to: string;
  id?: ItemId | null;
}

export const ParentBreadcrumbs: React.FC<IParentBreadcrumbProps> = ({ title, id, to }) => {
  if (!id) {
    return <></>;
  }

  return (
    <div className="breadcrumbs__wrapper__list">
      <ul className="breadcrumbs__items__list">
        <Link className="breadcrumbs__ancestor__list" key={id} title={title} to={to}>
          <span className={`${Classes.TEXT_MUTED} d-block text-truncate breadcrumbs__list__text`}>{title}</span>
        </Link>
      </ul>
    </div>
  );
};
