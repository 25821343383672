import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from '@mantine/core';

import { useAssetsEditStore } from 'store/hooks';
import { ModalWrapper } from 'components/dialogs';
import { SectionHeader } from 'components/section-header';
import { FormSelect, useForm } from 'helpers/form';
import { Model } from 'helpers/filters/types';
import { IAsset, IAssetPresetFields } from 'types';
import { IFormHandlers, IFormData } from 'helpers/form/types';
import { IAssetFormFields } from 'components/asset/asset-edit-form/sections';
import { editAssetPresetInitialData as initialValues } from 'components/asset/asset-edit-form/constants';

import PresetForm from './preset-form';
import { getAssetFormFields } from 'components/asset/asset-edit-form/utils';
import '../index.scss';

type IPresetOption = {
  presetName: string;
};

interface IPresetSelectorProps extends IPresetOption {
  onPresetChange: (option: IPresetOption) => void;
  presetOptions: string[];
}

interface IAssetPresetFormProps {
  formData: IFormData<IAssetFormFields>;
  handlers: IFormHandlers<IAssetFormFields>;
  values: IAssetFormFields;
}

export const AssetPresetForm: React.FC<IAssetPresetFormProps> = ({ formData, handlers, values }) => {
  return <PresetForm formId="select-preset-form" {...{ formData, handlers, values, asset: initialValues }} />;
};

const PresetSelector: React.FC<IPresetSelectorProps> = ({ presetName, onPresetChange, presetOptions }) => (
  <div className="asset-edit__select-preset-modal-header">
    <FormSelect
      label=""
      validation={{ valid: true }}
      touched={false}
      required={false}
      name="presetName"
      placeholder="Select Preset"
      value={presetName}
      onChange={onPresetChange}
      options={presetOptions}
    />
  </div>
);

export interface ISelectAssetPresetProps {
  opened: boolean;
  onClose: () => void;
  presets: Record<string, IAssetPresetFields>;
}

export const SelectAssetPreset: React.FC<ISelectAssetPresetProps> = observer(({ opened, onClose, presets }) => {
  const { applyPreset } = useAssetsEditStore();
  const [presetName, setPresetName] = useState<string>('');
  const { formData, handlers, values } = useForm<IAssetFormFields>(getAssetFormFields(initialValues), Model.ASSETS);

  const onPresetChange = useCallback(
    ({ presetName }: IPresetOption) => {
      setPresetName(presetName);
      const preset = presetName ? presets[presetName] : initialValues;

      handlers.onSetFields(getAssetFormFields(preset));
    },
    [handlers, presets],
  );

  const presetSelector = useCallback(() => {
    const presetOptions = Object.keys(presets);
    return <PresetSelector presetOptions={presetOptions} {...{ onPresetChange, presetName }} />;
  }, [presets, onPresetChange, presetName]);

  const header = useCallback(() => <SectionHeader customRightRenderer={presetSelector} />, [presetSelector]);
  const handleApplyPreset = useCallback(() => {
    applyPreset({ ...initialValues, ...values } as unknown as IAsset);
    onClose();
  }, [applyPreset, onClose, values]);

  const body = useCallback(() => {
    return (
      <>
        <div className="asset-edit__select-preset-modal-container">
          <AssetPresetForm {...{ formData, handlers, values }} />
        </div>
        <div className="asset-edit__select-preset-modal-footer">
          <Button variant="default" size="xs" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleApplyPreset} size="xs" variant="primary">
            Apply
          </Button>
        </div>
      </>
    );
  }, [values, formData, handlers, handleApplyPreset, onClose]);

  return (
    <ModalWrapper
      title="Select Preset"
      className="asset-edit__select-preset-modal"
      {...{ opened, onClose, body, header }}
    />
  );
});

export default SelectAssetPreset;
