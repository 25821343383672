import { CalGenericMeeting } from '@mediafellows/mm3-types';

import { IUseActionsOptionParam, IUseActionsResponse, useActions } from 'utils/actions';
import { useCheckInInviteesAction } from './check-in-attendees';

import { useDeleteAction } from './delete';
import { useEditAction } from './edit';
import { IMeetingActionName } from './types';

export const useMeetingActions = (
  items: CalGenericMeeting[],
  options: IUseActionsOptionParam,
): IUseActionsResponse<IMeetingActionName> => {
  const editAction = useEditAction(items, options);
  const checkInAction = useCheckInInviteesAction(items, options);
  const deleteAction = useDeleteAction(items, options);
  const actions = [editAction, checkInAction, deleteAction];

  return useActions<CalGenericMeeting, IMeetingActionName>(actions, options, items);
};
