import { Model } from 'helpers/filters/types';
import React from 'react';
import { IProductAsset, IPreviewImage, IAsset, IMm3Asset } from 'types';
import { flags } from 'utils/flags';

export enum AssetFilters {
  ALL = 'All',
  IMAGE = 'image',
  VIDEO = 'video',
  AUDIO = 'audio',
  DOCUMENT = 'document',
}

export function filterPreviewAssets(assets: IProductAsset[], assetFilter: AssetFilters): IProductAsset[] {
  return (assets || []).filter(
    ({ marketing_use, asset = {} }) =>
      marketing_use &&
      readyStatuses.has(asset?.file_status || '') &&
      (assetFilter === AssetFilters.ALL || asset.classification?.includes(assetFilter)),
  );
}

export function getPreviewImageUrl(preview_image?: IPreviewImage | null): string {
  if (!preview_image?.url && !preview_image?.source_url) {
    return '';
  }
  const { status, url = '', source_url = '' } = preview_image;

  if (status !== undefined) {
    return readyStatuses.has(status || '') ? url : source_url;
  }
  return url || source_url;
}

export const getAvailableTabs = (assets?: IProductAsset[]): { filter: string; title: string }[] => {
  const tabs = [
    { filter: 'ALL', title: 'All' },
    { filter: 'IMAGE', title: 'Images' },
    { filter: 'VIDEO', title: 'Videos' },
    { filter: 'AUDIO', title: 'Audio' },
    { filter: 'DOCUMENT', title: 'Documents' },
  ];
  return tabs.filter(
    ({ filter }) => filter === 'ALL' || filterPreviewAssets(assets || [], AssetFilters[filter]).length,
  );
};

export const readyStatuses = new Set(['ready', 'available']);
export function getIngestStatus(asset?: Pick<IAsset | IMm3Asset, 'status' | 'file_status' | 'preview_image'>): string {
  const { file_status = '', preview_image } = asset || {};

  const { status: previewImageStatus = '' } = preview_image || {};

  if (readyStatuses.has(file_status) && readyStatuses.has(previewImageStatus)) {
    return 'ready';
  }
  if (!readyStatuses.has(file_status) || !previewImageStatus) {
    return file_status || '';
  }

  return `preview_${previewImageStatus}`;
}

export const ingestStatuses = new Set(['pending', 'ingesting', 'reingesting', 'created']);
export function isAssetIngesting({
  file_status,
  preview_image,
}: Pick<IAsset | IMm3Asset, 'file_status' | 'preview_image'>): boolean {
  return Boolean(ingestStatuses.has(file_status || '') || ingestStatuses.has(preview_image?.status || ''));
}

export const refreshIngestingAssetsDelay = 3 * 60 * 1000; // 3 minutes

export function withAssetVersion<T, P>(
  OldAssetComponent: React.FC<T>,
  Mm3AssetComponent: React.FC<P>,
): React.FC<T | P> {
  const { isMm3Assets } = flags;
  if (isMm3Assets) {
    return ((props: P) => <Mm3AssetComponent isMm3Assets={isMm3Assets} {...props} />) as React.FC<T | P>;
  }
  return ((props: T) => <OldAssetComponent isMm3Assets={isMm3Assets} {...props} />) as React.FC<T | P>;
}

export function selectAssetHelper<P, Q>(oldAssetsHelper: Q, mm3AssetsHelper: P): P | Q {
  return flags.isMm3Assets ? mm3AssetsHelper : oldAssetsHelper;
}

export function getAssetModel(): Model.ASSETS | Model.MM3_ASSETS {
  return flags.isMm3Assets ? Model.MM3_ASSETS : Model.ASSETS;
}
export const getMainClassification = (asset: Partial<IAsset> | Partial<IMm3Asset>): string => {
  return (asset as IAsset)?.main_classification || asset?.classification?.split('/')[0] || '';
};

export const getAssetType = (asset: IAsset | IMm3Asset): string => {
  return asset?.['@type'] || (asset as IMm3Asset)?.type || '';
};
