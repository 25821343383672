import { Routes } from 'utils/routes';
import { SIDE_BAR_WEBSITE_SECTION } from 'utils/side-bar';

export const sideBarWebsiteRoutes = SIDE_BAR_WEBSITE_SECTION.map((section) => section.route);

const sideBarWebsiteRoutePowers = SIDE_BAR_WEBSITE_SECTION.reduce((acc, section) => {
  return { ...acc, [section.route]: ['um/list_read'] };
}, {});

export const powersByRoute = {
  [Routes.CREATE_CONTACT]: ['um/user_management'],
  [Routes.CREATE_NEW_PRODUCT]: ['pm/product_upsert'],
  [Routes.CREATE_ASSET]: ['am/asset_upload'],
  [Routes.CREATE_ORGANIZATION]: ['um/organization_management'],
  [Routes.CREATE_EVENT]: ['um/group_management'],
  [Routes.CREATE_NEW_CONFERENCE]: ['cc/conference_management'],
  [Routes.CREATE_PRODUCT_RECOMMENDATION]: ['mc/recommendation_upsert'],
  [Routes.CREATE_ASSET_RECOMMENDATION]: ['mc/asset_recommendations_enabled'],
  [Routes.CREATE_ACCESS_PRIVILEGE]: ['um/group_management'],
  [Routes.CREATE_MOBILE_APP_SYNC]: ['um/group_management'],
  [Routes.CREATE_SCREENING_ROOM]: ['um/group_management'],
  [Routes.CREATE_DELIVERY_PACKAGE]: ['deliveries/manage_delivery_packages'],
  [Routes.CONTACTS]: ['um/user_read'],
  [Routes.CONTACT_SELECTIONS]: ['um/group_read', 'um/user_read'],
  [Routes.ORGANIZATIONS]: ['um/organization_read'],
  [Routes.PRODUCTS]: ['pm/product_read'],
  [Routes.PRODUCT_SELECTIONS]: ['um/group_read', 'pm/product_read'],
  [Routes.BASKETS]: ['pm/basket_usage'],
  [Routes.CAST_CREW]: ['pm/cast_crew_read'],
  [Routes.ASSETS]: ['am/asset_read'],
  [Routes.ASSET_SELECTIONS]: ['um/group_read', 'am/asset_read'],
  [Routes.SCREENING_ROOMS]: ['um/group_read'],
  [Routes.EVENTS]: ['um/group_read'],
  [Routes.ACCESS_PRIVILEGES]: ['um/group_read'],
  [Routes.MOBILE_APP_SYNC]: ['um/group_read'],
  [Routes.CONFERENCES]: ['cc/conference_management'],
  [Routes.RECOMMENDATIONS]: ['mc/recommendation_read'],
  [Routes.LOCATIONS]: ['cal/location_read'],
  [Routes.CALENDAR]: ['cal/calendar_usage'],
  [Routes.DELIVERIES]: ['deliveries/manage_delivery_packages'],
  [Routes.AFFILIATION]: ['global/affiliation_management'],
  ...sideBarWebsiteRoutePowers,
};

export const getSidebarSectionPowers = (routes: Routes[]): string[] | undefined => {
  const powers = routes?.reduce((powers, route) => {
    return powers.concat(powersByRoute[route] || []);
  }, []);
  return !powers.length ? undefined : powers;
};
