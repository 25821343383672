import React, { useCallback, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { DateRange } from '@blueprintjs/datetime';
import { endOfDay, startOfDay } from 'date-fns';

import { defaultMaxDate, defaultMinDate, parseDate } from 'utils/date';
import { FormDateRange, IDateRangeFilterDefinition } from 'helpers/form/fields';

import './style.scss';
import { useDataSectionStore } from 'store/hooks';

interface IFilterDateRangeProps {
  name: string;
  label: string;
  minDate?: Date;
  maxDate?: Date;
  allowSingleDayRange?: boolean;
  large?: boolean;
  disabled?: boolean;

  filter: IDateRangeFilterDefinition;
  onChange?: (newValue: { [key: string]: IDateRangeFilterDefinition }) => void;
}

const FilterDateRange: React.FC<IFilterDateRangeProps> = observer((props) => {
  const { name, label, minDate = defaultMinDate, maxDate = defaultMaxDate, filter, onChange, ...restProps } = props;
  const { start, end } = filter;
  const [selectedRange, setSelectedRange] = useState<DateRange>([null, null]);
  const { setParams } = useDataSectionStore();

  useEffect(() => {
    if (!start && !end) {
      setSelectedRange([null, null]);
      return;
    }

    const selected: DateRange = [
      parseDate(new Date(start), minDate, maxDate),
      parseDate(new Date(end), minDate, maxDate),
    ];

    setSelectedRange(selected);
  }, [start, end, minDate, maxDate]);

  const handleReset = useCallback((): void => {
    setSelectedRange([null, null]);
    onChange?.({ [name]: { ...filter, start: '', end: '' } });
  }, [filter, name, onChange]);

  const handleChange = useCallback(
    (newValue: { [key: string]: DateRange }): void => {
      const startVal = newValue[name][0];
      const endVal = newValue[name][1];

      if (!startVal && !endVal) {
        handleReset();
        return;
      }

      const startOfDayTime = startVal ? startOfDay(startVal) : selectedRange[0];
      const endOfDayTime = endVal ? endOfDay(endVal) : selectedRange[1];
      setSelectedRange([startOfDayTime, endOfDayTime]);

      if (onChange && startOfDayTime && endOfDayTime) {
        onChange({
          [name]: { ...filter, start: startOfDayTime.toISOString(), end: endOfDayTime.toISOString() },
        });
      }
      setParams({ page: 1 });
    },
    [filter, handleReset, name, onChange, selectedRange, setParams],
  );

  return (
    <FormDateRange
      className="filter-date-range__input"
      value={selectedRange}
      minDate={minDate}
      maxDate={maxDate}
      onChange={handleChange}
      name={name}
      label={label}
      {...restProps}
    />
  );
});

export default FilterDateRange;
