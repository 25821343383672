import { Model } from 'helpers/filters/types';
import { chipmunk } from 'utils/chipmunk';

interface IObjects {
  id: number;
  name: string;
}

export function getDivisions(): Promise<IObjects[]> {
  return chipmunk.run(async (chipmunk) => {
    // without params chipmunk is complaining. Probably the param marked as required
    const { objects } = await chipmunk.action(Model.DIVISIONS, 'get', { params: { division_ids: [] } });
    return objects;
  });
}
