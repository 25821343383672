import { ParametronStore } from 'store/parametron-store';
import queryString from 'query-string';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import {
  EqFilter,
  ExistFilter,
  FilterActions,
  IFilter,
  IFiltersDefinition,
  InFilter,
  IPresetFilter,
  IPresetParams,
  isEqFilter,
  isInFilter,
  isMatchFilter,
  isNeFilter,
  isNotExistFilter,
  isNotInFilter,
  isNoValueFilter,
  isOneValueFilter,
  isQFilter,
  isTwoValuesFilter,
  MatchFilter,
  NeFilter,
  NotExistFilter,
  NotInFilter,
  ParametronSort,
  QFilter,
  RangeFilter,
} from './types';
import { getRootStore } from 'store';
import { router } from 'routes';
import { parseQueryParams } from 'utils/general';
import { logger } from 'utils/logger';

export const filtersAreEqual = (filter1: IFilter, filter2: IFilter): boolean => {
  if (
    filter1.attribute != filter2.attribute ||
    filter1.method != filter2.method ||
    filter1?.searchOperator !== filter2?.searchOperator
  ) {
    return false;
  }

  if (isNoValueFilter(filter1) && isNoValueFilter(filter2)) {
    return filter1.value === filter2.value;
  }

  if (isOneValueFilter(filter1) && isOneValueFilter(filter2)) {
    if (isArray(filter1.value) || isArray(filter2.value)) {
      return isEqual(filter1.value, filter2.value);
    }
    return filter1.value === filter2.value;
  }

  if (isTwoValuesFilter(filter1) && isTwoValuesFilter(filter2)) {
    return filter1.start === filter2.start && filter1.end === filter2.end;
  }

  return true;
};

export const applyParametronFilters = <T extends IFiltersDefinition>(
  parametron: ParametronStore,
  filters: T,
  defaultFilters: T,
  initFilters: T,
): boolean => {
  const currentFilters = cloneDeep(parametron.parametron.data.filters);

  // we need only params that are modified from defaultFilters
  const modifiedFilters = Object.entries(filters || {})
    .filter(([key, value]) => {
      const initFilter = initFilters?.[key] && filtersAreEqual(value, initFilters[key]);
      return initFilter || !filtersAreEqual(value, defaultFilters[key]);
    })
    .map(([, value]) => value);

  parametron.api.dropFilters();

  modifiedFilters.map((filter) => {
    if (isQFilter(filter)) {
      parametron.api.setFilter(filter.attribute, filter.method, filter.value, filter.searchOperator);
      return;
    }

    if (isMatchFilter(filter) || isEqFilter(filter) || isNeFilter(filter)) {
      parametron.api.setFilter(filter.attribute, filter.method, filter.value);
    }

    if (isInFilter(filter) || isNotInFilter(filter)) {
      parametron.api.setFilter(filter.attribute, filter.method, filter.value);
    }

    if (isNoValueFilter(filter) && filter.value) {
      parametron.api.setFilter(filter.attribute, filter.method);
    }

    if (isTwoValuesFilter(filter)) {
      parametron.api.setFilter(filter.attribute, filter.method, filter.start, filter.end);
    }
  });

  removeSortingForTextSearch<T>(parametron, filters);
  const updatedFilters = cloneDeep(parametron.parametron.data.filters);
  return !isEqual(updatedFilters, currentFilters);
};

export const filtersToPreset = <T extends IFiltersDefinition>(filters: T): IPresetFilter[] => {
  const presetFilters: IPresetFilter[] = [];
  Object.values(filters).map((filter) => {
    let f;
    if (isQFilter(filter)) {
      f = [filter.attribute, filter.method, filter.value, filter.searchOperator];
    }

    if (isMatchFilter(filter) || isEqFilter(filter) || isNeFilter(filter)) {
      f = [filter.attribute, filter.method, filter.value];
    }

    if (isInFilter(filter) || isNotInFilter(filter)) {
      f = [filter.attribute, filter.method, filter.value];
    }

    if (isNoValueFilter(filter) && filter.value) {
      f = [filter.attribute, filter.method];
    }

    if (isTwoValuesFilter(filter)) {
      f = [filter.attribute, filter.method, filter.start, filter.end];
    }
    if (f) {
      presetFilters.push(f);
    }
  });

  return presetFilters;
};

export const applyPresetToFilters = <T extends IFiltersDefinition>(
  defaultFilters: T | null,
  presetFilters: IPresetFilter[],
  initFilters,
): T => {
  const filters = {};
  presetFilters.map((p) => {
    // passing observable to convertPresetToFilter = mobx warnings
    const preset = [...p] as IPresetFilter;

    const filter = convertPresetToFilter(preset);
    const filterKey =
      defaultFilters && Object.keys(defaultFilters).find((k) => defaultFilters[k].attribute === filter?.attribute);

    if (filterKey) {
      filters[filterKey] = filter;
    }
  });

  // if initFilter is missing from preset filters, it means preset is saved with the filter disabled
  // filters obj in store need to be updated with correct filter value
  Object.keys(initFilters).map((k) => {
    if (!filters[k]) {
      filters[k] = updateFilterValue(initFilters[k], false);
    }
  });

  return { ...(defaultFilters || ({} as T)), ...filters };
};

export const convertPresetToFilter = ([attribute, method, value1, value2]: IPresetFilter): IFilter | null => {
  switch (method) {
    case 'q':
      return new QFilter(value1 as string);
    case 'match':
      return new MatchFilter(value1 as string | number);
    case 'eq':
      return new EqFilter(attribute, value1 as string | number);
    case 'ne':
      return new NeFilter(attribute, value1 as string | number);
    case 'in':
      return new InFilter(attribute, value1 as string | number | (string | number)[]);
    case 'not_in':
      return new InFilter(attribute, value1 as string | number | (string | number)[]);
    case 'exist':
      return new ExistFilter(attribute, true);
    case 'not_exist':
      return new NotExistFilter(attribute, true);
    case 'range':
      return new RangeFilter(attribute, value1 as string | number, value2 as string | number);
    default:
      return null;
  }
};

export const updateFilterValue = (filter: IFilter, value): IFilter | void => {
  if (isQFilter(filter)) {
    return new QFilter(value);
  } else if (isMatchFilter(filter)) {
    return new MatchFilter(filter.attribute, value);
  } else if (isEqFilter(filter)) {
    return new EqFilter(filter.attribute, value);
  } else if (isNeFilter(filter)) {
    return new NeFilter(filter.attribute, value);
  } else if (isInFilter(filter)) {
    return new InFilter(filter.attribute, value);
  } else if (isNotInFilter(filter)) {
    return new NotInFilter(filter.attribute, value);
  } else if (isNotExistFilter(filter)) {
    return new NotExistFilter(filter.attribute, value);
  }
};
export const toggleParam = <T>({
  event,
  paramComponentValues,
  setParamComponentValues,
}: {
  event: React.FormEvent<HTMLInputElement>;
  paramComponentValues: T;
  setParamComponentValues: React.Dispatch<React.SetStateAction<T>>;
}): void => {
  const { dataSectionStore } = getRootStore();
  const {
    navigate,
    state: { location },
  } = router;
  const queryParams = parseQueryParams(location.search);
  const param = event.currentTarget.getAttribute('data-param');

  if (typeof param !== 'string') {
    logger.error('toggleParam param is not a string as expected');
    return;
  }

  const isChecked = paramComponentValues[param];

  let query = { ...queryParams };

  if (!isChecked) {
    query = { ...queryParams, ...{ [param]: true } };
  } else {
    delete query[param];
    dataSectionStore.setParams({ [param]: false });
  }

  setParamComponentValues({ ...paramComponentValues, ...{ [param]: !isChecked } });

  const searchString = queryString.stringify({ ...query });

  navigate({ pathname: location.pathname, search: searchString });
};

export const searchByParam = ({ action, customParams }): void => {
  const { dataSectionStore } = getRootStore();
  const { resetParams, defaultParams } = dataSectionStore;
  const {
    navigate,
    state: { location },
  } = router;
  const query = parseQueryParams(location.search);
  const isClearAction = action === FilterActions.CLEAR;
  const isLoadPresetAction = action === FilterActions.LOAD_PRESET;

  if (isClearAction) {
    resetParams();
    navigate({ pathname: location.pathname, search: '' });
    return;
  }

  if (isLoadPresetAction) {
    const paramDifference = Object.keys(params).reduce((accumulator, key) => {
      if (params[key] !== defaultParams[key]) {
        accumulator[key] = params[key];
      }

      return accumulator;
    }, {} as IPresetParams);

    const search = queryString.stringify({ ...paramDifference, ...customParams });
    navigate({ pathname: location.pathname, search });
    return;
  }

  const search = queryString.stringify({ ...query });
  dataSectionStore.setParams({ ...customParams });
  navigate({ pathname: location.pathname, search: search });
};

export interface ISubmitFiltersParams {
  action: string;
  setParamComponentValues?;
  customParams?;
}

const customizeSort = (params, searchStore): Record<string, unknown> => {
  const hasFilters = searchStore?.parametron?.data?.filters?.length;
  const isSequenceNumberSort = searchStore?.parametron?.data?.sort === ParametronSort.SEQUENCE_NUMBER;
  const isIncludeDescendants = searchStore?.parametron?.data?.params.include_descendants;

  if (hasFilters && isSequenceNumberSort) {
    return { ...params, sort: ParametronSort.UPDATED_AT };
  }

  if (!hasFilters && !isIncludeDescendants) {
    return { ...params, sort: ParametronSort.SEQUENCE_NUMBER };
  }

  return params;
};

const paramValuesToComponents = (params, defaultParams, setParamComponentValues): IPresetParams => {
  const paramDifference = Object.keys(params).reduce((accumulator, key) => {
    if (params[key] !== defaultParams[key]) {
      accumulator[key] = params[key];
    }

    return accumulator;
  }, {} as IPresetParams);

  setParamComponentValues?.({
    include_deleted: !!paramDifference.include_deleted,
    include_internal_accounts: !!paramDifference.include_internal_accounts,
  });

  return paramDifference;
};

export const submitFiltersWithSeqNumber = ({
  action,
  setParamComponentValues,
  customParams,
}: ISubmitFiltersParams): void => {
  const { dataSectionStore } = getRootStore();
  const { resetParams, defaultParams, searchStore } = dataSectionStore;
  const {
    navigate,
    state: { location },
  } = router;
  const query = parseQueryParams(location.search);
  const isClearAction = action === FilterActions.CLEAR;
  const isLoadPresetAction = action === FilterActions.LOAD_PRESET;
  const params = dataSectionStore.getParams();

  if (isClearAction) {
    const customResetParams = { ...dataSectionStore.defaultParams };

    if (params.include_descendants) {
      customResetParams.sort = ParametronSort.UPDATED_AT;
    }

    resetParams(customResetParams);
    setParamComponentValues?.(customParams);
    navigate({ pathname: location.pathname, search: '' });
    return;
  }

  if (isLoadPresetAction) {
    const paramDifference = paramValuesToComponents(params, defaultParams, setParamComponentValues);
    const customized = customizeSort(paramDifference, searchStore);

    const search = queryString.stringify(customized);
    navigate({ pathname: location.pathname, search });
    return;
  }

  const customized = customizeSort({ ...query }, searchStore);

  const search = queryString.stringify(customized);
  navigate({ pathname: location.pathname, search });
};

export const submitFilters = ({ action, setParamComponentValues, customParams }: ISubmitFiltersParams): void => {
  const { dataSectionStore } = getRootStore();
  const { resetParams, defaultParams } = dataSectionStore;
  const {
    navigate,
    state: { location },
  } = router;

  const query = parseQueryParams(location.search);
  const isClearAction = action === FilterActions.CLEAR;
  const isLoadPresetAction = action === FilterActions.LOAD_PRESET;
  const params = dataSectionStore.getParams();

  if (isClearAction) {
    resetParams();
    setParamComponentValues?.(customParams);
    navigate({ pathname: location.pathname, search: '' });
    return;
  }

  if (isLoadPresetAction) {
    const paramDifference = paramValuesToComponents(params, defaultParams, setParamComponentValues);
    const search = queryString.stringify({ ...paramDifference });
    navigate({ pathname: location.pathname, search });
    return;
  }

  const search = queryString.stringify({ ...query });
  navigate({ pathname: location.pathname, search: search });
};

let params: Record<string, unknown> = {};

export const removeSortingForTextSearch = <T extends IFiltersDefinition>(
  parametron: ParametronStore,
  filters: T,
): void => {
  const { dataSectionStore } = getRootStore();
  const { navigate } = router;

  const { sort } = dataSectionStore.getParams();
  const removeSortParam = shouldRemoveSortParams<T>(filters);

  if (removeSortParam && sort) {
    params = dataSectionStore.getParams();
    const { sort: _, ...rest } = parseQueryParams(location.search);
    const searchString = queryString.stringify({ ...rest });
    navigate({ pathname: location.pathname, search: searchString });
    dataSectionStore.setParams({ sort: null, order: null });
  } else if (!removeSortParam && !sort && params.sort) {
    parametron.api.setParams(params);
  }
};

const shouldRemoveSortParams = <T extends IFiltersDefinition>(filters: T): boolean => {
  return Object.values(filters).findIndex(({ value, removeSortParam }: QFilter) => value && removeSortParam) > -1;
};
