import React, { useCallback, useState } from 'react';
import { toNumber } from 'lodash';
import FullCalendar, { CalendarApi, DateSelectArg, EventClickArg } from '@fullcalendar/react';
import { CalGenericMeeting } from '@mediafellows/mm3-types';

import { useDialogStore } from 'store/hooks';
import { today } from 'utils/date';
import { addMinutes } from 'date-fns';
import { MeetingForm } from './meeting-form';
import { ExportForm } from './export-form';
import { getLocationFromEvent, localTimezone } from './utils';
import { MeetingPreview } from './meeting-preview';
import { MeetingActions } from './ui';
import { IModalSize } from 'components/dialogs/types';

export const usePreviewMeeting = (calendar: React.RefObject<FullCalendar>): ((info: EventClickArg) => void) => {
  const { openModal } = useDialogStore();

  const handleEventClick = useCallback(
    (info: EventClickArg): void => {
      info.jsEvent.preventDefault();

      openModal({
        title: 'Meeting Details: ' + info.event.title,
        icon: <MeetingActions info={info} calendar={calendar} />,
        className: 'meeting-preview-dialog',
        body: () => (
          <MeetingPreview
            id={toNumber(info.event?.id)}
            title={info.event.title}
            {...(info.event?.extendedProps as Omit<CalGenericMeeting, 'title' | 'id'>)}
          />
        ),
      });
    },
    [calendar, openModal],
  );

  return handleEventClick;
};

export const useCreateMeeting = (): ((evt: CalendarApi | DateSelectArg, time_zone?: string) => void) => {
  const { openModal } = useDialogStore();
  const handleDateClick = useCallback(
    (evt: CalendarApi | DateSelectArg, time_zone?: string): void => {
      const locationId = toNumber((evt as DateSelectArg).resource?.id);

      openModal({
        title: 'Create Meeting',
        size: IModalSize.M,
        body: () => (
          <MeetingForm
            id={0}
            starts_at={((evt as DateSelectArg).start || today).toISOString()}
            ends_at={((evt as DateSelectArg).end || addMinutes(today, 30)).toISOString()}
            title=""
            description=""
            host_id={0}
            time_zone={time_zone}
            calendar={evt.view?.calendar}
            additional_seats={null}
            invites={[]}
            location_id={locationId}
            location={getLocationFromEvent(locationId, evt as DateSelectArg)}
          />
        ),
      });
    },
    [openModal],
  );

  return handleDateClick;
};

export const useExportCalendar = (): ((filters) => void) => {
  const { openModal } = useDialogStore();
  const handleExport = useCallback(
    (filters): void => {
      openModal({
        title: 'Export Calendar',
        className: 'export-form',
        body: () => <ExportForm filters={filters} />,
      });
    },
    [openModal],
  );

  return handleExport;
};
const CALENDAR_TIMEZONE = 'MFX_CALENDAR_TIMEZONE';

export const useTimezone = (): [string, (tz: string) => void] => {
  const [timezone, setTimezone] = useState<string>(() => {
    const cachedTimezone = localStorage.getItem(CALENDAR_TIMEZONE);
    if (cachedTimezone) {
      return cachedTimezone;
    }

    localStorage.setItem(CALENDAR_TIMEZONE, localTimezone);
    return localTimezone;
  });
  const changeTimeZone = useCallback((tz: string): void => {
    setTimezone(tz);
    localStorage.setItem(CALENDAR_TIMEZONE, tz);
  }, []);

  return [timezone, changeTimeZone];
};

export const useEditMeeting = (calendarRef: React.RefObject<FullCalendar>): ((meeting: CalGenericMeeting) => void) => {
  const { openModal } = useDialogStore();
  const calendar = calendarRef.current?.getApi();

  const handleEventClick = useCallback(
    (meeting: CalGenericMeeting): void => {
      openModal({
        title: 'Update Meeting',
        body: () => (calendar ? <MeetingForm {...meeting} calendar={calendar} /> : <></>),
        size: IModalSize.M,
      });
    },
    [calendar, openModal],
  );

  return handleEventClick;
};
