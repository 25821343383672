import { IAsset, IMm3Asset } from 'types';

import { useUploadImageDialog } from 'components/upload-preview-image';

import { IAssetActionConfig, IAssetActionName } from 'utils/actions/asset/types';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { ingestPreviewImage } from 'utils/apis/preview-image';

import { shouldDisplayUploadPreview as shouldDisplay } from './actions-acl';

export const useUploadPreviewAction = (
  items: (IAsset | IMm3Asset)[],
  options: IUseActionsOptionParam,
): IAssetActionConfig => {
  const [openUploadImageDialog] = useUploadImageDialog({
    onSubmit: async (file) => {
      const { id: itemId, preview_image_id } = items?.[0];
      if (file && itemId) {
        const newPreview = await ingestPreviewImage(file?.url, preview_image_id, itemId);
        options.onSuccess?.(IAssetActionName.UPLOAD_PREVIEW, {
          ...items[0],
          preview_image: newPreview,
        });
      }
    },
    customMessage: 'Select a preview image to upload',
  });

  return {
    name: IAssetActionName.UPLOAD_PREVIEW,
    shouldDisplay,
    icon: 'upload',
    title: 'Upload preview',
    handler: openUploadImageDialog,
  };
};
