import React from 'react';
import cx from 'classnames';
import { IconName } from 'blueprint5-icons';

import { IStyled } from 'types';
import { AssetFilters } from 'utils/asset';

import { MantineIcon } from 'utils/ui/icon';
import { getEntityType } from 'utils/general';
import { IImgResizerSizes, resizedUrl } from 'utils/img-resizer';
import { formatMm3TypeTag } from 'utils/product';

import { getFallbackIcon } from './utils';
import './style.scss';

export interface IThumbnailProps extends IStyled {
  image?: string | null;
  assetType?: string | null;
  showType?: boolean;
  background?: boolean;
  size?: IImgResizerSizes;
  placeholder?: React.ReactNode | boolean;
  noResize?: boolean;
  customTag?: string;
  fallbackIconName?: IconName;
}

const getImage = (image: string, size: IImgResizerSizes, noResize: boolean): string => {
  if (noResize) {
    return image;
  }
  return resizedUrl(image, { size });
};

export const Thumbnail: React.FC<IThumbnailProps> = ({
  image,
  assetType,
  showType,
  background,
  className,
  style,
  size = 'cga',
  placeholder,
  customTag,
  noResize = false,
  fallbackIconName,
}) => {
  const entityType = formatMm3TypeTag(assetType || '') || getEntityType(assetType);
  const resizedImage = getImage(image || '', size, noResize);
  const fallbackIcon = getFallbackIcon(assetType || '', fallbackIconName);

  return (
    <div className={cx(['thumbnail', className])} style={style}>
      {showType && (customTag || entityType) && (
        <div className="thumbnail__type text-uppercase p-1 text-truncate">{customTag || entityType}</div>
      )}

      {background && resizedImage && (
        <div style={{ backgroundImage: `url(${resizedImage})` }} className="thumbnail__content-background" />
      )}

      {image && <div style={{ backgroundImage: `url(${resizedImage})` }} className="thumbnail__content" />}
      {image && entityType === AssetFilters.VIDEO && (
        <MantineIcon className="thumbnail__play-icon" icon="play" size={30} />
      )}

      {!image && !placeholder && (
        <div className="thumbnail__content thumbnail__content--fallback">
          {fallbackIcon && <MantineIcon icon={fallbackIcon} size={20} />}
        </div>
      )}
      {placeholder}
    </div>
  );
};

export default Thumbnail;
