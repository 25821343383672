import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { IconAlias } from 'icons';
import { DeliveriesGenericDeliveryPackage } from '@mediafellows/mm3-types';
import { Wizard, WizardStep, useWizardSteps } from 'components/wizard';
import { WizardButtons } from 'components/delivery/ui';
import { FormAssetTreeSelector } from 'helpers/form';
import { StepIds, getTabId, filteredSteps } from './utils';

import { IPackageDeliveryStep } from './types';
import { DeliveryOrganizationRecipientsStep } from './wizard-steps/delivery-organization-recipients-step';
import { DeliveryProductsStep } from './wizard-steps/delivery-products-step';
import { DeliveryAssetsStep } from './wizard-steps/delivery-assets-step';
import { DeliverySummaryStep } from './wizard-steps/delivery-summary-step';
import { IWizardStep, IWizardStepElement } from 'components/wizard/utils';

export const DeliveryWizard: React.FC<
  IPackageDeliveryStep & {
    allAssetsSelected?: boolean;
    onFinish: (delivery: DeliveriesGenericDeliveryPackage) => void;
    excludeProducts?: boolean;
  }
> = ({
  deliveryForm,
  connectionForm,
  excludeProducts = false,
  tab = StepIds.Recipients,
  onFinish,
  allAssetsSelected,
}) => {
  const { organization_id, connection_id, product_ids, asset_ids, name } = deliveryForm.values || {};
  const [connectionValidating, onConnectionValidating] = useState<boolean>(false);

  let steps = [
    {
      id: StepIds.Recipients,
      disabled: !organization_id || (!connection_id && connection_id !== 0) || !connectionForm.valid,
      isLoading: connectionValidating,
    },
    { id: StepIds.Products, disabled: isEmpty(product_ids) },
    { id: StepIds.Assets, disabled: isEmpty(asset_ids) },
    {
      id: StepIds.Summary,
      nextButtonTitle: 'Save Draft',
      disabled: !name || isEmpty(asset_ids),
      action: (evt: React.MouseEvent<HTMLElement, MouseEvent>) => {
        deliveryForm.onSubmit(evt);
      },
    },
  ] as IWizardStep[];

  steps = excludeProducts ? filteredSteps(steps) : steps;
  const { nextButton, backButton, currentStepId, setCurrentStepId, isStepDisabled } = useWizardSteps(steps, {
    defaultStepId: getTabId(tab),
  });

  return (
    <Wizard
      selectedStepId={currentStepId}
      onChange={setCurrentStepId}
      showStepTitleIndex
      fitInParent
      footerPadded={false}
      footer={
        <WizardButtons
          {...{
            form: deliveryForm,
            connectionForm,
            onFinish,
            onConnectionValidating,
            nextButton,
            backButton,
            currentStepId,
          }}
        />
      }
    >
      <WizardStep
        id={StepIds.Recipients}
        title="Organizations"
        icon={IconAlias.ORGANIZATION}
        disabled={isStepDisabled(StepIds.Recipients)}
        loading={connectionValidating}
        panel={
          <DeliveryOrganizationRecipientsStep
            deliveryForm={deliveryForm}
            connectionForm={connectionForm}
            connectionValidating={connectionValidating}
          />
        }
      />

      {!excludeProducts ? (
        <WizardStep
          id={StepIds.Products}
          title="Products"
          icon={IconAlias.PRODUCT}
          disabled={isStepDisabled(StepIds.Products) || currentStepId === StepIds.Recipients}
          panel={<DeliveryProductsStep deliveryForm={deliveryForm} />}
        />
      ) : (
        (null as unknown as IWizardStepElement)
      )}

      <WizardStep
        id={StepIds.Assets}
        title="Assets"
        icon={IconAlias.ASSET}
        disabled={isStepDisabled(StepIds.Assets) || currentStepId === StepIds.Recipients}
        panel={
          excludeProducts ? (
            <DeliveryAssetsStep deliveryForm={deliveryForm} />
          ) : (
            <FormAssetTreeSelector
              showMetadataHover
              allSelected={allAssetsSelected}
              form={deliveryForm}
              assetType="all"
            />
          )
        }
      />

      <WizardStep
        id={StepIds.Summary}
        title="Package"
        icon={IconAlias.MENU}
        disabled={isStepDisabled(StepIds.Summary)}
        panel={<DeliverySummaryStep deliveryForm={deliveryForm} />}
      />
    </Wizard>
  );
};
