import React from 'react';

import {
  FormCountry,
  FormGeoSelect,
  FormInput,
  FormMultiSelect,
  FormResponsibleContacts,
  FormSelect,
  IUseFormReturn,
} from 'helpers/form';
import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { CustomContactRowEdit } from 'components/contact-form/custom-row-edit';

import { IContact } from 'types';
import FormOrganizationSelect from 'helpers/form/fields/form-organization/form-organization';
import { queryInterests } from 'pages/contact-details/api';
import { queryContacts } from 'utils/apis/contacts';

const ContactBusinessSection: React.FC<IUseFormReturn<IContact>> = ({ formData, handlers, values }) => {
  return (
    <FormSectionWrapperV2
      title="Business"
      wrapperClassName="py-3"
      className="entity-detail__infobox-wrapper"
      gridTemplateColumns="1fr 1frf"
      withBorder
    >
      <FormOrganizationSelect
        name="organization_id"
        key="organization_id"
        label="Organization"
        handlers={handlers}
        formData={formData}
      />
      <FormMultiSelect
        fetchValues={queryContacts}
        name="assistant_ids"
        label="Assistants"
        large
        {...handlers}
        {...formData.assistant_ids}
      />
      <FormCountry name="country_id" label="Country" large {...formData.country_id} {...handlers} />
      <FormResponsibleContacts {...handlers} {...formData.responsible_user_id} />
      <FormInput name="department" label="Department" {...handlers} {...formData.department} />
      <FormInput name="territory_rep" label="Sales Contact" {...handlers} {...formData.territory_rep} />
      <FormInput name="function" label="Function" {...handlers} {...formData.function} />
      <FormInput name="company_profile" label="Company Profile" {...handlers} {...formData.company_profile} />
      <CustomContactRowEdit handlers={handlers} formData={formData} />
      <FormSelect large name="customer_segment" label="Customer Segment" {...handlers} {...formData.customer_segment} />
      <FormSelect name="responsibility" label="Responsibility" large {...handlers} {...formData.responsibility} />
      <FormMultiSelect
        large
        name="tags"
        label="Tags"
        {...handlers}
        {...formData.tags}
        options={values.tags}
        allowNewItems
      />
      <FormGeoSelect
        large
        key="geo_scope"
        name="geo_scope_ids"
        label="Territories of Responsibility"
        {...formData.geo_scope_ids}
        {...handlers}
      />
      <FormMultiSelect
        large
        name="interests"
        label="Interests"
        {...handlers}
        {...formData.interests}
        fetchValues={queryInterests}
      />
    </FormSectionWrapperV2>
  );
};

export default ContactBusinessSection;
