import {
  createTheme,
  MantineThemeOverride,
  Tabs,
  Switch,
  Pill,
  ThemeIcon,
  Tooltip,
  SegmentedControl,
  Input,
  RangeSlider,
  Notification,
  Checkbox,
  Menu,
  Select,
  Divider,
} from '@mantine/core';
import { defaultTheme } from './default-theme';

export const theme: MantineThemeOverride = createTheme({
  ...defaultTheme,
  components: {
    ...defaultTheme.components,
    Switch: Switch.extend({
      defaultProps: {
        size: 'xs',
      },
      styles: {
        root: {
          '--switch-color': 'var(--mfx-primary)',
        },
        track: {
          '--mantine-color-dark-6': 'var(--mfx-gray-6)',
          '--mantine-color-dark-4': 'var(--mfx-gray-6)',
          cursor: 'pointer',
          pointerEvents: 'all',
        },
        label: {
          fontFamily:
            'Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, Icons16, sans-serif',
          fontWeight: '500',
          fontSize: '14px',
        },
        thumb: {
          border: 'var(--mfx-gray-5)',
          backgroundColor: 'var(--mfx-gray-5)',
        },
        body: {
          pointerEvents: 'none',
        },
      },
    }),
    Pill: Pill.extend({
      styles: {
        root: {
          background: 'var(--mfx-gray-1--dark)',
          color: 'var(--mfx-gray-6)',
        },
      },
    }),
    RangeSlider: RangeSlider.extend({
      defaultProps: {
        styles: {
          bar: {
            backgroundColor: 'var(--mfx-main)',
          },
          thumb: {
            backgroundColor: 'var(--mfx-main)',
          },
          label: {
            top: '15px',
            background: '#e1e8ed',
            color: 'var(--mfx-gray-5)',
          },
          root: {
            '--slider-track-bg': 'var(--mfx-gray-6)',
          },
          markLabel: {
            color: '#e1e8ed',
            paddingTop: '10px',
          },
          mark: {
            display: 'none',
          },
        },
      },
    }),
    Checkbox: Checkbox.extend({
      defaultProps: {
        size: 'xs',
        styles: {
          input: {
            cursor: 'pointer',
          },
          root: {
            cursor: 'pointer',
          },
          label: {
            fontSize: '14px',
          },
        },
      },
    }),
    ThemeIcon: ThemeIcon.extend({
      defaultProps: {
        styles: {
          root: {
            fill: 'currentColor',
          },
        },
      },
    }),
    Notification: Notification.extend({
      defaultProps: {
        styles: {
          root: {
            backgroundColor: 'var(--mfx-gray-5)',
          },
        },
      },
    }),

    Tooltip: Tooltip.extend({
      defaultProps: {
        withArrow: true,
        arrowSize: 8,
      },
      styles: {
        tooltip: {
          backgroundColor: 'var(--mfx-gray-5)',
          color: 'var(--mfx-gray-2)',
        },
      },
    }),
    Tabs: Tabs.extend({
      styles: {
        root: {
          '--tabs-color': 'var(--mfx-main)',
          '--tab-hover-color': 'transparent',
          '--tab-border-color': 'var(--mfx-gray-6)',
          '--tabs-list-border-width': '1px',
        },
      },
      defaultProps: {
        keepMounted: false,
      },
    }),
    SegmentedControl: SegmentedControl.extend({
      styles: {
        root: {
          backgroundColor: 'var(--mfx-gray-6)',
        },
        control: {
          borderRadius: '3px',
          display: 'flex',
        },
        label: {
          alignSelf: 'center',
        },
      },
    }),
    Modal: {
      styles: {
        header: {
          backgroundColor: 'var(--mfx-gray-7)',
          boxShadow: '0 1px 0 rgba(0, 0, 0, 0.4)',
        },
        title: {
          fontWeight: '600',
          fontSize: '16px',
        },
        content: {
          backgroundColor: 'var(--mfx-gray-3--dark)',
          boxShadow: ' 0 0 0 1px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.4), 0 18px 46px 6px rgba(0, 0, 0, 0.4)',
          color: 'var(--mfx-gray-1)',
        },
      },
    },
    Input: Input.extend({
      styles: {
        wrapper: {
          '--input-bg': 'var(--input-bg-color)',
          color: 'var(--mfx-gray-1)',
          '--input-height-md': '2.5rem',
        },
        input: {
          transition: 'box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9)',
          border: 'none',
          fontWeight: ' var(--mantine-font-size-md)',
        },
      },
    }),
    Select: Select.extend({
      styles: {
        wrapper: {
          '--input-bg': 'var(--input-bg-color)',
          color: 'var(--mfx-gray-1)',
          '--input-height-md': '2.5rem',
        },
        input: {
          transition: 'box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9)',
          border: 'none',
          fontWeight: ' var(--mantine-font-size-md)',
          // remove when migrating all formElements
          marginTop: '-2px',
          marginBottom: '-2px',
        },
      },
    }),
    Divider: Divider.extend({
      styles: {
        root: {
          borderColor: 'rgba(255,255,255,0.15)',
        },
      },
    }),
    Menu: Menu.extend({
      defaultProps: {
        withArrow: true,
        arrowSize: 14,
        closeOnClickOutside: true,
        closeOnItemClick: true,
        radius: 'sm',
        transitionProps: {
          transition: 'pop',
          duration: 150,
          timingFunction: 'ease',
        },
      },
      styles: {
        dropdown: {
          boxShadow: '0 0 0 1px rgba(0,0,0,0.2),0 2px 4px rgba(0,0,0,0.4),0 8px 24px rgba(0,0,0,0.4)',
        },
      },
    }),
  },
});
