import { action, observable } from 'mobx';

import { IAsset, ItemId } from 'types';

export class AssetsEditStore {
  @observable assets: IAsset[] = [];
  @observable assetIds: ItemId[] = [];
  @observable isModalOpen = false;
  @observable isPresetModalOpen = false;
  @observable isSavePresetModalOpen = false;
  @observable preset: IAsset;

  @observable onSuccess: (() => void) | null;

  @action.bound
  setIsModalOpen(isModalOpen: boolean, onSuccess: (() => void) | null = null): void {
    this.isModalOpen = isModalOpen;
    this.onSuccess = onSuccess;
  }

  @action.bound
  setAssetIds(ids: ItemId[]): void {
    this.assetIds = ids;
  }

  @action.bound
  setAssets(assets: IAsset[]): void {
    this.assets = assets;
  }

  @action.bound
  onDataChange(asset: IAsset): void {
    this.assets = this.assets.map((currentAsset) => (currentAsset.id === asset.id ? asset : currentAsset));
  }

  @action.bound
  setIsPresetModalOpen(isOpen: boolean): void {
    this.isPresetModalOpen = isOpen;
  }

  @action.bound
  setIsSavePresetModalOpen(isOpen: boolean): void {
    this.isSavePresetModalOpen = isOpen;
  }

  @action.bound
  applyPreset(preset: IAsset): void {
    this.assets = this.assets.map((asset) => (asset.selected ? { ...asset, ...preset, id: asset.id } : asset));
  }

  @action.bound
  savePreset(preset: IAsset): void {
    this.isSavePresetModalOpen = true;
    this.preset = preset;
  }
}
