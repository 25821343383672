import { useCallback } from 'react';

import { IUseAction } from 'utils/actions';
import { IMeetingActionName } from './types';
import { CalGenericMeeting } from '@mediafellows/mm3-types';
import { useEditMeeting } from 'components/scheduler/hooks';
import FullCalendar from '@fullcalendar/react';
import { useDialogStore } from 'store/hooks';

export const useEditAction: IUseAction<CalGenericMeeting, IMeetingActionName> = (items, options) => {
  const handleEventClick = useEditMeeting(options?.calendar as React.RefObject<FullCalendar>);
  const { close } = useDialogStore();
  const handler = useCallback(() => {
    if (!items?.[0] || !options) {
      return;
    }

    if (options.closePrevDialog) {
      close();
    }
    return handleEventClick(items[0]);
  }, [close, options, handleEventClick, items]);

  return {
    name: IMeetingActionName.EDIT,
    icon: 'edit',
    title: 'Edit',
    handler,
  };
};
