import { useBasicStore } from 'store';
import { formatCategories } from 'utils/categories';
import { byId, formatField } from 'utils/general';

export function useCategories(ids?: number[] | null, full = false): string | undefined {
  const { categories } = useBasicStore();

  if (!ids || !ids.length) {
    return;
  }

  if (full) {
    return formatCategories(ids, categories);
  }

  const categoriesById = byId(categories);

  return formatField(ids.map((categoryId) => categoriesById[categoryId]?.name)) as string;
}
