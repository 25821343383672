import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Dialog } from '@blueprintjs/core';
import { Button } from '@mantine/core';

import { Loading } from 'components/loading';
import { IAsset, IAssetPresetFields, ItemId } from 'types';
import { useStore } from 'store';
import { useAssetsEditStore } from 'store/hooks';
import { useRefreshDataSection } from 'utils/hooks';
import { assetDetailsSchema } from 'utils/schemas/asset';
import { getStorageItem, STORAGE_KEYS } from 'utils/storage';
import { ToastError } from 'components/toast';

import AssetEditForm from './asset-edit-form';
import MultipleAssetEditHeader from './components/multiple-asset-edit-header';
import SelectAssetPreset from './components/select-preset-modal';
import PresetControls from './components/preset-control';
import { SavePresetModal } from './components/save-preset-modal';
import { AmGenericAsset3 } from '@mediafellows/mm3-types';

import './index.scss';

interface IMultipleAssetsEditModalProps {
  getAssets: (assetIds: ItemId[], schema?: string) => Promise<IAsset[]>;
  saveAssets: (assets: IAsset[] | Partial<AmGenericAsset3> | Partial<AmGenericAsset3>[]) => Promise<void>;
}

const MultipleAssetsEditModal: React.FC<IMultipleAssetsEditModalProps> = observer(({ getAssets, saveAssets }) => {
  const {
    assetIds,
    assets,
    setAssets,
    setIsModalOpen,
    isModalOpen,
    onDataChange,
    isPresetModalOpen,
    setIsPresetModalOpen,
  } = useAssetsEditStore();
  const { toastStore } = useStore();
  const [isLoading, setIsLoading] = useState(true);
  const [isValid, setIsValid] = useState(true);

  const [presets, setPresets] = useState<Record<string, IAssetPresetFields>>(
    getStorageItem(STORAGE_KEYS.ASSET_EDIT_PRESETS) as Record<string, IAssetPresetFields>,
  );

  useEffect(() => {
    let saveAssets = (result: IAsset[]): void => {
      setAssets(result);
      setIsLoading(false);
    };

    try {
      if (assetIds?.length) {
        setIsLoading(true);
        getAssets(assetIds, assetDetailsSchema).then(saveAssets);
      }
    } catch (error) {
      toastStore.error(error.text);
    }

    return () => {
      saveAssets = () => null;
    };
  }, [assetIds, getAssets, toastStore, setAssets]);

  const refresh = useRefreshDataSection();

  const handleCancel = useCallback((): void => {
    setIsModalOpen(false);
    setAssets([]);
  }, [setAssets, setIsModalOpen]);

  const [isSending, setIsSending] = useState(false);

  const handleSubmit = useCallback(async () => {
    try {
      setIsSending(true);
      toastStore.info('Saving');
      await saveAssets(assets);
      toastStore.clearAll();
      toastStore.success('Changes applied successfully!');
      setIsModalOpen(false);
      refresh();
    } catch (error) {
      toastStore.error(<ToastError error={error} />);
    } finally {
      setIsSending(false);
    }
  }, [assets, refresh, saveAssets, setIsModalOpen, toastStore]);

  const openPresetSelector = useCallback(() => {
    setIsPresetModalOpen(true);
  }, [setIsPresetModalOpen]);

  const closePresetSelector = useCallback(() => {
    setIsPresetModalOpen(false);
  }, [setIsPresetModalOpen]);

  return (
    <Dialog className="multi-asset-edit-form" isOpen={isModalOpen} onClose={handleCancel}>
      <MultipleAssetEditHeader openPresetModal={openPresetSelector} />
      <div>
        {isLoading && <Loading />}
        {assets.map((asset) => (
          <div key={asset.id} className="multi-asset-edit-form--element">
            <AssetEditForm
              formId={asset.id}
              asset={asset}
              onDataChange={onDataChange}
              setIsValid={setIsValid}
              selectable
            />
            <PresetControls values={asset} />
          </div>
        ))}
      </div>
      <div className="multi-asset-edit-form--footer me-3">
        <Button size="xs" variant="default" className="me-3" onClick={handleCancel}>
          Cancel
        </Button>
        <Button size="xs" variant="primary" onClick={handleSubmit} disabled={!isValid || isSending}>
          Save
        </Button>
      </div>
      <SelectAssetPreset presets={presets} onClose={closePresetSelector} opened={isPresetModalOpen} />
      <SavePresetModal setPresets={setPresets} />
    </Dialog>
  );
});

export default MultipleAssetsEditModal;
