import React from 'react';
import { MantineIcon } from 'utils/ui/icon';
import cx from 'classnames';

import { IStyled } from 'types';
import { useStore } from 'store';
import './style.scss';

interface IClipboardView extends IStyled {
  value?: string | null;
  textClassName?: string;
  /**
   * If set to true and copy value is empty container element still will be returned,
   * otherwise null will be returned.
   *
   * Default: false
   */
  showEmptyContainer?: boolean;
}

const getCopyValue = (children: React.ReactNode, value: string | null | undefined): string | undefined => {
  if (value) {
    return value;
  }

  if (typeof children === 'string') {
    return children;
  }

  return undefined;
};

/**
 * If no value provided and children property is type of string
 * children property will be used
 */
export const ClipboardView: React.FC<IClipboardView> = ({
  children,
  className,
  style,
  value,
  textClassName,
  showEmptyContainer = false,
}) => {
  const { toastStore } = useStore();

  const displayValue = children || value;
  const copyValue = getCopyValue(children, value);

  if (!displayValue || !copyValue) {
    if (!showEmptyContainer) {
      return null;
    }

    return <span className={cx('clipboard-view__container', className)} style={style}></span>;
  }

  return (
    <span className={cx('clipboard-view__container', className)} style={style}>
      <span className={textClassName}>{displayValue}</span>

      <MantineIcon
        className="clipboard-view__copy"
        icon="duplicate"
        onClick={() => {
          navigator.clipboard.writeText(copyValue);
          toastStore.info(`${copyValue} copied to clipboard`);
        }}
      />
    </span>
  );
};
