import { IAsset } from 'types';

export const editAssetPresetInitialData = {
  ['@type']: '',
  access_level: '',
  archive_number: '',
  asset_type: '',
  asset_identifier: '',
  classification: '',
  created_at: '',
  default_layer: {
    copyright_notice: '',
    description: '',
    caption: '',
    talent_in_image: '',
    photographer: '',
    notes: '',
  },
  effective_permissions: [],
  external_reference: '',
  file_name: '',
  format: '',
  geographies: [],
  height: '',
  id: undefined,
  md5: '',
  name: '',
  preview_image: null,
  preview_image_id: '',
  permissions: [],
  language_ids: [],
  protection_levels: [],
  status: '',
  updated_at: '',
  user_md5: '',
  width: '',
} as unknown as IAsset;

export const assetFormCustomContext = {
  properties: {
    'default_layer.name': { required: true, validations: [{ presence: true }] },
    access_level: { required: true, validations: [{ presence: true }] },
    status: { required: true, validations: [{ presence: true }] },
    classification: { required: true, validations: [{ presence: true }] },
  },
};
