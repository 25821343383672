export const initialValues = {
  include_descendants: true,
  descendants_ids: [],
  subject: 'Enjoy the view',
  recipient_list: [],
  cc_list: [],
  bcc_list: [],
  generate_links_only: false,
  contact_selections: [],
  cc_contact_selections: [],
  bcc_contact_selections: [],
  send_emails: false,
  allowed_views: null,
  type: 'Recommendation::Product',
  visible: true,
  requires_login: false,
};
