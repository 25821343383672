import { getProductsWithAssets } from 'components/marketing-entity-detail/group-asset-tree/utils';
import { GroupTypes, IAsset, ISearchFilter, ItemId } from 'types';
import { queryAssets } from 'utils/apis/asset';
import { queryContacts } from 'utils/apis/contacts';
import { searchProducts } from 'utils/apis/product';
import { getValidIds } from 'utils/general';

export type IPartialRecommendation = {
  asset_ids: number[];
  product_ids: number[];
  otherAssets: IAsset[];
  productAssetIds: IAsset[];
  recipients: string[];
};

export const initialValues: IPartialRecommendation = {
  asset_ids: [],
  product_ids: [],
  otherAssets: [],
  productAssetIds: [],
  recipients: [],
};
type IMainEntityType = 'users' | 'products' | 'assets';

export async function getRecommendationDataFromSelection(
  filters: ISearchFilter[],
  mainEntityType: IMainEntityType,
): Promise<IPartialRecommendation> {
  if (mainEntityType === 'users') {
    const contacts = await queryContacts({}, filters, 'id');
    return {
      ...initialValues,
      recipients: getValidIds(contacts),
    };
  }
  if (mainEntityType === 'assets') {
    const assets = await queryAssets({}, filters, 'id');
    return {
      ...initialValues,
      asset_ids: getValidIds(assets),
      otherAssets: assets,
    };
  }

  const products = await searchProducts({ search: { filters } }, 'id');
  return {
    ...initialValues,
    product_ids: getValidIds(products),
  };
}

export async function getRecommendationDataFromGroup(
  groupId?: ItemId,
  groupType?: GroupTypes | `${GroupTypes.SELECTION}/${IMainEntityType}`,
): Promise<IPartialRecommendation> {
  if (!groupId) {
    return initialValues;
  }
  const filters = [['group_ids', 'in', [groupId]]] as ISearchFilter[];

  if (groupType?.startsWith(`${GroupTypes.SELECTION}/`)) {
    const mainEntityType = groupType.split('/').at(-1) as IMainEntityType;
    return getRecommendationDataFromSelection(filters, mainEntityType);
  }

  const productsPromise = searchProducts({ search: { filters }, only_roots: false, per: 200 }, 'id', 'unfurl');
  const assetsPromise = queryAssets({}, filters, 'id');
  const [products = [], assets = []] = await Promise.all([productsPromise, assetsPromise]);
  const assetIds = assets.map((e) => Number(e?.id));
  const productIds = getValidIds(products);
  let otherAssets: IAsset[] = [];
  let groupProductAssets: IAsset[] = [];

  if (groupType === GroupTypes.MOBILE_APP_SYNC) {
    const productsWithAssets = await getProductsWithAssets(groupId, productIds);
    const productAssetIds = productsWithAssets
      ?.flatMap((productWithAssets) => productWithAssets.assets)
      .map((asset) => asset.id);

    const result = assets?.reduce(
      (acc: { otherAssets: IAsset[]; groupProductAssets: IAsset[] }, asset) => {
        const isProductAsset = productAssetIds.includes(asset.id);
        if (!isProductAsset) acc.otherAssets.push(asset);
        else acc.groupProductAssets.push(asset);
        return acc;
      },
      { otherAssets: [], groupProductAssets: [] },
    );
    otherAssets = result.otherAssets;
    groupProductAssets = result.groupProductAssets;
  }

  return {
    ...initialValues,
    asset_ids: assetIds,
    product_ids: productIds,
    otherAssets,
    productAssetIds: groupProductAssets,
  };
}
