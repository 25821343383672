import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Title } from '@mantine/core';
import { Link } from 'react-router-dom';

import { Globals } from 'types';
import { Wizard, WizardStep } from 'components/wizard';
import { StepAbout } from './step-about';
import { StepOrganization, keysAddress } from 'pages/registration/step-organization';
import { StepPreview } from './step-preview';
import { StepDone } from './step-done';
import { useStore } from 'store';
import { Footer } from 'components/footer';
import { IFormData, isValid, useForm } from 'helpers/form';

import { ToastError } from 'components/toast';
import { IRegistrationData, customContext, defaults, initialValues, StepIds, steps } from 'pages/registration/types';
import { MantineIcon } from 'utils/ui/icon';
import { chipmunk } from 'utils/chipmunk';
import { cleanUp } from 'utils/payload';

import './style.scss';

const getStepIndex = (step: number | null): number => {
  return steps.findIndex((s) => s.id === step);
};

const isValidStep = (stepId: number | null, formData: IFormData<Partial<IRegistrationData>>): boolean => {
  const step = steps[getStepIndex(stepId)];

  if (!step || !step.keys) {
    return false;
  }

  return isValid(formData, step?.keys);
};

const isStepEnabled = (stepId: number | null, formData: IFormData<Partial<IRegistrationData>>): boolean => {
  const stepIndex = getStepIndex(stepId);

  for (let i = stepIndex; i > -1; i--) {
    const step = steps[i].id;

    if (!isValidStep(step, formData)) {
      return false;
    }

    if (step === StepIds.Step2 && formData.hasOwnProperty('organization_legal_address_city')) {
      return isValid(formData, keysAddress);
    }
  }

  return true;
};

const Registration: React.FC<{}> = observer(() => {
  const { toastStore } = useStore();
  const [currentStep, setCurrentStep] = useState<number>(StepIds.Step1);
  const [registrationInProgress, setRegistrationInProgress] = useState(false);

  const handleSubmit = (data: IRegistrationData): void | Promise<void> => {
    if (currentStep === StepIds.Step1 && isStepEnabled(currentStep, formData)) {
      handleAboutContinue();
    }
    if (currentStep === StepIds.Step2 && isStepEnabled(currentStep, formData)) {
      handleOrganizationContinue();
    }

    if (currentStep === StepIds.Step3 && isStepEnabled(currentStep, formData)) {
      handlePreviewContinue(data);
    }
  };

  const { formData, ...rest } = useForm<Partial<IRegistrationData>>(
    initialValues,
    'um.registration',
    handleSubmit,
    customContext,
  );

  const form = {
    ...rest,
    formData,
    valid: isStepEnabled(currentStep, formData),
  };

  const handleAboutContinue = (): void => setCurrentStep(StepIds.Step2);

  const handleOrganizationBack = (): void => setCurrentStep(StepIds.Step1);

  const handleOrganizationContinue = (): void => setCurrentStep(StepIds.Step3);

  const handlePreviewBack = useCallback((): void => {
    setCurrentStep(StepIds.Step2);
  }, []);

  const handlePreviewContinue = useCallback(
    async (previewData: IRegistrationData): Promise<void> => {
      setRegistrationInProgress(true);

      const regData: IRegistrationData = {
        ...previewData,
        user_phone_0_number: previewData.organization_phone_0_number || undefined,
        organization_phone_0_number: previewData.organization_phone_0_number || undefined,
      };
      const updatedDefaults = {
        user_phone_0_label: regData.user_phone_0_number ? 'Work' : undefined,
        organization_phone_0_label: regData.organization_phone_0_number ? 'Work' : undefined,
        ...(regData.organization_legal_address_street
          ? {
              ...defaults,
              user_address_street: regData.organization_legal_address_street,
              user_address_zip_code: regData.organization_legal_address_zip_code,
              user_address_city: regData.organization_legal_address_city,
              user_address_country_id: regData.organization_legal_address_country_id,
            }
          : {}),
      };
      const mappedData = cleanUp(regData, updatedDefaults);

      await chipmunk.run(async (chipmunk) => {
        try {
          await chipmunk.action('um.registration', 'create', {
            body: mappedData,
            headers: { 'Session-Id': null },
          });

          setCurrentStep(StepIds.Step4);
        } catch (e) {
          toastStore.error(<ToastError error={e} placeholder="Something went wrong" />);
        }
        setRegistrationInProgress(false);
      });
    },
    [toastStore],
  );

  return (
    <div className={`page--registration ${Globals.MODE_DARK_CLASS}`}>
      <div className="registration">
        <div className="registration__head">
          <Title order={3}>Register</Title>
        </div>

        <div className="registration__line" />

        <div className="registration__content">
          <Wizard selectedStepId={currentStep} onChange={setCurrentStep}>
            <WizardStep
              id={StepIds.Step1}
              title="About"
              description="Step 1"
              icon="person"
              disabled={currentStep === StepIds.Step4}
              panel={
                <StepAbout form={form} onContinue={handleAboutContinue} isVisible={currentStep === StepIds.Step1} />
              }
            />

            <WizardStep
              id={StepIds.Step2}
              title="Organization"
              description="Step 2"
              icon="office"
              disabled={!isStepEnabled(steps[0].id, formData) || currentStep === StepIds.Step4}
              panel={
                <StepOrganization
                  form={form}
                  onBack={handleOrganizationBack}
                  onContinue={handleOrganizationContinue}
                  isVisible={currentStep === StepIds.Step2}
                />
              }
            />

            <WizardStep
              id={StepIds.Step3}
              title="Preview"
              description="Step 3"
              icon="menu"
              disabled={!isStepEnabled(steps[1].id, formData) || currentStep === StepIds.Step4}
              panel={<StepPreview form={form} onBack={handlePreviewBack} regInProgress={registrationInProgress} />}
            />
            <WizardStep
              id={StepIds.Step4}
              title="Done!"
              description="Step 4"
              icon="tick"
              disabled={true}
              panel={<StepDone />}
            />
          </Wizard>
        </div>

        <div className="registration__note">
          <div>You already have an account?</div>
          <Link className="d-inline-flex align-items-center ms-2" to="/login">
            <MantineIcon className="me-1" icon="log-in" />
            Sign In
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
});

export default Registration;
