import { EqFilter, RangeFilter, QFilter } from 'helpers/filters/types';
import { additionalFilters } from 'components/contact-filters/additional-filters';
import { IContactFilters } from 'components/contact-filters/contact-filters';

export const defaultContactFilters: IContactFilters = {
  _: new QFilter(''),
  status: new EqFilter('status', ''),
  role_id: new EqFilter('role_id', ''),
  activated_at: new RangeFilter('activated_at', '', ''),
  created_at: new RangeFilter('created_at', '', ''),
  last_login_at: new RangeFilter('last_login_at', '', ''),
  organization_id: new EqFilter('organization_id', ''),
  country_id: new EqFilter('country_id', ''),
  responsible_user_id: new EqFilter('responsible_user_id', ''),
  bouncing: new EqFilter('bouncing', false),
  mobile_sync: new EqFilter('mobile_sync', false),
  ...additionalFilters,
};

export const contactStats = ['status', 'responsibility', ...Object.keys(additionalFilters)].join(',');
