import React from 'react';
import { observer } from 'mobx-react-lite';

import { IAsset } from 'types';
import { IFormData, IFormHandlers } from 'helpers/form/types';
import {
  AssetSettingsSection,
  AssetIdentifiersSection,
  AssetAboutSection,
  IAssetFormFields,
} from 'components/asset/asset-edit-form/sections';

export interface IAssetPresetFieldsProps {
  formId: string;
  formData: IFormData<IAssetFormFields>;
  handlers: IFormHandlers<IAssetFormFields>;
  asset: IAsset;
}

export const AssetPresetFields: React.FC<IAssetPresetFieldsProps> = ({ formId, formData, handlers, asset }) => {
  return (
    <>
      <AssetSettingsSection formData={formData} formId={formId} handlers={handlers} />

      <AssetAboutSection formData={formData} handlers={handlers} assetType={asset?.default_layer?.['@type']} />

      <AssetIdentifiersSection formData={formData} handlers={handlers} />
    </>
  );
};

export default React.memo(observer(AssetPresetFields));
