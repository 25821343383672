import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import { FormTreeSelect, ITreeSelectOption, IFormTreeSelectProps } from 'helpers/form';
import { useBasicStore } from 'store/hooks';
import { formatCategory } from 'utils/categories';
import { byId } from 'utils/general';

export const FormCategories: React.FC<Omit<IFormTreeSelectProps, 'options'>> = observer((props) => {
  const { categories } = useBasicStore();

  const formatCategoryLabel = useCallback(
    ({ value }: ITreeSelectOption): string => {
      const categoriesById = byId(categories);
      return formatCategory(parseInt(value, 10), categoriesById);
    },
    [categories],
  );

  // IMPORTANT: options should be the last argument in case props contains options as well!
  // otherwice options will be overriten which will result in long strange debug session
  return <FormTreeSelect tagRenderer={formatCategoryLabel} {...props} options={categories} />;
});

export default FormCategories;
