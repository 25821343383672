import React, { useMemo } from 'react';
import { isEmpty, isString, isNull, isUndefined, isArray, omitBy } from 'lodash';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Tooltip } from '@mantine/core';
import { IconName } from 'blueprint5-icons';
import {
  IPhone,
  ISocialMediaProfile,
  IContact,
  ItemId,
  IFetchThumbnailImages,
  DetailsPageTabs,
  IUserAgent,
  IGenericObject,
  IAnalyticsProps,
} from 'types';
import { Routes } from 'utils/routes';
import { IAction } from 'utils/actions';
import { useTerritoryName } from 'utils/hooks/territory';
import { ActionMenuItem } from 'components/action';
import { LabelValuePair as Row } from 'components/label-value-pair';
import { UserAvatar } from 'components/user-avatar';

import { IActionName } from 'utils/actions/types';
import { IAnalyticsContact } from 'components/analytics/contact-analytics';
import { loadProductGroupItems } from 'utils/apis/groups';
import { pluralWordInflect, formatField, formatFullName, splitStringItems } from 'utils/general';
import { useCategories } from 'utils/hooks';
import { Classes } from './';
import { MantineIcon } from './icon';
export { getVideoResolution } from './resolution';

import './style.scss';

interface IAnalyticsGroup {
  id?: string;
  name?: string;
  type?: string;
}

interface IAnalyticsOrganization {
  id: string;
  name: string;
}

export const getContactName = (contact?: { first_name: string; last_name: string } | null): string => {
  if (!contact) {
    return '';
  }

  const { first_name, last_name } = contact;

  return [first_name, last_name].filter((p) => Boolean(p)).join(' ');
};

export const placeholder = (data?: unknown, className = ''): JSX.Element => {
  if (data) {
    return <>{data}</>;
  }

  return <span className={cx(`${className} ${Classes.TEXT_MUTED}`)}>{'—'}</span>;
};

export const getPhone = (phones: IPhone[] = [], order = ['Mobile', 'Work', 'Home']): IPhone | undefined => {
  const numberByLabel = (phones || []).reduce((acc, phone) => {
    if (order.includes(phone.label)) {
      acc[phone.label] = phone.number;
    }

    return acc;
  }, {});

  for (const label of order) {
    if (numberByLabel[label]) {
      return { label, number: numberByLabel[label] };
    }
  }
  return;
};

const SocialProfileIconsMap: Record<string, IconName> = {
  LinkedIn: 'link',
  Facebook: 'link',
  Twitter: 'link',
  Blog: 'link',
};

export const renderSocialLinks = (profiles: ISocialMediaProfile[] = []): JSX.Element | undefined => {
  if (!profiles) {
    return;
  }

  const links = profiles.map((profile) => (
    <a key={`${profile.label}-${profile.link}`} href={profile.link} className="social-icon-item">
      <MantineIcon icon={SocialProfileIconsMap[profile.label]} size={24} />
    </a>
  ));

  return <>{links}</>;
};

export const getResponsibleUserLabel = (): string => {
  return 'Responsible Contact';
};

export const ResponsibleUser = (props: { contact?: IContact }): JSX.Element | null => {
  const { contact } = props;
  const label = getResponsibleUserLabel();

  if (contact?.responsible_user) {
    return (
      <Link to={`/contacts/${contact.responsible_user?.id}`}>
        <span title={label}>{getContactName(contact?.responsible_user)}</span>
      </Link>
    );
  }

  return (
    <span title={label} style={{ fontStyle: 'italic' }}>
      not assigned
    </span>
  );
};

export const renderMenuActionItems = (actions: IAction<IActionName>[]): JSX.Element => {
  return (
    <>
      {actions.map((action, index) => (
        <ActionMenuItem action={action} key={`${action.icon}_${action.title}_${index}`} />
      ))}
    </>
  );
};

export const If: React.FC<{ condition: boolean }> = ({ condition, children }) => {
  if (!condition || !children) {
    return null;
  }

  return <>{children}</>;
};

export const ListItemCount: React.FC<{ entityCount?: number; itemName: string; className?: string }> = ({
  entityCount,
  itemName,
  className,
}) => {
  if (entityCount === undefined) return placeholder();
  const pluralizedText = pluralWordInflect(itemName, entityCount);
  return <span className={className}> {pluralizedText} </span>;
};

export const ListItemCountLink: React.FC<{ entityCount?: number; link: string; linkText: string }> = ({
  entityCount,
  link,
  linkText,
}) => {
  if (entityCount === undefined) return placeholder();
  const pluralizedText = pluralWordInflect(linkText, entityCount);
  if (entityCount === 0) return <span> {pluralizedText} </span>;
  return <Link to={link}>{pluralizedText}</Link>;
};

export const DetailCountLink: React.FC<{
  entityCount: number | undefined;
  link: string;
  label: string;
  rawLabel?: boolean;
  hidden?: boolean;
}> = ({ entityCount, link, label, hidden, rawLabel = false }) => {
  if (entityCount) {
    return <Row label={label} hidden={hidden} rawLabel={rawLabel} value={<Link to={link}>{entityCount}</Link>} />;
  }
  return <Row label={label} hidden={hidden} rawLabel={rawLabel} value={entityCount} />;
};

export const LinkWithArrow: React.FC<{
  to: string | { pathname: string; state: unknown };
  text: string;
  className?: string;
  state?: IAnalyticsProps;
}> = ({ to, className, text, state }) => (
  <Link className={cx(`d-flex align-items-center ${className}`)} to={to} state={state}>
    <MantineIcon icon="arrow-right" className="mx-2" size={12} />
    <span>{text}</span>
  </Link>
);
export const CategoriesInfo: React.FC<{ category_ids?: number[] | null; expanded?: boolean }> = observer(
  ({ category_ids = [], expanded }) => {
    const categoriesInfo = useCategories(category_ids, true);

    const categoriesList = useMemo(() => {
      if (!categoriesInfo) {
        return null;
      }
      const list = categoriesInfo.split(', ');
      return list.map((path) => {
        const categoryParts = path.split(' > ');
        const name = categoryParts[categoryParts.length - 1];
        return { name, path };
      });
    }, [categoriesInfo]);

    if (!category_ids?.length) {
      return placeholder();
    }
    if (expanded || !categoriesList) {
      return <>{placeholder(categoriesInfo)}</>;
    }
    return <>{placeholder(formatField(categoriesList.map(({ name }) => name)))}</>;
  },
);

export const UserAgent: React.FC<{ user_agent: IUserAgent }> = ({ user_agent }) => (
  <div>
    <div title={user_agent.browser_version || ''}>
      {user_agent.browser || ''} {user_agent.browser_version.split('.')[0] || ''}
    </div>
    {user_agent.os ? (
      <div>
        {user_agent.os || ''} {user_agent.os_version}
      </div>
    ) : null}
    {user_agent.device && user_agent.device !== 'Other' ? <div>{user_agent.device}</div> : 'N/A'}
  </div>
);

export const AnalyticsContact: React.FC<{ user: IContact | IAnalyticsContact; interaction?: string }> = ({
  user,
  interaction,
}) => {
  const fullName = user?.first_name || user?.last_name || user?.full_name ? formatFullName(user) : user?.email;
  const first_name = user?.first_name || user?.email;
  const publicUser = user?.email || fullName;
  const isActiveStatus = user?.status === 'active' ? false : true;

  return (
    <div className="analytics-list-table__contact">
      <UserAvatar
        disabled={isActiveStatus}
        offset={5}
        size={7}
        className="analytics-list-table__contact-avatar"
        user={{ ...user, first_name }}
      />
      {user?.id !== '0' ? (
        <Link
          className="analytics-list-table__contact-name text-truncate"
          to={`/contacts/${user?.id}/${DetailsPageTabs.ANALYTICS}`}
          state={{ interaction }}
        >
          {fullName}
        </Link>
      ) : (
        <Tooltip label={publicUser} position="top-start">
          <span className="d-inline-block analytics-list-table__contact-name text-truncate">{publicUser}</span>
        </Tooltip>
      )}
    </div>
  );
};

export const AnalyticsOrganization: React.FC<{ user: IContact; organization?: IAnalyticsOrganization }> = ({
  user,
  organization,
}) => {
  const userOrg = organization ? organization : { id: user?.organization_id, name: user?.organization_name };

  if (!userOrg.id || userOrg.id === '0') {
    return <span>{'N/A'}</span>;
  }

  return (
    <Tooltip label={userOrg?.name} position="top">
      <div className="video-screenings-list-table__organization-name text-truncate">
        <Link to={`${Routes.ORGANIZATIONS}/${userOrg?.id}`}>{userOrg?.name}</Link>
      </div>
    </Tooltip>
  );
};

export const AnalyticsContext: React.FC<{ group?: IAnalyticsGroup }> = ({ group }) => {
  if (!group || !group?.id || !group?.name || !group?.type) return <span>N/A</span>;

  const { id, name, type } = group;
  const groupType = type?.split('::')?.pop()?.toLowerCase() + 's';

  return (
    <div className="analytics-list-table__contact">
      <Link className="analytics-list-table__group-name" to={`/${groupType}/${id}`}>
        {name}
      </Link>
    </div>
  );
};

export const fetchProductThumbnailImages = async (groupId?: ItemId): IFetchThumbnailImages => {
  if (!groupId) {
    return [];
  }
  const productThumbnails = await loadProductGroupItems({
    group_ids: groupId,
    per: 4,
  });
  if (!productThumbnails || !productThumbnails.length) {
    return [{}];
  }
  return productThumbnails.map((product) => ({
    image: product.inherited_preview_image?.url,
    assetType: product.type,
  }));
};

export const omitNilValues = (item): IGenericObject => {
  return omitBy(item, (v) => isUndefined(v) || isNull(v) || ((isArray(v) || isString(v)) && isEmpty(v)));
};

export const UserTerritory: React.FC<{ countryId: string; geoCountry?: string }> = ({ countryId, geoCountry }) => {
  const countryName = useTerritoryName(countryId);
  if (geoCountry) {
    return countryName ? <span>{`${countryName} (${geoCountry})`}</span> : <span>{`N/A (${geoCountry})`}</span>;
  }
  return <span>{'N/A'}</span>;
};

export const EmailsBlock: React.FC<{ emails?: string | string[] | null; title?: string }> = ({ emails, title }) => {
  const items = Array.isArray(emails) ? emails : splitStringItems(emails);
  return (
    <>
      {title && <div className="bp3-text-muted pt-3 layout-block__subtitle">{title}</div>}
      {!items || !items.length ? (
        <i className="bp3-text-muted p-4">There are no emails to show here </i>
      ) : (
        <div className="d-flex flex-wrap p-3">
          {items.map((email, index) => (
            <span className="event-attendees-tab__tag m-1" key={index}>
              {email}
            </span>
          ))}
        </div>
      )}
    </>
  );
};
