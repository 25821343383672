import { useEffect, useRef, useMemo } from 'react';
import { isEqual, merge } from 'lodash';

import { IAsset, IMm3Asset } from 'types';
import { parseKeyPathToObject } from 'utils/payload';
import { flags } from 'utils/flags';

import { editAssetPresetInitialData, assetFormCustomContext } from 'components/asset/asset-edit-form/constants';
import { IAssetFormFields } from 'components/asset/asset-edit-form/sections';

import { Model } from 'helpers/filters/types';
import { useForm, IUseFormReturn, useMm3Form } from 'helpers/form';
import { IUseMm3FormReturn } from 'helpers/form/use-mm3-form';
import { IValidationResult } from 'helpers/form/mm3/types';
import { getRequiredError } from 'helpers/form/mm3/validation';

import { getAssetFormFields } from './utils';

type IOnDataChangeCallback = (object: IAsset | IMm3Asset) => void;
interface IUseAssetEditFormProps {
  asset?: IAsset | IMm3Asset;
  onDataChange?: IOnDataChangeCallback;
  handleSubmit?: (values: IAssetFormFields | IMm3Asset) => Promise<void>;
}
type IUseAssetEditForm = (
  props: IUseAssetEditFormProps,
) => IUseFormReturn<IAssetFormFields> | IUseMm3FormReturn<IMm3Asset>;

const customValidator = (values: IMm3Asset, validation: IValidationResult<IMm3Asset>): IValidationResult<IMm3Asset> => {
  const classificationValidation = !values?.classification
    ? {
        classification: { required: true, ...getRequiredError() },
      }
    : { classification: { required: true } };

  return merge({}, validation, classificationValidation) as IValidationResult<IMm3Asset>;
};

export const useAssetEditForm: IUseAssetEditForm = ({ asset = {}, onDataChange, handleSubmit }) => {
  const initialAssetFields = useMemo(
    () =>
      flags.isMm3Assets ? asset : getAssetFormFields({ ...editAssetPresetInitialData, ...(asset as IAssetFormFields) }),
    [asset],
  );

  const latestAssetFields = useRef(initialAssetFields);

  const oldform = useForm<IAssetFormFields>(
    (flags.isMm3Assets ? {} : initialAssetFields) as IAssetFormFields,
    Model.ASSETS,
    handleSubmit,
    assetFormCustomContext,
  );
  const mm3form = useMm3Form<IMm3Asset>(asset as IMm3Asset, Model.MM3_ASSETS, handleSubmit, customValidator);
  const form = flags.isMm3Assets ? mm3form : oldform;
  const { values, handlers, valid } = form;
  const latestValues = useRef(values);

  useEffect(() => {
    if (isEqual(initialAssetFields, latestAssetFields.current)) {
      return;
    }

    latestAssetFields.current = initialAssetFields;
    handlers.onChange(initialAssetFields);
  }, [handlers, initialAssetFields]);

  useEffect(() => {
    if (isEqual(values, latestValues.current)) {
      return;
    }

    latestValues.current = values;
    onDataChange?.(
      flags.isMm3Assets
        ? (values as IMm3Asset)
        : parseKeyPathToObject<IAssetFormFields, IAsset>(values as IAssetFormFields),
    );
  }, [values, valid, onDataChange]);

  return form;
};
