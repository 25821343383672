import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { MenuItem, TagInputProps, Menu } from '@blueprintjs/core';
import { ActionIcon, Loader } from '@mantine/core';
import { MultiSelect, ItemListPredicate, MultiSelectProps } from '@blueprintjs/select';
import cx from 'classnames';
import { isEqual, compact } from 'lodash';

import {
  IContact,
  ICustomItemRendererProps,
  IFetchProductsWithAncestry,
  IProduct,
  IQueryParams,
  ISearchFilter,
  ItemId,
} from 'types';
import { by } from 'utils/general';
import { formatFormLabel, getFieldPlaceholder } from 'helpers/form/fields/helpers';
import { AncestryDropdown, IBreadcrumbProps } from './ancestry-dropdown/ancestry-dropdown';
import { FormGroup } from 'helpers/form/fields/form-group';

import { IFieldData, IFieldHandlers } from 'helpers/form/types';
import type { IFormMultiSelectOption } from 'helpers/form/fields/select-helpers';
import { tagRenderer, itemRenderer, parseItem, DefaultCreateTagElement, GroupTagElement } from './utils';
import { Intent, LoaderSize } from 'utils/ui';
import { useDebounce } from 'utils/hooks';
import { hasDescendants } from 'pages/product-details/product-assets-tab/utils';
import { useMultiselectRemoteSetup, useMultiselectStaticSetup, usePreventBackspaceClick } from './hooks';
import { IItem } from 'types';
import { MantineIcon } from 'utils/ui/icon';
import { Classes } from 'utils/ui';
import { Cross } from 'blueprint5-icons';

import './style.scss';

const filterSelectedItems = (
  isRemoteMode: boolean,
  items: IFormMultiSelectOption[],
  selectedItems: IFormMultiSelectOption[],
): IFormMultiSelectOption[] => {
  if (!isRemoteMode) {
    return items;
  }

  return items.filter(
    (item) =>
      hasDescendants((item as IProduct).ancestry_info) ||
      !selectedItems.some((selectedItem) => item.value === selectedItem.value),
  );
};

const MfxMultiSelect = MultiSelect.ofType<IFormMultiSelectOption>();

export interface IFormMultiSelectProps
  extends Partial<MultiSelectProps<IFormMultiSelectOption>>,
    IFieldData<ItemId[] | null, (IFormMultiSelectOption | ItemId)[]>,
    IFieldHandlers<ItemId[] | null> {
  name: string;
  label?: string;
  omni?: boolean;
  large?: boolean;
  inline?: boolean;
  disabled?: boolean;
  fitInParent?: boolean;
  placeholder?: string;
  showPositiveValidation?: boolean;
  queryDelay?: number;
  tagInputProps?: Partial<TagInputProps>;
  validateNewItem?: (query: string) => boolean;
  onSelectedItemsChange?: (items: IFormMultiSelectOption[]) => void;
  rawLabel?: boolean;
  allowNewItems?: boolean;
  isAncestryMode?: boolean;
  helperText?: string;
  createTagElement?: React.FC<{ text: string; onClick: VoidFunction }>;
  fetchValues?: (
    { ids, q }: IQueryParams,
    filters?: ISearchFilter[],
  ) => Promise<(IFormMultiSelectOption | IItem | string)[]> | IFetchProductsWithAncestry;
  customRenderItemParser?: (
    item: IFormMultiSelectOption,
    itemProps: ICustomItemRendererProps,
  ) => ICustomItemRendererProps;
  customTagRenderer?: (item: IFormMultiSelectOption) => React.ReactNode;
  hideClearAllButton?: boolean;
}

const defaultBreadcrumbs: IBreadcrumbProps[] = [{ href: 'root', icon: 'double-chevron-left', text: 'Top' }];

export const FormMultiSelect: React.FC<IFormMultiSelectProps> = (props) => {
  const {
    name,
    label = '',
    onChange,
    onBlur,
    fetchValues,
    validation,
    touched,
    value,
    omni = false,
    large = false,
    rawLabel = false,
    required,
    isAncestryMode = false,
    options,
    onSelectedItemsChange,
    queryDelay = 250,
    inline = false,
    allowNewItems = false,
    validateNewItem = (s: string): boolean => s?.trim().length > 0,
    placeholder,
    fitInParent,
    createTagElement: CreateTagElement = DefaultCreateTagElement,
    className,
    customRenderItemParser,
    customTagRenderer,
    hideClearAllButton = false,
    ...inputProps
  } = props;

  const formattedLabel = label?.length && !rawLabel && !label.endsWith('s') ? `${label}s` : label;

  const showError = Boolean(touched && !validation?.valid && validation?.errorMessage);
  const intent = showError ? Intent.DANGER : undefined;
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumbProps[]>(defaultBreadcrumbs);

  // use array for value - we need to be able to trigger query useEffect on every setInputValue
  // needed for ancestry filters
  const [inputValue, setInputValue] = useState<string[]>(['']);
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState<IFormMultiSelectOption[]>([]);
  const [selectedItems, setSelectedItems] = useState<IFormMultiSelectOption[]>([]);
  const isRemoteMode = Boolean(fetchValues);
  const [isRemoteInitialized, setIsRemoteInitialized] = useState<null | boolean>(!isRemoteMode);
  const [canLoadRemoteOptions, setCanLoadRemoteOptions] = useState(false);
  const debouncedInputValue = useDebounce(inputValue, queryDelay);
  const filters = useRef<unknown>(undefined);
  const inputRef = useRef<HTMLInputElement>(null);
  const isMounted = useRef(false);
  const latestSelectedItemsChange = useRef<IFormMultiSelectOption[]>([]);

  usePreventBackspaceClick(inputRef);
  useMultiselectStaticSetup(options, isRemoteMode, setItems, setSelectedItems, value, isRemoteInitialized);
  useMultiselectRemoteSetup(
    isRemoteMode,
    value,
    setIsRemoteInitialized,
    fetchValues,
    setSelectedItems,
    canLoadRemoteOptions,
    setIsLoading,
    setItems,
    debouncedInputValue,
    filters,
  );

  useEffect(() => {
    if (isMounted.current && !isEqual(selectedItems, latestSelectedItemsChange.current)) {
      latestSelectedItemsChange.current = selectedItems;
      onSelectedItemsChange?.(selectedItems);
      return;
    }

    latestSelectedItemsChange.current = selectedItems;
    isMounted.current = true;
  }, [selectedItems, onSelectedItemsChange]);

  const handleBlur = useCallback(() => {
    onBlur?.(name);
  }, [name, onBlur]);

  const handleChange = (values: IFormMultiSelectOption[]): void => {
    onChange?.({ [name]: values.reduce((acc, { value }) => [value, ...acc], []) });
    setSelectedItems(values);
    if (inputValue[0] !== '') {
      setInputValue(['']);
    }
  };

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue([e.target.value]);
  }, []);

  const handlePasteInput = useCallback((e: React.ClipboardEvent<HTMLInputElement>): void => {
    e.stopPropagation();
    e.preventDefault();

    const pastedText = e.clipboardData.getData('text/plain');
    setInputValue([pastedText]);
  }, []);

  const onItemSelect = async (item: IFormMultiSelectOption, e): Promise<void> => {
    const targetEl = e.target;
    const isAncestryArrowBtn =
      targetEl.classList.contains('js-product-search-item__ancestry') ||
      targetEl.closest('.js-product-search-item__ancestry');

    if (isAncestryArrowBtn) {
      const parentId = e.currentTarget.dataset.parentId;
      const text = e.currentTarget.dataset.text;

      filters.current = [['parent_id', 'eq', parentId]];

      setIsLoading(true);
      setBreadcrumbs((breadcrumbs) => [...breadcrumbs, { text, href: parentId }]);
      setInputValue(['']);
      return;
    }

    const type = item['@type'] || (item as IProduct).type || item['$type'];
    const newItem = type ? parseItem(item) : (item as IFormMultiSelectOption);

    if (newItem && selectedItems.every(({ value }) => value !== newItem.value)) {
      handleChange([newItem, ...selectedItems]);
      handleBlur?.();
    }
  };

  const onRemove = (_, index: number): void => {
    handleChange(selectedItems.filter((item, i) => index !== i));
    handleBlur?.();
  };

  const itemListPredicate = useCallback<ItemListPredicate<IFormMultiSelectOption>>(
    (q, list) => {
      const itemsById = by<IFormMultiSelectOption>(selectedItems, 'value');
      const searchQuery = new RegExp(q, 'i');

      return list.filter(({ value, label }) => !itemsById[value] && (q.startsWith('id:') || searchQuery.test(label)));
    },
    [selectedItems],
  );

  const createNewItemFromQuery = useMemo(() => {
    if (!allowNewItems) {
      return undefined;
    }

    return (query: string) => {
      const value = query.trim();
      return { label: value, value };
    };
  }, [allowNewItems]);

  const exactEmailMatch = (items, trimmedValue): string | IFormMultiSelectOption | boolean => {
    const match = items.some((item) => item?.itemType === 'user' && (item as IContact)?.email === trimmedValue);

    return match;
  };

  const handleAdd = (): void => {
    const trimmedValue = inputValue[0].trim();
    const emailMatch = exactEmailMatch([...items, ...selectedItems], trimmedValue);

    if (
      !validateNewItem(trimmedValue) ||
      emailMatch ||
      !trimmedValue ||
      selectedItems.some((e) => e.value === trimmedValue)
    ) {
      return;
    }

    const newItem = createNewItemFromQuery?.(trimmedValue);

    if (!newItem) {
      return;
    }

    handleChange([newItem, ...selectedItems]);
  };

  const handleGroupAdd = (parsedInputItems): void => {
    const filteredUserEmails = parsedInputItems.filter((item) => {
      const emailMatch = exactEmailMatch([...items, ...selectedItems], item);

      if (emailMatch || selectedItems.some((e) => e.value === item.value)) {
        return;
      }

      return item;
    });

    handleChange([...filteredUserEmails, ...selectedItems]);
  };

  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      handleAdd();
    }
  };

  const handleInputPropsClick = (): void => {
    if (!isRemoteMode) return;
    setCanLoadRemoteOptions(true);
  };

  const handleBreadcrumbClick = async (e): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();

    const target = e.target;
    const menuItem = target.closest(`.${Classes.MENU_ITEM}`);
    const isLink = target.classList.contains(Classes.BREADCRUMB);
    let parentId;

    if (isLink) {
      parentId = target.getAttribute('href');
    }

    if (!isLink && menuItem) {
      parentId = menuItem.getAttribute('href');
    }

    if (!parentId) return;

    filters.current = parentId === 'root' ? undefined : [['parent_id', 'eq', parentId]];

    const breadcrumbIndex = breadcrumbs.findIndex((item) => item.href === parentId);
    const updated = breadcrumbs.slice(0, breadcrumbIndex + 1);

    setIsLoading(true);
    setBreadcrumbs(updated);
    setInputValue(['']);
  };

  const handleItemRenderer = (item, itemProps): JSX.Element | null => {
    const hashData = item?.id && new Map(selectedItems.map((item) => [item.id, item]));
    const isItemSelected = hashData?.has(item?.id);
    itemProps = customRenderItemParser ? customRenderItemParser(item, itemProps) : itemProps;
    itemProps = {
      ...itemProps,
      modifiers: { ...itemProps.modifiers, disabled: isItemSelected || itemProps.modifiers.disabled },
    };

    return itemRenderer(item, itemProps, { isAncestryMode });
  };

  const handleTagRenderer = useCallback(
    (item) => customTagRenderer?.(item) || tagRenderer(omni)(item),
    [omni, customTagRenderer],
  );

  const handleClearAll = useCallback(
    (name: string): void => {
      setSelectedItems([]);
      onChange?.({ [name]: [] });
    },
    [setSelectedItems, onChange],
  );

  const handleItemListRender = ({ filteredItems, renderItem }): React.ReactElement => {
    // renderItem calls itemRenderer
    const items = filteredItems.filter((item) => item != null);
    const hasItems = items.length;

    if (isAncestryMode) {
      return (
        <AncestryDropdown
          isLoading={isLoading}
          items={items}
          renderItem={renderItem}
          breadcrumbs={breadcrumbs}
          onBreadcrumbClick={handleBreadcrumbClick}
        />
      );
    }

    if (isLoading) {
      return (
        <Menu>
          <MenuItem disabled icon={<Loader size={16} />} text="Loading..." />
        </Menu>
      );
    }

    // identifies emails separated by comma and space
    const listRegex = /([^,\s]+@[^,\s]{2,}\.[^,\s]{2,})+/gi;
    const inputeItems = inputValue.toString()?.match(listRegex) ?? [];

    let parsedInputItems = inputeItems.map((email) => {
      email = email.trim();
      const systemUser = items.find((item) => item.email === email);
      if (systemUser) return systemUser;

      if (!validateNewItem(email)) return;

      return createNewItemFromQuery?.(email);
    });
    parsedInputItems = compact(parsedInputItems);

    const trimmedValue = inputValue[0].trim();
    const emailMatch = exactEmailMatch([...items, ...selectedItems], trimmedValue);

    const showCreateTag = allowNewItems && validateNewItem(trimmedValue) && !emailMatch;
    const isGroupTag = !showCreateTag && parsedInputItems.length > 1;

    if (hasItems) {
      return (
        <Menu>
          {showCreateTag && <CreateTagElement text={inputValue[0]} onClick={handleAdd} />}
          {isGroupTag ? (
            <GroupTagElement
              text={`${parsedInputItems.length} recipients`}
              onClick={() => handleGroupAdd(parsedInputItems)}
            />
          ) : (
            items.map(renderItem)
          )}
        </Menu>
      );
    }

    if (showCreateTag) {
      return (
        <Menu>
          <CreateTagElement text={inputValue[0]} onClick={handleAdd} />
        </Menu>
      );
    }

    return (
      <Menu>
        <MenuItem disabled text="Nothing found." />
      </Menu>
    );
  };

  const isWrapInputDisabled = !isRemoteInitialized || inputProps.disabled;

  const formattedPlaceholder = getFieldPlaceholder({
    placeholder,
    disabled: isWrapInputDisabled,
    defaultPlaceholder: allowNewItems ? `Add ${formattedLabel}` : `Search ${formattedLabel}`,
  });

  const isEmptyMultiSelect = !selectedItems.length;
  return (
    <FormGroup
      label={formatFormLabel(formattedLabel, required)}
      labelFor={name}
      intent={intent}
      helperText={showError ? 'This field is required' : ''}
      inline={inline}
      className={cx(className, {
        'form-multi-select-group--inline': inline,
        'form-multi-select-wrap--fit-in-parent': fitInParent,
      })}
    >
      <MfxMultiSelect
        className={cx('form-multi-select', { 'form-multi-select--large': omni })}
        resetOnSelect
        createNewItemFromQuery={createNewItemFromQuery}
        popoverProps={{
          boundary: 'viewport',
          minimal: true,
          usePortal: true,
          position: omni ? 'left-top' : 'bottom-left',
          portalClassName: cx('form-multi-select-portal', { 'form-multi-select-portal--large': omni }),
        }}
        tagRenderer={handleTagRenderer}
        itemRenderer={handleItemRenderer}
        itemListRenderer={handleItemListRender}
        onItemSelect={onItemSelect}
        items={filterSelectedItems(isRemoteMode, items, selectedItems)}
        itemListPredicate={isRemoteMode ? undefined : itemListPredicate}
        selectedItems={selectedItems}
        {...inputProps}
        tagInputProps={{
          large: omni || large,
          onRemove,
          inputRef,
          addOnPaste: true,
          separator: false,
          rightElement: !isRemoteInitialized ? (
            <Loader size={LoaderSize.SMALL} />
          ) : !hideClearAllButton ? (
            <ActionIcon
              size="md"
              variant="subtle"
              className={cx('clear-all__button', { 'clear-all__button--omni': omni })}
              disabled={isEmptyMultiSelect || isWrapInputDisabled}
              onClick={() => handleClearAll(name)}
            >
              <MantineIcon icon={<Cross />} />
            </ActionIcon>
          ) : undefined,
          ...(inputProps.tagInputProps || {}),
          disabled: isWrapInputDisabled,
          inputProps: {
            value: inputValue[0],
            onPaste: handlePasteInput,
            onChange: handleInputChange,
            onKeyDown: handleEnter,
            onBlur: handleBlur,
            onClick: handleInputPropsClick,
          },
        }}
        placeholder={formattedPlaceholder}
        query={inputValue[0]}
      />
    </FormGroup>
  );
};
