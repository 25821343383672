import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import { Title, Button, ActionIcon } from '@mantine/core';

import { FormInput, useForm } from 'helpers/form';

import { Intent } from 'utils/ui/intent';
import { MantineIcon } from 'utils/ui/icon';
import { cookie } from 'utils/cookie';
import { chipmunk } from 'utils/chipmunk';
import { useStore } from 'store';

import { Globals } from 'types';
import { Footer } from 'components/footer';
import { ToastError } from 'components/toast';

import { loadSession } from 'store/session-store';

import './style.scss';

export interface ISetNewPasswordFields {
  password: string;
  password_confirmation: string;
}

const initialValues: ISetNewPasswordFields = {
  password: '',
  password_confirmation: '',
};

const SetNewPassword: React.FC<{}> = observer(() => {
  const { toastStore } = useStore();
  const [inProgress, setInProgress] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const { token } = useParams<{ token: string }>();

  const handleSubmit = useCallback(
    (data: ISetNewPasswordFields, valid: boolean) => {
      if (!valid) {
        return;
      }

      setInProgress(true);

      chipmunk.run(async (chipmunk) => {
        try {
          const res = await chipmunk.action('um.password', 'update', {
            body: data,
            params: { password_reset_token: token },
            headers: { 'Session-Id': null },
          });

          toastStore.success('Your password has been changed');

          cookie.set('sessionId', res.object.id);
          chipmunk.updateConfig({
            headers: { 'Session-Id': res.object.id },
          });

          await loadSession(true);
        } catch (err) {
          toastStore.error(<ToastError error={err} placeholder="Could not reset password!" />);
        }
        setInProgress(false);
      });
    },
    [toastStore, token],
  );

  const { formData, handlers, valid, onSubmit } = useForm<ISetNewPasswordFields>(
    initialValues,
    'um.password',
    handleSubmit,
  );
  const togglePasswordVisibilityField = (): void => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  return (
    <div className={`page--set-new-password ${Globals.MODE_DARK_CLASS}`}>
      <div className={`set-new-password`}>
        <div className="set-new-password__head">
          <Title order={3}>Enter new password</Title>
        </div>

        <div className="set-new-password__line" />

        <div className="set-new-password__content">
          <div className="set-new-password__text mb-3">Enter your new password below.</div>
          <form onSubmit={onSubmit}>
            <FormInput
              name="password"
              type={showPassword ? 'text' : 'password'}
              rightElement={
                <ActionIcon variant="subtle" color="gray.5" onClick={togglePasswordVisibilityField}>
                  <MantineIcon icon={showPassword ? 'eye-off' : 'eye-on'} />
                </ActionIcon>
              }
              label="Password"
              showPositiveValidation={true}
              {...formData.password}
              {...handlers}
            />
            <FormInput
              name="password_confirmation"
              type={showPassword ? 'text' : 'password'}
              rightElement={
                <ActionIcon variant="subtle" color="gray.5" onClick={togglePasswordVisibilityField}>
                  <MantineIcon icon={showPassword ? 'eye-off' : 'eye-on'} />
                </ActionIcon>
              }
              label="Confirm Password"
              placeholder="Repeat Password"
              showPositiveValidation={true}
              {...formData.password_confirmation}
              {...handlers}
            />
            <div className="set-new-password__btngroup">
              <Button disabled={!valid} loading={inProgress} variant={Intent.PRIMARY} type="submit">
                Change
              </Button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
});

export default SetNewPassword;
