import { shouldDisplaySingleNotDeleted } from 'utils/actions/common/actions-acl';
import { flags } from 'utils/flags';
import { IShouldDisplayGroupActionFunction } from 'utils/actions/common/types';

export const shouldDisplayRecommendMobileSelection: IShouldDisplayGroupActionFunction = (
  action,
  index,
  user,
  entities,
  options,
) => {
  const showByFlag = options.isAssetRecommendation
    ? flags.showAssetRecommendationsFeature
    : flags.showRecommendationFeature;
  return shouldDisplaySingleNotDeleted(action, index, user, entities, options) && showByFlag;
};
