import React from 'react';

import { IConference } from 'types';
import { IUseFormReturn } from 'helpers/form';
import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import { LabelValuePair as Row, yn } from 'components/label-value-pair';

import { formatDate } from 'utils/date';

export const ConferenceCreateSummary: React.FC<{ form: IUseFormReturn<Partial<IConference>> }> = ({
  form: { values },
}) => {
  return (
    <FormSectionWrapperV2 padded={false}>
      <Row label="Title" value={values.title} />
      <Row label="Type" value={values.mode} />

      <Row label="Start time" value={formatDate(values.starts_at)} hideIfNoValue />
      <Row label="End time" value={formatDate(values.ends_at)} hideIfNoValue />
      <Row label="Disable waiting room" value={yn(values.disable_waitingroom)} />
      <Row label="Assets" value={values.asset_ids?.length || 0} />
      <Row label="Contacts" value={values.user_ids?.length || 0} />
    </FormSectionWrapperV2>
  );
};
