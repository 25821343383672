import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { omit } from 'lodash';

import { useStore } from 'store';
import { saveContact } from 'pages/contact-details/api';

import { FormInput, useForm, IUseFormReturn } from 'helpers/form';
import { Model } from 'helpers/filters/types';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { parseKeyPathToObject, parseObjectToKeyPath } from 'utils/payload';
import { IContact } from 'types';
import { Pages } from 'utils/actions/types';

import { FormSectionWrapperV2 } from 'components/form-section-wrapper-v2';
import {
  ContactPersonalDataSection,
  ContactBusinessSection,
  ContactSubscriptionSection,
  ContactPreferencesSection,
  ContactSettingsSection,
  ContactInformationSection,
} from 'components/contact-form';
import { ContactOverviewTabPreview } from 'components/contact';
import { staticContext } from 'pages/upsert-contact/custom-context';

import './style.scss';

interface IContactOverviewEditTabProps {
  contact: IContact;
  onDataChange: (object: IUseFormReturn<IContact>) => void;
  refresh: () => Promise<void>;
  setEditModeEnabled: (edit: boolean) => void;
}

const additionalContextsParams = [
  { prefix: 'phones', model: 'um.organization/phone' },
  { prefix: 'address', model: 'um.user/address' },
];

const options = {
  context: 'single',
  page: Pages.DETAILS,
} as IUseActionsOptionParam;

const keysToOmit = ['preview_image_id', 'preview_image'];

const ContactOverviewEditTab: React.FC<IContactOverviewEditTabProps> = observer(
  ({ contact, onDataChange, refresh, setEditModeEnabled }) => {
    const initialValues = parseObjectToKeyPath(omit(contact, keysToOmit), ['tags']) as IContact;

    const { toastStore } = useStore();
    const handleSubmit = useCallback(
      async (values: IContact) => {
        try {
          toastStore.info('Saving');
          await saveContact(values.id, parseKeyPathToObject(omit(values, keysToOmit)), contact);
          toastStore.clearAll();
          toastStore.success('Saved', 1000);
          await refresh();
          toastStore.success('Changes applied successfully!');
          setEditModeEnabled(false);
        } catch (e) {
          toastStore.clearAll();
          toastStore.error(`Error while saving: ${e?.object?.description || 'Unknown error'}`);
        }
      },
      [contact, refresh, setEditModeEnabled, toastStore],
    );

    const contactForm = useForm<IContact>(
      initialValues,
      Model.CONTACTS,
      handleSubmit,
      staticContext,
      additionalContextsParams,
    );
    const { formData, handlers } = contactForm;

    useEffect(() => {
      onDataChange(contactForm);
    }, [contactForm, onDataChange]);

    return (
      <div className="contact-general-tab h-100">
        <ContactOverviewTabPreview contact={contact} options={options} />
        <div className="contact-general-tab__infoboxes-wrapper">
          <div className="contact-general-tab__infoboxes">
            <ContactPersonalDataSection {...contactForm} />
            <ContactInformationSection {...contactForm} />
            <ContactBusinessSection {...contactForm} />
            <FormSectionWrapperV2 title="Notes" gridTemplateColumns="1fr" wrapperClassName="py-3" withBorder>
              <FormInput name="notes" textarea placeholder="Type Notes" {...formData.notes} {...handlers} />
            </FormSectionWrapperV2>
            <ContactPreferencesSection {...contactForm} />
            <ContactSettingsSection {...contactForm} />
            <ContactSubscriptionSection {...contactForm} />
          </div>
        </div>
      </div>
    );
  },
);

export default ContactOverviewEditTab;
