import React from 'react';
import { observer } from 'mobx-react-lite';
import { isEqual } from 'lodash';
import { Switch } from '@mantine/core';

import {
  EqFilter,
  ExistFilter,
  NotExistFilter,
  isExistFilter,
  isNotExistFilter,
  InFilter,
  NotInFilter,
  isInFilter,
  isNotInFilter,
} from 'helpers/filters/types';

type ISwitchFilterDefinition = EqFilter | ExistFilter | NotExistFilter | InFilter | NotInFilter;

interface IFilterSwitchProps {
  name: string;
  label: string;
  large?: boolean;
  disabled?: boolean;
  checkedValue: number | string | boolean | boolean[] | string[];
  notCheckedValue: number | string | boolean | boolean[] | string[];

  filter: ISwitchFilterDefinition;
  onChange?: (newValue: { [key: string]: ISwitchFilterDefinition }) => void;
}

const FilterSwitch: React.FC<IFilterSwitchProps> = observer((props) => {
  const { name, filter, checkedValue, notCheckedValue, onChange, ...restProps } = props;

  const { value } = filter;
  const handleChange: React.FormEventHandler<HTMLInputElement> = (ev): void => {
    const newValue = ev.currentTarget.checked ? checkedValue : notCheckedValue;

    if (isExistFilter(filter) || isNotExistFilter(filter)) {
      onChange?.({ [name]: { ...filter, value: newValue as boolean } });
    } else if (isInFilter(filter) || isNotInFilter(filter)) {
      onChange?.({ [name]: { ...filter, value: newValue as Array<string | number> } });
    } else {
      onChange?.({ [name]: { ...filter, value: newValue as string | number } });
    }
  };

  return <Switch mb="sm" checked={isEqual(value, checkedValue)} onChange={handleChange} {...restProps} />;
});

export default FilterSwitch;
