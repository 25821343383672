import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import { useDataSectionStore } from 'store';
import { Row } from 'components/label-value-pair';

import { IContact } from 'types';
import { InfoBoxV2 } from 'components/info-box-v2';
import { formatDate } from 'utils/date';
import { useCountryNames } from 'utils/hooks';
import { getPhone, getResponsibleUserLabel, ResponsibleUser, renderSocialLinks, CategoriesInfo } from 'utils/ui';

export const ContactPreviewAttributes: React.FC = observer(() => {
  const { activeItem: contact } = useDataSectionStore<IContact>();

  const {
    genre_preferences_ids,
    status,
    deleted_at,
    territory_rep,
    country_id,
    email,
    role,
    responsibility,
    geo_scopes,
    customer_segment,
    interests,
    notes,
    created_at,
    phones,
    social_media_profiles,
    activated_at,
    last_login_at,
  } = contact || {};
  const [country] = useCountryNames([country_id]);

  const getSpecificPhone = useCallback(
    (order) => {
      const p = getPhone(phones, order);
      if (!p) {
        return null;
      }

      return <Row hideIfNoValue label={`Phone ${p.label}`} value={p.number} />;
    },
    [phones],
  );

  return (
    <InfoBoxV2 gridTemplateColumns="1fr 1fr" className="break-word p-3">
      <Row label="Status" value={status} />
      <Row label="Platform Role" value={role?.name} />
      <Row label="Email" value={email} rawValue />
      {getSpecificPhone(['Mobile'])}
      {getSpecificPhone(['Work', 'Home'])}
      <Row label="Country" value={country} hideIfNoValue />
      <Row label={getResponsibleUserLabel()} value={<ResponsibleUser contact={contact} />} hideIfNoValue />
      <Row label="Sales contact" value={territory_rep} hideIfNoValue rawLabel />
      <Row label="Responsibility" value={responsibility} size="xl" hideIfNoValue />
      <Row label="Territories of responsibility" value={geo_scopes} size="xl" hideIfNoValue rawLabel />
      <Row label="Customer segment" value={customer_segment} size="xxl" hideIfNoValue rawLabel />
      <Row label="Interests" value={interests} size="xxl" hideIfNoValue />
      <Row label="Notes" value={notes} size="xxl" hideIfNoValue />
      <div className="contact-preview__social screen-l">{renderSocialLinks(social_media_profiles)}</div>
      {/* this is the fix for label/value in the infobox */}
      <div style={{ display: 'none' }}></div>
      <Row label="Created at" value={formatDate(created_at)} rawLabel />
      <Row label="Active since" value={formatDate(activated_at)} rawLabel />
      <Row label="Last login" value={formatDate(last_login_at)} rawLabel />
      <Row label="Deleted at" value={formatDate(deleted_at)} hideIfNoValue rawLabel />
      <Row hideIfNoValue label="Genres" value={<CategoriesInfo category_ids={genre_preferences_ids} />} />
    </InfoBoxV2>
  );
});
