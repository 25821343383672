export const contactDetailsSchema = `
  access_level,
  activated_at,
  address,
  analytics_playback_attachment_all,
  analytics_subscription,
  assistant_ids,
  bouncing,
  company_profile,
  country_id,
  country,
  created_at,
  customer_segment,
  date_of_birth,
  department,
  division_id,
  email,
  first_name,
  function,
  geo_scope_ids,
  geo_scopes,
  gender,
  id,
  im_contacts,
  interests,
  last_login_at,
  last_name,
  mfa_enabled,
  middle_name,
  mobile_sync,
  notes,
  organization,
  organization_id,
  phones,
  preview_image,
  preview_image_id,
  responsibility,
  responsible_user_id,
  responsible_user {
    id,
    first_name,
    last_name,
    role
  },
  role,
  role_id,
  skip_marketing_emails,
  social_media_profiles,
  status,
  supervisor_analytics_cc,
  supervisor_analytics_playback_attachment,
  supervisor_analytics_playback_attachment_all,
  supervisor_analytics_subscription,
  tags,
  title,
  genre_preferences_ids,
  sso,
  territory_rep,
`;

export const contactListSchema = `
  access_level,
  activated_at,
  deleted_at,
  address,
  bouncing,
  country_id,
  country,
  created_at,
  date_of_birth,
  department,
  division_id,
  email,
  first_name,
  function,
  id,
  last_login_at,
  last_name,
  notes,
  organization,
  organization_id,
  phones,
  preview_image,
  preview_image_id,
  responsible_user_id,
  responsible_user {
    first_name,
    last_name,
    role
  },
  role,
  role_id,
  skip_marketing_emails,
  status,
  tags,
  genre_preferences_ids,
  territory_rep,
`;

export const analyticsContactSchema = `
  first_name,
  id,
  status,
  function,
  last_name,
  preview_image,
  responsible_user {
    id,
    first_name,
    last_name,
    role
  }
  `;
