import React, { useMemo, useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import { Routes } from 'utils/routes';
import { EventTiming } from 'components/event-timing';
import { LoaderWithText, ThumbnailWithRefresh } from 'components/thumbnail';
import { LabelValuePair as Row } from 'components/label-value-pair';
import { PreviewTab, PreviewInfo } from 'components/preview-tab';
import { EventLink } from 'components/event-link';

import { DetailsPageTabs, IMarketingEvent } from 'types';
import { useDataSectionStore } from 'store';
import { changeAccessLevelSlider } from 'utils/change-access';
import { IconAlias } from 'icons';
import { GroupAssetTypeAggregations } from 'components/asset';
import { formatOwnership, getStatus } from 'utils/general';
import { formatDate } from 'utils/date';
import { changeGroupAccessImmediate } from 'utils/apis/groups';
import { DetailCountLink } from 'utils/ui';
import { getAttendeesCount } from 'utils/event-utils';
import { EventSchema } from 'utils/schemas/event';

export const EventPreview: React.FC<{}> = observer(() => {
  const { activeItem: event } = useDataSectionStore<IMarketingEvent>();

  const attendeesCount = useMemo(() => getAttendeesCount(event), [event]);
  const {
    created_at,
    deleted_at,
    name,
    id,
    preview_image,
    pm_statistics_data,
    updated_at,
    group_ids = [],
    subevent,
    access_level,
    owner,
    location,
    preview_image_id,
  } = event || {};

  const handleAccessLevelChange = useCallback(
    (newAccessLevel: string) => {
      return changeAccessLevelSlider({
        apiCall: (data) => changeGroupAccessImmediate(data, EventSchema),
        divisionId: owner?.division_id,
        newAccessLevel,
      });
    },
    [owner],
  );

  if (!event) {
    return <span className="entity-preview-tab__no-preview">No preview</span>;
  }

  const detailsPageLink = `${Routes.EVENTS}/${id}`;

  const eventLink = !subevent && (
    <DetailCountLink
      entityCount={group_ids?.length}
      link={`${detailsPageLink}/${DetailsPageTabs.SUB_EVENTS}`}
      label="Sub-Events"
      rawLabel
    />
  );
  return (
    <PreviewTab
      title={name}
      subtitle={<EventLink event={event} className="text-center d-block" linkClassName="text-wrap text-break" />}
      accessLevel={access_level}
      detailsLink={deleted_at ? '' : detailsPageLink}
      handleAccessLevelChange={handleAccessLevelChange}
      customThumbnail={
        <ThumbnailWithRefresh
          fallbackIconName={IconAlias.EVENT}
          entity={{ preview_image_id, preview_image }}
          placeholder={<LoaderWithText text="processing" />}
        />
      }
    >
      <PreviewInfo>
        <Row label="Name" value={name} />
        <Row label="Owner" value={formatOwnership(event)} />

        <EventTiming event={event} />
        <Row label="Location" value={location} />
        {eventLink || <></>}
        {!subevent ? (
          <DetailCountLink
            entityCount={attendeesCount}
            link={`${detailsPageLink}/${DetailsPageTabs.ATTENDEES}`}
            label="Attendees"
          />
        ) : (
          <></>
        )}
        <DetailCountLink
          entityCount={pm_statistics_data?.count}
          link={`${detailsPageLink}/${DetailsPageTabs.PRODUCTS}`}
          label="Products"
        />
        <Row
          label="Assets"
          value={<GroupAssetTypeAggregations groupId={id} baseUrl={`${detailsPageLink}/${DetailsPageTabs.ASSETS}`} />}
        />
        <Row label="Status" value={getStatus(deleted_at)} />
        <Row label="Created at" value={formatDate(created_at)} rawLabel />
        <Row label="Updated at" value={formatDate(updated_at)} rawLabel />
        <Row label="Deleted at" value={formatDate(deleted_at)} rawLabel hideIfNoValue />
      </PreviewInfo>
    </PreviewTab>
  );
});
