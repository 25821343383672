export enum RoutePaths {
  ACCESS_PRIVILEGES = 'access-privileges',
  ACCESS_PRIVILEGES_PARAM = ':accessPrivilegeId',
  CAST_CREW = 'cast-crew',
  CAST_CREW_PARAM = ':id',
  TAB_PARAM1 = ':tabLevel1',
  PRODUCTS = 'products',
  PRODUCTS_PARAM = ':productId',
  LOGIN = 'login',
  IMPRINT = 'imprint',
  PRIVACY_POLICY = 'privacy-policy',
  REGISTRATION = 'registration',
  SSO = 'sso',
  RESET_PASSWORD = 'reset-password',
  CREATE_NEW_CONFERENCE = 'create-conference',
  CREATE_ACCESS_PRIVILEGE = 'create-access-privilege',
  CONTACTS = 'contacts',
  CONTACTS_PARAM = ':contactId',
  CONTACTS_BASKETS = 'baskets',
  CONTACTS_BASKETSID_PARAM = ':basketId',
  CONTACT_SELECTIONS = 'contact-selections',
  CONTACT_SELECTIONS_PARAM = ':selectionId',
  CREATE_CONTACT = 'create-contact',
  PRODUCT_SELECTIONS = 'product-selections',
  PRODUCT_SELECTIONS_PARAM = ':selectionId',
  CREATE_EVENT = 'create-event',
  BASKETS = 'baskets',
  BASKET_PARAM = ':basketId',
  ASSETS = 'assets',
  ASSET_PARAM = ':assetId',
  ASSET_SELECTIONS = 'asset-selections',
  ASSET_SELECTIONS_PARAM = ':selectionId',
  CREATE_ASSET = 'create-asset',
  LOCATIONS = 'locations',
  CALENDAR = 'calendar',
  CONFERENCES = 'conferences',
  CONFERENCES_PARAM = ':code',
  EVENTS = 'events',
  EVENTS_PARAM = ':eventId',
  ORGANIZATIONS = 'organizations',
  ORGANIZATIONS_PARAM = ':organizationId',
  ORGANIZATIONS_DELIVERIES = 'deliveries',
  ORGANIZATIONS_DELIVERSID_PARAM = ':deliveryId',
  CREATE_ORGANIZATION = 'create-organization',
  CREATE_NEW_PRODUCT = 'create-product',
  RECOMMENDATIONS = 'recommendations',
  RECOMMENDATIONS_PARAM = ':recommendationId',
  CREATE_PRODUCT_RECOMMENDATION = 'create-product-recommendation',
  CREATE_ASSET_RECOMMENDATION = 'create-asset-recommendation',
  MOBILE_APP_SYNC = 'mobile-selections',
  MOBILE_APP_SYNC_PARAM = ':mobileSyncId',
  CREATE_MOBILE_APP_SYNC = 'create-mobile-selection',
  CREATE_SCREENING_ROOM = 'create-screening-room',
  SCREENING_ROOMS = 'screening-rooms',
  SCREENING_ROOM_PARAM = ':screeningRoomId',
  COLLECTIONS = 'collections',
  WEBSITES_BRAND_LICENSING = 'website-brand-licensing',
  WEBSITES_COLLECTIONS = 'website-collections',
  WEBSITES_CONTACT = 'website-contact',
  WEBSITES_EVENTS = 'website-events',
  WEBSITES_GENRES = 'website-genres',
  WEBSITES_HOME_PAGE = 'website-home',
  WEBSITES_LOCATIONS = 'website-locations',
  WEBSITES_NEWS = 'website-news',
  WEBSITES_PRODUCTION = 'website-production',
  WEBSITES_DOWNLOADS = 'website-downloads',
  WEBSITES_CINEMA = 'website-cinema',
  WEBSITES_MARKET_EVENTS = 'website-market-events',
  WEBSITES_ABOUT = 'website-about',
  WEBSITES_MICROSITES = 'website-microsites',
  WEBSITES_CLIP_SALES = 'website-clip-sales',
  WEBSITES_REGISTRATION_PAGE = 'website-registration',
  WEBSITES_DIGITAL_CHANNELS = 'website-digital-channels',
  WEBSITES_TV_PAGE = 'website-tv-page',
  NEWS = 'news',
  ANALYTICS_ASSETS = 'analytics-assets',
  ANALYTICS_CONTACTS = 'analytics-contacts',
  ANALYTICS_RECOMMENDATIONS = 'analytics-recommendations',
  ANALYTICS_PRODUCTS = 'analytics-products',
  COLLECTIONS_PARAM = ':collectionId',
  PRODUCER_PORTAL = 'producer-portal',
  CREATE_DELIVERY_PACKAGE = 'create-delivery-package',
  DELIVERIES = 'deliveries',
  DELIVERIES_PARAM = ':deliveryId',
  AFFILIATION = 'affiliation',
  ACCESS_GROUPS = 'access-groups',
  ACCESS_GROUPS_PARAM = ':accessGroupId',
  CREATE_ACCESS_GROUP = 'create-access-group',
  TWO_FACTOR_AUTH = '2fa',
}

export enum Routes {
  ACCESS_PRIVILEGES = `/${RoutePaths.ACCESS_PRIVILEGES}`,
  ANALYTICS_ASSETS = `/${RoutePaths.ANALYTICS_ASSETS}`,
  ANALYTICS_RECOMMENDATIONS = `/${RoutePaths.ANALYTICS_RECOMMENDATIONS}`,
  ANALYTICS_CONTACTS = `/${RoutePaths.ANALYTICS_CONTACTS}`,
  ANALYTICS_PRODUCTS = `/${RoutePaths.ANALYTICS_PRODUCTS}`,
  ASSETS = `/${RoutePaths.ASSETS}`,
  ASSET_SELECTIONS = `/${RoutePaths.ASSET_SELECTIONS}`,
  CREATE_ASSET = `/${RoutePaths.CREATE_ASSET}`,
  CAST_CREW = `/${RoutePaths.CAST_CREW}`,
  CONFERENCES = `/${RoutePaths.CONFERENCES}`,
  CONTACTS = `/${RoutePaths.CONTACTS}`,
  CONTACT_SELECTIONS = `/${RoutePaths.CONTACT_SELECTIONS}`,
  CREATE_ACCESS_PRIVILEGE = `/${RoutePaths.CREATE_ACCESS_PRIVILEGE}`,
  CREATE_NEW_CONFERENCE = `/${RoutePaths.CREATE_NEW_CONFERENCE}`,
  CREATE_NEW_PRODUCT = `/${RoutePaths.CREATE_NEW_PRODUCT}`,
  DASHBOARD = '/',
  EVENTS = `/${RoutePaths.EVENTS}`,
  LOGIN = `/${RoutePaths.LOGIN}`,
  IMPRINT = `/${RoutePaths.IMPRINT}`,
  PRIVACY_POLICY = `/${RoutePaths.PRIVACY_POLICY}`,
  MOBILE_APP_SYNC = `/${RoutePaths.MOBILE_APP_SYNC}`,
  CREATE_MOBILE_APP_SYNC = `/${RoutePaths.CREATE_MOBILE_APP_SYNC}`,
  CREATE_SCREENING_ROOM = `/${RoutePaths.CREATE_SCREENING_ROOM}`,
  SCREENING_ROOMS = `/${RoutePaths.SCREENING_ROOMS}`,
  TWO_FACTOR_AUTH = `/${RoutePaths.TWO_FACTOR_AUTH}`,

  NEWS = `/${RoutePaths.NEWS}`,
  ORGANIZATIONS = `/${RoutePaths.ORGANIZATIONS}`,
  BASKETS = `/${RoutePaths.BASKETS}`,
  PRODUCT_SELECTIONS = `/${RoutePaths.PRODUCT_SELECTIONS}`,
  PRODUCTS = `/${RoutePaths.PRODUCTS}`,
  PRODUCER_PORTAL = `/${RoutePaths.PRODUCER_PORTAL}`,
  RECOMMENDATIONS = `/${RoutePaths.RECOMMENDATIONS}`,
  CREATE_PRODUCT_RECOMMENDATION = `/${RoutePaths.CREATE_PRODUCT_RECOMMENDATION}`,
  CREATE_ASSET_RECOMMENDATION = `/${RoutePaths.CREATE_ASSET_RECOMMENDATION}`,
  REGISTRATION = `/${RoutePaths.REGISTRATION}`,
  CALENDAR = `/${RoutePaths.CALENDAR}`,
  LOCATIONS = `/${RoutePaths.LOCATIONS}`,
  RESET_PASSWORD = `/${RoutePaths.RESET_PASSWORD}`,
  CREATE_CONTACT = `/${RoutePaths.CREATE_CONTACT}`,
  CREATE_EVENT = `/${RoutePaths.CREATE_EVENT}`,
  CREATE_ORGANIZATION = `/${RoutePaths.CREATE_ORGANIZATION}`,

  COLLECTIONS = `/${RoutePaths.COLLECTIONS}`,
  WEBSITES_BRAND_LICENSING = `/${RoutePaths.WEBSITES_BRAND_LICENSING}`,
  WEBSITES_COLLECTIONS = `/${RoutePaths.WEBSITES_COLLECTIONS}`,
  WEBSITES_CONTACT = `/${RoutePaths.WEBSITES_CONTACT}`,
  WEBSITES_EVENTS = `/${RoutePaths.WEBSITES_EVENTS}`,
  WEBSITES_GENRES = `/${RoutePaths.WEBSITES_GENRES}`,
  WEBSITES_HOME_PAGE = `/${RoutePaths.WEBSITES_HOME_PAGE}`,
  WEBSITES_LOCATIONS = `/${RoutePaths.WEBSITES_LOCATIONS}`,
  WEBSITES_NEWS = `/${RoutePaths.WEBSITES_NEWS}`,
  WEBSITES_PRODUCTION = `/${RoutePaths.WEBSITES_PRODUCTION}`,
  WEBSITES_ABOUT = `/${RoutePaths.WEBSITES_ABOUT}`,
  WEBSITES_CINEMA = `/${RoutePaths.WEBSITES_CINEMA}`,
  WEBSITES_DOWNLOADS = `/${RoutePaths.WEBSITES_DOWNLOADS}`,
  WEBSITES_MICROSITES = `/${RoutePaths.WEBSITES_MICROSITES}`,

  WEBSITES_CLIP_SALES = `/${RoutePaths.WEBSITES_CLIP_SALES}`,
  WEBSITES_REGISTRATION_PAGE = `/${RoutePaths.WEBSITES_REGISTRATION_PAGE}`,
  WEBSITES_DIGITAL_CHANNELS = `/${RoutePaths.WEBSITES_DIGITAL_CHANNELS}`,
  WEBSITES_TV_PAGE = `/${RoutePaths.WEBSITES_TV_PAGE}`,

  CREATE_DELIVERY_PACKAGE = `/${RoutePaths.CREATE_DELIVERY_PACKAGE}`,
  DELIVERIES = `/${RoutePaths.DELIVERIES}`,

  AFFILIATION = `/${RoutePaths.AFFILIATION}`,

  ACCESS_GROUPS = `/${RoutePaths.ACCESS_GROUPS}`,
  CREATE_ACCESS_GROUP = `/${RoutePaths.CREATE_ACCESS_GROUP}`,
}
