import React, { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Divider, Switch } from '@mantine/core';

import { useAssetsEditStore } from 'store/hooks';
import { FormCheckbox, useForm } from 'helpers/form';
import { Model } from 'helpers/filters/types';
import { SectionHeader } from 'components/section-header';

import '../index.scss';

interface IApplyPresetFormValues {
  selectedAssets: boolean;
}

const initialValues: IApplyPresetFormValues = {
  selectedAssets: false,
};

interface IMultipleAssetFormHeader {
  openPresetModal: () => void;
}

export const MultipleAssetEditHeader: React.FC<IMultipleAssetFormHeader> = observer(({ openPresetModal }) => {
  const { assets, setAssets } = useAssetsEditStore();
  const { formData, handlers } = useForm<IApplyPresetFormValues>(initialValues, Model.ASSETS);
  const { onChange } = handlers;
  const numberOfSelectedAssets = useMemo(() => assets.filter(({ selected }) => selected).length, [assets]);

  const allSelected = useMemo(() => assets.every(({ selected }) => selected), [assets]);

  useEffect(() => {
    onChange({ selectedAssets: numberOfSelectedAssets > 0 });
  }, [onChange, numberOfSelectedAssets]);

  const onSelectedAssetChange = useCallback(
    ({ selectedAssets }) => {
      onChange({ selectedAssets });
      setAssets(
        assets.map((asset) => (asset.selected === selectedAssets ? asset : { ...asset, selected: selectedAssets })),
      );
    },
    [onChange, setAssets, assets],
  );

  const onSelectAllChange = useCallback(
    (evt) => {
      const selectAll = evt.target.checked;
      setAssets(assets.map((asset) => (asset.selected === selectAll ? asset : { ...asset, selected: selectAll })));
    },
    [setAssets, assets],
  );

  const CustomRightRenderer = useCallback(
    (): JSX.Element => (
      <Button variant="outline" color="white" className="asset-upload__form-header" onClick={openPresetModal}>
        Select Preset
      </Button>
    ),
    [openPresetModal],
  );

  return (
    <>
      <SectionHeader title="Edit Asset" customRightRenderer={CustomRightRenderer} useBackground />

      <div className="asset-upload__form-select">
        <FormCheckbox
          label={`${numberOfSelectedAssets}/${assets.length}`}
          name="selectedAssets"
          {...formData.selectedAssets}
          {...handlers}
          onChange={onSelectedAssetChange}
          hasBottomMargin={false}
        />
        <Divider orientation="vertical" mx={5} />
        <Switch checked={allSelected} label="Select All" name="selectAll" onChange={onSelectAllChange} />
      </div>
    </>
  );
});

export default React.memo(MultipleAssetEditHeader);
