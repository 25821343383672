import React, { useCallback, useState } from 'react';
import { Button } from '@mantine/core';
import { uniq } from 'lodash';
import { useStore } from 'store';

import { MobileAppSyncFormProducts } from 'components/mobile-app-sync-wizard/mobile-app-sync-form-products';
import { IModalSize } from 'components/dialogs/types';
import { Wizard, WizardStep } from 'components/wizard';
import { IconAlias } from 'icons/icon-aliases';
import { delay } from 'utils/delay';
import { IGroupActionName, IGroupConfig } from 'utils/actions/common';
import { shouldDisplaySingleNotDeleted as shouldDisplay } from 'utils/actions/common';
import { addItemsToMobileAppSync } from 'utils/apis/mobile-app-sync';

import { FormAssetTreeSelector, useForm } from 'helpers/form';
import { Model } from 'helpers/filters/types';
import { IMobileAppSync } from 'types';
import { IUseActionsOptionParam } from 'utils/actions/types';
import { IMobileSyncActionForm } from './types';
import { ArrowLeft } from 'blueprint5-icons';
import { MantineIcon } from 'utils/ui/icon';

export interface IMobileAppSyncActionProps {
  entities: IMobileAppSync[];
  options: IUseActionsOptionParam;
}
const customContext = {
  properties: {
    product_ids: { required: true },
  },
};

enum Steps {
  Step1,
  Step2,
}

const AddProductsToMobileSync: React.FC<{
  mobileSync: IMobileSyncActionForm;
  closeModal: () => void;
  options: IUseActionsOptionParam;
}> = ({ mobileSync, closeModal, options }) => {
  const [currentStep, setCurrentStep] = useState<Steps>(Steps.Step1);
  const { toastStore } = useStore();
  const handleTabChange = useCallback((tab: Steps) => setCurrentStep(tab), []);

  const handleNext = useCallback(() => {
    setCurrentStep(Steps.Step2);
  }, []);

  const goBack = useCallback(() => {
    setCurrentStep(Steps.Step1);
  }, []);

  const { id, ...rest } = mobileSync;
  const initialValues = {
    ...rest,
    product_ids: [],
    asset_ids: [],
    include_descendants: true,
    include_related_products: false,
    relatedProductIds: [],
  };

  const handleSubmit = useCallback(
    async (values: IMobileSyncActionForm) => {
      try {
        const productIds = uniq([...(values.product_ids || []), ...(values.relatedProductIds || [])]);
        await addItemsToMobileAppSync({
          ...values,
          product_ids: productIds,
          id,
        });

        await delay(1500);
        toastStore.success('Items added successfully');
        options?.onSuccess?.(IGroupActionName.ADD_ENTITIES);
      } catch (error) {
        toastStore.error('Something went wrong when adding items');
      }
      closeModal();
    },
    [closeModal, options, id, toastStore],
  );

  const addProductsForm = useForm<Omit<IMobileSyncActionForm, 'id'>>(
    initialValues,
    Model.GROUPS,
    handleSubmit,
    customContext,
  );
  return (
    <Wizard
      fitInParent
      selectedStepId={currentStep}
      onChange={handleTabChange}
      footerPadded={false}
      footer={
        <div className="d-flex w-100 justify-content-between">
          <Button
            size="xs"
            onClick={goBack}
            leftSection={<MantineIcon icon={<ArrowLeft />} />}
            variant="subtle"
            style={{ visibility: currentStep === Steps.Step1 ? 'hidden' : undefined }}
          >
            Back
          </Button>
          <Button
            size="xs"
            hidden={currentStep === Steps.Step2}
            variant="primary"
            onClick={handleNext}
            disabled={!addProductsForm.valid}
          >
            Next
          </Button>
          <Button
            size="xs"
            hidden={currentStep === Steps.Step1}
            variant="primary"
            onClick={addProductsForm.onSubmit}
            loading={addProductsForm.isSending}
            disabled={!addProductsForm.valid || addProductsForm.isSending}
          >
            Add
          </Button>
        </div>
      }
    >
      <WizardStep
        id={Steps.Step1}
        title="Products"
        icon={IconAlias.PRODUCT}
        panel={<MobileAppSyncFormProducts form={addProductsForm} mobileSyncId={mobileSync.id} />}
      />
      <WizardStep
        id={Steps.Step2}
        disabled={!addProductsForm.valid}
        title="Assets"
        icon={IconAlias.ASSET}
        panel={<FormAssetTreeSelector allSelected form={addProductsForm} showMetadataHover />}
      />
    </Wizard>
  );
};

export const useAddProductsAction = ({ entities = [], options }: IMobileAppSyncActionProps): IGroupConfig => {
  const {
    dialogStore: { openModal, close },
  } = useStore();

  const handler = useCallback(() => {
    return openModal({
      title: 'Add products to Mobile Selection',
      size: IModalSize.M,
      fitInView: true,
      body: () => (
        <AddProductsToMobileSync
          options={options}
          closeModal={close}
          mobileSync={entities[0] as unknown as IMobileSyncActionForm}
        />
      ),
    });
  }, [openModal, options, close, entities]);

  return {
    name: IGroupActionName.ADD_ENTITIES,
    shouldDisplay,
    icon: 'add',
    title: `Add products`,
    handler,
  };
};
