import { CalGenericLocation, CalGenericMeeting } from '@mediafellows/mm3-types';

import { chipmunk } from 'utils/chipmunk';
import { Model } from 'helpers/filters/types';
import { ItemId } from 'types';
import { parseToSearchParams } from 'utils/general';
import { locationSchema as schema } from 'utils/schemas/location';

export async function searchLocations(starts_at?: string, ends_at?: string): Promise<Array<CalGenericLocation>> {
  const params: {
    available_start?: string;
    available_end?: string;
  } = {};

  if (starts_at) params.available_start = starts_at;
  if (ends_at) params.available_end = ends_at;

  return chipmunk.run(async ({ action }) => {
    const { objects } = await action(Model.LOCATIONS, 'search', {
      params,
      schema,
    });

    return objects;
  });
}

export async function getLocations(location_ids?: ItemId | ItemId[]): Promise<Array<CalGenericLocation>> {
  return chipmunk.run(async ({ action }) => {
    const { objects } = await action(Model.LOCATIONS, 'get', {
      params: location_ids ? { location_ids } : {},
      schema,
    });

    return objects;
  });
}

export async function createLocation(body: CalGenericLocation): Promise<CalGenericMeeting> {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.LOCATIONS, 'create', {
      body: body,
    });

    return object;
  });
}

export async function updateLocation(body: CalGenericLocation): Promise<CalGenericMeeting> {
  return chipmunk.run(async ({ action }) => {
    const { object } = await action(Model.LOCATIONS, 'update', { params: { location_ids: body.id }, body: body });

    return object;
  });
}

export async function deleteLocation(ids: ItemId | ItemId[]): Promise<CalGenericLocation> {
  return chipmunk.run(async ({ action }) => {
    const { objects } = await action(Model.LOCATIONS, 'destroy', { params: { location_ids: ids } });

    return objects;
  });
}

export const queryLocation = async (q?: string, id?: number): Promise<CalGenericLocation[]> => {
  return chipmunk.run(async ({ action }) => {
    const { objects } = await action(Model.LOCATIONS, 'search', {
      body: parseToSearchParams({ q, ids: id ? [id] : undefined }),
    });

    return objects;
  });
};
