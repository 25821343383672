import React from 'react';

import { IUseFormReturn } from 'helpers/form';

import { IAssetFormFields } from './types';
import { IAssetPresetFields } from 'types/asset';

export interface IAssetCustomRowProps<T extends {}> extends Pick<IUseFormReturn<T>, 'handlers' | 'formData'> {
  hideLabel?: boolean;
}

export const CustomAssetRow: React.FC<IAssetCustomRowProps<IAssetFormFields | IAssetPresetFields>> = () => {
  return <></>;
};
