import React, { useEffect, useCallback, useReducer, useMemo, useState } from 'react';
import { IJsonLDSpec } from '@mediafellows/chipmunk';
import { chipmunk } from 'utils/chipmunk';
import { cookie } from 'utils/cookie';
import { logger } from 'utils/logger';
import { IDeepPartial } from 'types';

import {
  IFormValidationResults,
  IFormData,
  IFormHandlers,
  IFieldValidationResults,
  IFormFieldsSettings,
} from './types';
import {
  IFormState,
  UpdateFormActions,
  formReducer,
  formStateInit,
  UpdateContextAction,
  UpdateFormValuesAction,
  UpdateFormTouchedAction,
  UpdateCustomContextAction,
  SetFormFieldsAction,
  ResetFormFieldsAction,
} from './state';
import { combineData, useFieldsTouchedRef } from './helpers';
import { buildCustomContext, IAdditionalContextConfig } from './additional-context-helpers';
import { get } from 'lodash';
import { ITouched } from 'helpers/form/mm3';

/**
 * Type of Form Submit handler passed to `useForm` - passes values and validated states to field
 * to form parent
 */
export type IFormSubmitHandler<T> = (values: T, valid: boolean, validations: IFormValidationResults<T>) => void;

/**
 * data returned from `useForm` hook:
 * - IFormData<T> - field data for every field in form
 * - IFormHandlers<T> - handler functions like onChange, onBlur that are passed to form inputs
 * - boolean - valid state (if whole form is valid)
 * - boolean - touched state (if any field in form was touched)
 * - onSubmit
 * - object<T> - form values
 */
export interface IUseFormReturn<T extends object> {
  formData: IFormData<T>;
  handlers: IFormHandlers<T>;
  valid: boolean;
  onSubmit: (event?: React.FormEvent<HTMLFormElement | HTMLElement>) => void;
  touched: boolean;
  values: T;
  resetFields: () => void;
  isSending: boolean;
}

/**
 * useForm is a hook which aggregates and simplifies form handling
 *
 * The hook returns: [formData, formHandlers, isValid, touched, formValues]
 */
export const useForm = <T extends object>(
  initialValues: T,
  appModel: string,
  handleSubmit?: IFormSubmitHandler<T>,
  customContext?: IDeepPartial<IJsonLDSpec>,
  additionalContextsParams?: IAdditionalContextConfig[],
): IUseFormReturn<T> => {
  const [formState, dispatchFormDataAction] = useReducer<React.Reducer<IFormState<T>, UpdateFormActions<T>>, T>(
    formReducer,
    initialValues,
    formStateInit,
  );
  const { values, validations, fieldsTouched, required, options, extensions, disabled } = formState;

  useEffect(() => {
    (async () => {
      try {
        const context = (await chipmunk.context(appModel)) as IJsonLDSpec;
        dispatchFormDataAction(new UpdateContextAction(context));
      } catch (e) {
        cookie.remove('sessionId');
      }
    })();
  }, [appModel]);

  useEffect(() => {
    (async () => {
      if (Array.isArray(additionalContextsParams) && additionalContextsParams.length && values) {
        const contexts = (await Promise.all(
          additionalContextsParams.map((context) => chipmunk.context(context.model)),
        )) as IJsonLDSpec[];
        const customContext = buildCustomContext<T>(contexts, additionalContextsParams, values);
        dispatchFormDataAction(new UpdateCustomContextAction(customContext));
      }
    })();
  }, [additionalContextsParams, values]);

  useEffect(() => {
    dispatchFormDataAction(new UpdateCustomContextAction(customContext));
  }, [customContext]);

  const onChange = useCallback((newValues: Partial<T>) => {
    dispatchFormDataAction(new UpdateFormValuesAction(newValues));
  }, []);

  const onSetFields = useCallback((fields: T) => {
    dispatchFormDataAction(new SetFormFieldsAction(fields));
  }, []);

  const fieldsTouchedRef = useFieldsTouchedRef(fieldsTouched);

  const onBlur = useCallback(
    (name: keyof T) => {
      const current = get(fieldsTouchedRef.current, name) as unknown;
      const isAlreadyTouched = (current as ITouched)?.touched;

      if (isAlreadyTouched) {
        return;
      }

      dispatchFormDataAction(
        new UpdateFormTouchedAction<IFormFieldsSettings<T, boolean>>({ [name]: true } as IFormFieldsSettings<
          T,
          boolean
        >),
      );
    },
    [fieldsTouchedRef],
  );

  const resetFields = useCallback(() => {
    dispatchFormDataAction(new ResetFormFieldsAction(initialValues));
  }, [initialValues]);

  const valid = useMemo(() => Object.values(validations).every((v: IFieldValidationResults) => v.valid), [validations]);

  const [isSending, setIsSending] = useState<boolean>(false);

  const onSubmit = useCallback(
    async (e) => {
      if (isSending) {
        return;
      }

      try {
        setIsSending(true);
        e?.preventDefault();
        if (handleSubmit) {
          await handleSubmit(values, valid, validations);
        }
      } catch (error) {
        logger.error(error);
      } finally {
        setIsSending(false);
      }
    },
    [handleSubmit, isSending, valid, validations, values],
  );

  const handlers = useMemo(() => ({ onChange, onBlur, onSetFields }), [onBlur, onChange, onSetFields]);

  const formData = useMemo(
    () => combineData(values, validations, fieldsTouched, required, options, extensions, disabled),
    [fieldsTouched, required, options, validations, values, extensions, disabled],
  );

  const touched = useMemo(() => Object.values(fieldsTouched).every((t) => t), [fieldsTouched]);

  return { formData, handlers, valid: valid && !isSending, onSubmit, touched, values, resetFields, isSending };
};
