import React from 'react';
import { set } from 'mobx';
import {
  UmGenericListShowcase,
  UmGenericListCollection,
  McGenericRecommendationProduct,
  UmGenericListItem,
  DeliveriesGenericConnection,
  DeliveriesGenericDeliveryPackage,
  CalGenericLocation,
} from '@mediafellows/mm3-types';

import { IMm3Asset } from 'types';

import {
  useAssetActions,
  useContactActions,
  useEventActions,
  useOrganizationActions,
  useProductActions,
  useShowcaseActions,
  useAccessPrivilegeActions,
  useBasketActions,
  useRecommendationActions,
  useConnectionActions,
  useDeliveryActions,
} from 'utils/actions';
import { IActionName } from 'utils/actions/types';
import {
  IAsset,
  IContact,
  IMarketingEvent,
  IOrganization,
  IBasketStandardized,
  IProduct,
  IAccessPrivilege,
  IIdentifiable,
  IMobileAppSync,
  IAccessGroup,
} from 'types';
import { getRootStore } from 'store';

import { IItemActionsMenuProps } from './utils';
import { useMobileAppSyncActions } from 'utils/actions/mobile-app-sync';
import { useLocationActions } from 'utils/actions/location';

export { ActionMenuItem } from './utils';
export { ConferenceActionsList } from './conference';
export {
  SelectionListItemActionsList,
  AssetSelectionActionsList,
  ContactSelectionActionsList,
  ProductSelectionActionsList,
} from './selection';
export { WorkflowActionsList } from './workflow';
export { CastCrewActionsList } from './cast-crew';
export { ListItemActionsList } from './list-item';
export { NewsActionsList } from './news';
export { ProducerPortalActionsList } from './producer-portal';
import type { IConnectionDeliveryPayload, ICreateWorkFlowDeliveryPayload } from 'utils/actions/types';
import { useAccessGroupActions } from 'utils/actions/access-group';
import { renderMenuActionItems } from 'utils/ui';
import { IExtendedGenericList } from 'components/website-data-section/utils';

export const AssetActionsList: React.FC<IItemActionsMenuProps<IAsset | IMm3Asset>> = (props) => {
  const { items, options } = props;
  const actions = useAssetActions(items, options);

  return <>{renderMenuActionItems(actions)}</>;
};

export const refresh = (action: IActionName, showcase: UmGenericListShowcase): void => {
  if (!showcase?.id || showcase.type !== 'List::Showcase') {
    return;
  }

  const {
    dataSectionStore: { searchStore },
  } = getRootStore();
  const item = searchStore?.objects.find((s) => s.id === showcase.id);
  set(item, { ...showcase, updated_at: new Date().toISOString() });
};

export const ShowcaseActionsList: React.FC<
  IItemActionsMenuProps<
    ((UmGenericListShowcase | UmGenericListCollection) & { listItem?: UmGenericListItem }) | IExtendedGenericList
  >
> = (props) => {
  const { items, options } = props;
  if (!options.onSuccess) options.onSuccess = refresh;

  const actions = useShowcaseActions(items, options);

  return <>{renderMenuActionItems(actions)}</>;
};

export const ProductActionsList: React.FC<IItemActionsMenuProps<IProduct & { listItem?: UmGenericListItem }>> = (
  props,
) => {
  const { items, options } = props;

  const actions = useProductActions(items, options);

  return <>{renderMenuActionItems(actions)}</>;
};

export const LocationActionsList: React.FC<IItemActionsMenuProps<CalGenericLocation>> = (props) => {
  const { items, options } = props;

  const actions = useLocationActions(items, options);

  return <>{renderMenuActionItems(actions)}</>;
};

export const ContactActionsList: React.FC<IItemActionsMenuProps<IContact>> = (props) => {
  const { items, options } = props;

  const actions = useContactActions(items, options);

  return <>{renderMenuActionItems(actions)}</>;
};

export const OrganizationActionsList: React.FC<
  IItemActionsMenuProps<IOrganization & IIdentifiable & { listItem?: UmGenericListItem }>
> = (props) => {
  const { items, options } = props;

  const actions = useOrganizationActions(items, options);

  return <>{renderMenuActionItems(actions)}</>;
};

export const EventActionsList: React.FC<IItemActionsMenuProps<IMarketingEvent>> = (props) => {
  const { items, options } = props;

  const actions = useEventActions(items, options);

  return <>{renderMenuActionItems(actions)}</>;
};

export const BasketActionsList: React.FC<IItemActionsMenuProps<IBasketStandardized>> = (props) => {
  const { items, options } = props;

  const actions = useBasketActions(items, options);

  return <>{renderMenuActionItems(actions)}</>;
};

export const AccessPrivilegeActions: React.FC<IItemActionsMenuProps<IAccessPrivilege>> = (props) => {
  const { items, options } = props;

  const actions = useAccessPrivilegeActions(items, options);

  return <>{renderMenuActionItems(actions)}</>;
};

export const MobileAppSyncActions: React.FC<IItemActionsMenuProps<IMobileAppSync>> = (props) => {
  const { items, options } = props;

  const actions = useMobileAppSyncActions(items, {
    messageSuccess: 'Mobile Selection has been copied',
    ...options,
  });

  return <>{renderMenuActionItems(actions)}</>;
};

export const RecommendationActions: React.FC<IItemActionsMenuProps<McGenericRecommendationProduct>> = (props) => {
  const { items, options } = props;

  const actions = useRecommendationActions(items, options);
  return <>{renderMenuActionItems(actions)}</>;
};

export const ConnectionActionsList: React.FC<
  IItemActionsMenuProps<IConnectionDeliveryPayload<DeliveriesGenericConnection>>
> = (props) => {
  const { items, options } = props;

  const actions = useConnectionActions(items, options);

  return <>{renderMenuActionItems(actions)}</>;
};

export const DeliveryActionsList: React.FC<
  IItemActionsMenuProps<ICreateWorkFlowDeliveryPayload<DeliveriesGenericDeliveryPackage>>
> = (props) => {
  const { items, options } = props;

  const actions = useDeliveryActions(items, options);

  return <>{renderMenuActionItems(actions)}</>;
};

export const AccessGroupActions: React.FC<IItemActionsMenuProps<IAccessGroup>> = (props) => {
  const { items, options } = props;

  const actions = useAccessGroupActions(items, options);

  return <>{renderMenuActionItems(actions)}</>;
};
