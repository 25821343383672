import React, { useMemo } from 'react';
import { Button } from '@mantine/core';
import cx from 'classnames';

import { FormActionSection } from 'components/form-submit-section';
import { Wizard, WizardStep, useWizardSteps, isStepDisabled } from 'components/wizard';
import { isValid } from 'helpers/form';
import { IconAlias } from 'icons';

import { MobileAppSyncFormProducts } from './mobile-app-sync-form-products';
import { MobileAppSyncFormGeneral, keysGeneral } from 'components/mobile-app-sync-wizard/mobile-app-sync-form-general';
import { MobileAppSyncFormSummary } from 'components/mobile-app-sync-wizard/mobile-app-sync-form-summary';
import { MobileAppSyncFormAssets } from 'components/mobile-app-sync-wizard/mobile-app-sync-assets';

import { IMobileAppSyncWizard } from 'components/mobile-app-sync-wizard/types';
import { MantineIcon } from 'utils/ui/icon';
import { ArrowLeft } from 'blueprint5-icons';

enum Steps {
  GENERAL,
  PRODUCTS,
  ASSETS,
  SUMMARY,
}

export const MobileAppSyncWizard: React.FC<IMobileAppSyncWizard> = ({ form, isCreating, className, fitInParent }) => {
  const steps = useMemo(
    () => [
      { id: Steps.GENERAL, disabled: !isValid(form.formData, keysGeneral) },
      { id: Steps.PRODUCTS, disabled: !isValid(form.formData) },
      { id: Steps.ASSETS, disabled: !isValid(form.formData) },
      {
        id: Steps.SUMMARY,
        nextButtonTitle: 'Create',
        action: form.onSubmit,
      },
    ],
    [form.formData, form.onSubmit],
  );
  const { nextButton, backButton, currentStepId, setCurrentStepId } = useWizardSteps(steps);

  return (
    <Wizard
      fitInParent={fitInParent}
      isLoading={isCreating}
      className={className}
      loadingMessage="We are preparing your Mobile Selection now."
      footerPadded={false}
      showStepTitleIndex
      selectedStepId={currentStepId}
      onChange={setCurrentStepId}
      footer={
        <FormActionSection className="d-flex justify-content-between w-100">
          <Button
            className={cx('m-0', { invisible: backButton.hide })}
            onClick={backButton.action}
            disabled={backButton.hide || isCreating}
            variant="subtle"
            leftSection={<MantineIcon icon={<ArrowLeft />} />}
          >
            {backButton.title}
          </Button>
          <Button
            className="m-0"
            variant="primary"
            onClick={nextButton.action}
            disabled={nextButton.disabled || isCreating}
          >
            {nextButton.title}
          </Button>
        </FormActionSection>
      }
    >
      <WizardStep
        id={Steps.GENERAL}
        title="General info"
        icon={IconAlias.GENERAL_INFO}
        disabled={isStepDisabled(Steps.GENERAL, steps)}
        panel={<MobileAppSyncFormGeneral form={form} />}
      />
      <WizardStep
        id={Steps.PRODUCTS}
        disabled={isStepDisabled(Steps.PRODUCTS, steps)}
        title="Products"
        icon={IconAlias.PRODUCT}
        panel={<MobileAppSyncFormProducts fitInParent={fitInParent} form={form} />}
      />
      <WizardStep
        id={Steps.ASSETS}
        disabled={isStepDisabled(Steps.ASSETS, steps)}
        title="Assets"
        icon={IconAlias.ASSET}
        panel={
          <MobileAppSyncFormAssets form={form} isEditingProducts={currentStepId === Steps.PRODUCTS} showMetadataHover />
        }
      />
      <WizardStep
        id={Steps.SUMMARY}
        disabled={isStepDisabled(Steps.SUMMARY, steps)}
        title="Summary"
        icon={IconAlias.MENU}
        panel={<MobileAppSyncFormSummary form={form} />}
      />
    </Wizard>
  );
};
