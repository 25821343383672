import React, { useCallback } from 'react';
import { Button } from '@mantine/core';
import { FloppyDisk } from 'blueprint5-icons';
import { observer } from 'mobx-react-lite';

import { IAsset } from 'types';
import { useAssetsEditStore } from 'store/hooks';
import { editAssetPresetInitialData as initialValues } from 'components/asset/asset-edit-form/constants';
import { MantineIcon } from 'utils/ui/icon';

export interface IAssetPresetControlsProps {
  values: IAsset;
}

export const AssetPresetControls: React.FC<IAssetPresetControlsProps> = ({ values }) => {
  const { savePreset } = useAssetsEditStore();

  const handleSavePreset = useCallback(() => {
    savePreset({ ...initialValues, ...values } as unknown as IAsset);
  }, [savePreset, values]);

  return (
    <div className="asset-upload-components__single-asset-form-preset-control my-2">
      <Button leftSection={<MantineIcon icon={<FloppyDisk />} />} variant="subtle" onClick={handleSavePreset}>
        Save As Preset
      </Button>
    </div>
  );
};

export default React.memo(observer(AssetPresetControls));
